// export const CheckBox = () => <></>;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckBoxStyle = styled.div.attrs({})`
  position: relative;

  input:hover + label:before,
  input:focus + label:before {
    border-color: #4a86e0;
  }

  input:checked + label:before {
    background-color: #4a86e0;
    border-color: #4a86e0;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:checked:disabled + label:before {
    background-color: #cadfff;
    border-color: #cadfff;
  }

  input:checked:disabled + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:disabled + label {
    cursor: default;
  }

  input:disabled + label:before {
    cursor: default;
    background-color: #eaeaea;
    border-color: #c5c5c5;
  }

  input:disabled + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:indeterminate + label:before {
    background-color: #4a86e0;
    border-color: #4a86e0;
  }

  input:indeterminate + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 4px;
    width: 8px;
    height: 2px;
    border-width: 0;
    transform: none;
    background-color: #fff;
  }
`;

const Checkbox = ({
  id,
  label,
  indeterminate: propIndeterminate,
  checked: propChecked,
  onChange,
  cls,
  onError,
  userForm,
  ...inputProps
}: any) => {
  const [checked, setChecked] = useState(propChecked);
  const [indeterminate, setIndeterminate] = useState(propIndeterminate);

  const handleChange = (event: any) => {
    if (userForm) {
      if (onChange) {
        onChange(event.target.id);
      }
    } else {
        const { checked: newChecked } = event.target;
        setChecked(newChecked);
        setIndeterminate(false); // Reset indeterminate state when clicked
        if (onChange) {
          onChange(newChecked);
        }
      }
  };

  let inputClassname = 'w-4 h-4 cursor-pointer hidden input';
  let labelClassname =
    'relative ps-6 cursor-pointer text-base text-color-gray-06 inline-block before:content-[""] before:block before:absolute before:top-1 before:left-0 before:w-4 before:h-4 before:border before:border-solid before:border-color-grey-03 before:bg-white before:rounded-sm before:appearance-none before:cursor-pointer';
  if (onError) {
    inputClassname +=
      ' border-color-red-600 hover:border-color-red-600 focus-visible:border-color-red-600';
  }

  return (
    <CheckBoxStyle className={cls}>
      <input
        type="checkbox"
        className={inputClassname}
        checked={userForm ? propChecked : checked}
        id={id}
        onChange={handleChange}
        {...inputProps}
        ref={input => {
          if (input) {
            input.indeterminate = indeterminate;
          }
        }}
      />
      <label className={labelClassname} htmlFor={id}>
        {label}
      </label>
    </CheckBoxStyle>
  );
};

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  cls: PropTypes.string,
  onError: PropTypes.bool,
  userForm: PropTypes.bool,
};

Checkbox.defaultProps = {
  indeterminate: false,
  checked: false,
  disabled: false, // Default disabled state is false
  onChange: () => {},
  cls: '',
  onError: false,
  useForm: false,
};

export default Checkbox;
