import { displayTypeSection } from "../../../utils/common";

const TabContent2 = ({ courseInfo }: any) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-group-column">
          <thead>
            <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                構成
              </td>
              <td
                className="border-r border-r-color-blue-100 p-4 h-14 align-middle"
              >
                内容
              </td>
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                方法
              </td>
              <td className="p-4 h-14 align-middle last:border-r-0">
                学生の活動
              </td>
            </tr>
          </thead>
          <tbody>
            {courseInfo?.sections?.map((course: any, index: any) => (
              <>
                {course?.subs && course?.subs?.map((sub: any, ind: any) => (
                  <>
                    <tr className="bg-color-gray-20">
                      {ind === 0 ?
                        (
                          <>
                            <td
                              width="100px"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900 font-semibold"
                              rowSpan={course?.subs.length}
                            >
                              {displayTypeSection(courseInfo, index, courseInfo?.sections[index].type)}
                              <br />
                              {course?.subs?.[0].duration}
                            </td>
                            <td
                              width="18.7%"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                            >
                              {sub?.content}
                            </td>
                            <td
                              width="26%"
                              className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                            >
                              {sub?.method}
                            </td>
                            <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0">
                              {sub?.activity}
                            </td>
                          </>
                        ) :
                        (
                          <>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900">
                              {sub?.title}
                            </td>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900">
                              {sub?.content}
                            </td>
                            <td className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900">
                              {sub?.method}
                            </td>
                            <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0">
                              {sub?.activity}
                            </td>
                          </>
                        )}
                    </tr>
                  </>
                ))}
                <tr className="text-sm leading-5">
                  <td
                    className="border-r border-r-color-blue-100 p-4 h-36 align-top text-color-blue-900"
                  >
                    コメント
                  </td>
                  <td
                    className="p-4 h-36 align-top text-color-blue-900 last:border-r-0"
                    colSpan={3}
                  >
                    {course.overall_comment}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TabContent2;
