// export const Radio = () => <></>;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioStyle = styled.div.attrs({})`
  position: relative;

  input:hover + label:before,
  input:focus + label:before {
    border-color: #4a86e0;
  }

  input:checked + label:before {
    border-color: #4a86e0;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4a86e0;
  }

  input:checked:disabled + label:before {
    background-color: transparent;
    border-color: #cadfff;
  }

  input:checked:disabled + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #cadfff;
  }

  input:disabled + label {
    cursor: default;
  }

  input:disabled + label:before {
    cursor: default;
    background-color: #eaeaea;
    border-color: #eaeaea;
  }
`;

const Radio = ({
  id,
  label,
  checked: propChecked,
  onChange,
  ...inputProps
}: any) => {
  const [checked, setChecked] = useState(propChecked);

  const handleChange = (event: any) => {
    const { checked: newChecked } = event.target;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  let inputClassname = 'w-4 h-4 cursor-pointer hidden';
  let labelClassname =
    'relative ps-6 cursor-pointer text-base text-color-gray-06 inline-block before:content-[""] before:block before:absolute before:top-1 before:left-0 before:w-4 before:h-4 before:border before:border-solid before:border-color-grey-03 before:bg-white before:rounded-full before:appearance-none before:cursor-pointer';

  return (
    <RadioStyle>
      <input
        type="radio"
        className={inputClassname}
        checked={checked}
        id={id}
        onChange={handleChange}
        {...inputProps}
      />
      <label className={labelClassname} htmlFor={id}>
        {label}
      </label>
    </RadioStyle>
  );
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  checked: false,
  disabled: false, // Default disabled state is false
  onChange: () => {},
};

export default Radio;
