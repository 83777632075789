import {
  ACCESS_TOKEN,
  CODE,
  COUNT_LOGIN_PAGE,
  PROFILE,
  URL_REDIRECT,
} from '../constants';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setAccessToken = (access_token: any) => {
  return localStorage.setItem(ACCESS_TOKEN, access_token);
};

export const removeAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN);
};

export const setPofile = (data: any) => {
  return localStorage.setItem(PROFILE, data);
};

export const getProfile = () => {
  return localStorage.getItem(PROFILE);
};

export const removeProfile = () => {
  return localStorage.removeItem(PROFILE);
};

export const setUrlRedirect = (url: string) => {
  return localStorage.setItem(URL_REDIRECT, url);
};

export const getUrlRedirect = () => {
  return localStorage.getItem(URL_REDIRECT);
};

export const removeUrlRedirect = () => {
  return localStorage.removeItem(URL_REDIRECT);
};

export const setCountLoginPage = (value: string) => {
  return localStorage.setItem(COUNT_LOGIN_PAGE, value);
};

export const getCountLoginPage = () => {
  return localStorage.getItem(COUNT_LOGIN_PAGE);
};

export const removeCountLoginPage = () => {
  return localStorage.removeItem(COUNT_LOGIN_PAGE);
};

export const getCode = () => {
  return localStorage.getItem(CODE);
};

export const removeCode = () => {
  return localStorage.removeItem(CODE);
};
