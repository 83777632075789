import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import Button from '../../components/atoms/Button';
import { Tab, TabPane } from '../../components/atoms/Tab';
import TabContent1 from './included/TabContent1';
import TabContent2 from './included/TabContent2';
import { useEffect, useState } from 'react';
import { BasicModal } from '../../components/molecules/BasicModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  courseDetailService,
  evaluationReviewAction,
} from '../../services/CSSevice';
import { ICourseSchema, IReview } from '../../constants/types';
import { RouteKey, rc } from '../../constants/router';
import { paramStateNotification, regexRouter, showToastMessage } from '../../utils/common';
import { Loader } from '../../components/atoms/Loader';
import { ratingStatus, txtNotReceivedCS, txtReceivedCS } from '../../constants/message';

const EvaluationReview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { courseId } = useParams<{ courseId: string }>();
  const [courseInfo, setCourseInfo] = useState<ICourseSchema | null>(null);
  const [disableAction, setDisabled] = useState(false);
  const [canReject, setCanReject] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      await courseDetailService(courseId || '').then(result => {
        setCourseInfo(result);
        if (result.status === 2 || result.status === 3) {
          setDisabled(true);
        }
      });
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, location.pathname]);

  useEffect(() => {
    if (!reason) {
      setCanReject(false);
    } else {
      setCanReject(true);
    }
  }, [reason, isModalOpen]);

  const navigate = useNavigate();

  const showModal = () => {
    setReason('');
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleReject = async () => {
    // Handle rejection logic here
    setIsModalOpen(false);
    setIsLoading(true);
    await actionEvaluationReview(false);
  };

  const handleApprove = async () => {
    setIsLoading(true);
    await actionEvaluationReview(true);
  };

  const actionEvaluationReview = async (type: boolean) => {
    const payload: IReview = {
      approve: type,
      reason: type ? undefined : reason,
    };
    try {
      const result: any = await evaluationReviewAction(
        courseInfo?.id + '',
        payload,
      );
      if (result?.status === 200) {
        if (type) {
          navigate(regexRouter(rc(RouteKey.EvaluationForm).path) + '/' + courseInfo?.id, paramStateNotification('success', '', txtReceivedCS));
        } else {
          setDisabled(true);
          navigate(regexRouter(rc(RouteKey.Home).path), paramStateNotification('success', '', txtNotReceivedCS));
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  if (!courseInfo) {
    return <Loader isLoading={true} isFullScreen={true} />;
  }

  return (
    <>
      {isLoading && <Loader isLoading={true} isFullScreen={true} />}
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="px-8 py-5 bg-white mb-4">
          <Heading className="text-blue-800">
            <span
              className="mr-2 inline-block align-middle cursor-pointer hover:bg-slate-200"
              onClick={() => navigate(rc(RouteKey.Home).path)}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.0257 5.75017L7.40073 11.3752H20.4347V13.6252H7.40073L13.0257 19.2502L11.4347 20.8412L3.09375 12.5002L11.4347 4.15918L13.0257 5.75017Z"
                  fill="#6599ED"
                />
              </svg>
            </span>
            授業評価
          </Heading>
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div>
              <Label label="ID">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo && courseInfo?.id + ''}
                  disabled
                />
              </Label>
            </div>
            <div className="col-span-2">
              <Label label="タイトル">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo && courseInfo?.title + ''}
                  disabled
                />
              </Label>
            </div>
          </div>
        </div>
        <div className="mx-8 mb-20">
          <div className="relative p-4 bg-white rounded border border-color-gray-40">
            <div className="overflow-hidden">
              <div className="float-right ms-auto pl-4 flex gap-x-2">
                <button
                  type="button"
                  disabled={disableAction}
                  onClick={handleApprove}
                  className={`inline-block text-sm font-semibold p-2 rounded-md text-center text-color-green-600 bg-color-green-100-2 min-w-[150px] hover:bg-color-green-200 ${!disableAction ? '' : 'cursor-not-allowed opacity-50 hover:bg-color-green-100-2'}`}
                >
                  受理
                </button>
                <button
                  disabled={disableAction}
                  type="button"
                  onClick={showModal}
                  className={`inline-block text-sm font-semibold p-2 rounded-md text-center text-color-red-600 bg-color-red-100 min-w-[150px] hover:bg-color-red-200 ${!disableAction ? '' : 'cursor-not-allowed opacity-50 hover:bg-color-red-100'}`}
                >
                  不受理
                </button>
              </div>
              <div>
                <Tab
                  defaultActiveKey="1"
                  className="tab-evaluation-view shorter"
                >
                  <TabPane tabKey="1" tab="総合">
                    {courseInfo && <TabContent1 courseInfo={courseInfo} />}
                  </TabPane>
                  <TabPane tabKey="2" tab="パート">
                    {courseInfo && <TabContent2 courseInfo={courseInfo} />}
                  </TabPane>
                </Tab>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BasicModal
        title="CS不受理"
        open={isModalOpen}
        onOk={handleReject}
        onCancel={handleCancel}
        centered
        width="680px"
        footer={[
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Button
                type="button"
                variant="outline-tertiary"
                className="w-full"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                variant="danger"
                className="w-full"
                onClick={handleReject}
                disabled={!canReject}
              >
                不受理
              </Button>
            </div>
          </div>,
        ]}
      >
        <Label label="不受理のコメント">
          <TextField
            type="textarea"
            classNameChild="w-full block h-[72px]"
            placeholder="不受理のコメントを入れてください"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </Label>
      </BasicModal>
    </>
  );
};

export default EvaluationReview;
