import mainAxiosInstance from './api';
import {
  IClassListUser,
  ISupporterUser,
  IUserListSchema,
} from '../constants/types';

class UserService {
  static async getAll(page: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<IUserListSchema>(`users/`, {
        params: { page: page },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }

  static async update(id: any, formData: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.put(`users/${id}/`, {
        ...formData,
      });
      return response.status;
    } catch (error) {
    }
  }

  static async delete(id: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.delete(`users/${id}/`, {});
      if (response.status === 204) {
        return response.status;
      }
    } catch (error) {
    }
  }

  static async create(formData: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(`users/`, {
        ...formData,
      });
      if (response.status === 201) {
        return response.status;
      } else {
        return response.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        return Object.keys(error?.response.data)[0];
      }

      return false;
    }
  }
  static async getClassListUser(id: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<IClassListUser>(
        `users/${id}/created/`,
        {},
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return false;
    }
  }
  static async getSupporterUser(id: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<ISupporterUser>(
        `users/${id}/supported/`,
        {},
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return false;
    }
  }
}

export default UserService;
