import { Heading } from "../../components/templates/Heading";
import { TabLinks } from "../../components/templates/TabLinks";

const ProcessYoutube = () => {
    return (
        <>
            <div className="max-w-full">
                <TabLinks className="mb-4" />
                <div className="px-8 py-5 bg-white mb-4">
                    <Heading>
                        <p className="text-center">Youtube video is uploading ...</p>
                    </Heading>
                </div>
            </div>
        </>
    );
}

export default ProcessYoutube;