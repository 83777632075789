import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

var PropTypes = require('prop-types');
export const showToast = ({ title, text }: any) => {
  return (
    <div>
      <h3 className="mb-0 text-base font-semibold text-color-gray-06">
        {title}
      </h3>
      <div className="text-sm leading-[20px] text-color-gray-06">{text}</div>
    </div>
  );
};

const showToastButtonUndo = ({ text }: any) => {
  return (
    <div className="flex items-center gap-3">
      <div className="text-sm leading-[20px] text-color-gray-06">{text}</div>
      <button
        type="button"
        className="inline-block text-center py-1 px-3 text-sm text-color-blue-01 whitespace-nowrap"
      >
        <span className="rotate-90 inline-block me-2">
          <FontAwesomeIcon icon={faUndo} />
        </span>
        Undo
      </button>
    </div>
  );
};

const showToastProgressBar = ({ title }: any) => {
  return (
    <div>
      <div className="flex">
        <div className="w-4 pt-1 me-2.5">
          <svg
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            fill="var(--toastify-icon-color-info)"
          >
            <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
          </svg>
        </div>
        <h3 className="mb-0 text-base font-semibold text-color-gray-06 shrink grow">
          {title}
        </h3>
      </div>
      <div className="flex items-center">
        <div className="bg-color-gray-02 h-1.5 rounded-full relative shrink grow">
          <div
            className="absolute h-full rounded-full left-0 bg-color-blue-01"
            style={{ width: '40%' }}
          ></div>
        </div>
        <div className="flex items-center ms-2 h-6 min-w-12 text-color-gray-06 text-xs">
          20/50
        </div>
      </div>
      <div className="text-center">
        <a
          href="/"
          className="inline-block text-center py-1 px-3 text-sm text-color-blue-01"
        >
          View details
        </a>
      </div>
    </div>
  );
};

export const ToastMessage = () => {
  const showToastMessageDefault = () =>
    toast('Default Notification', { containerId: 'A' });
  const showToastMessageSuccess = () =>
    toast.success(
      showToast({
        title: 'Success Text',
        text: 'Success Description Success Description Success Description',
      }),
      { containerId: 'B' },
    );
  const showToastMessageInfo = () =>
    toast.info(
      showToast({
        title: 'Info Text',
        text: 'Info Description Info Description Info Description',
      }),
      { containerId: 'C' },
    );
  const showToastMessageError = () =>
    toast.error(
      showToast({
        title: 'Error Text',
        text: 'Error Description Error Description Error Description',
      }),
      { containerId: 'D' },
    );
  const showToastMessageWarning = () =>
    toast.warning(
      showToast({
        title: 'Warning Text',
        text: 'Warning Description Warning Description Warning Description',
      }),
      { containerId: 'E' },
    );
  const showToastMessageButtonUndo = () =>
    toast(
      showToastButtonUndo({
        text: 'Success Description Success Description Success Description',
      }),
      { containerId: 'F' },
    );
  const showToastMessageProgressBar = () =>
    toast(showToastProgressBar({ title: 'Uninstalling' }), {
      containerId: 'G',
    });

  return (
    <>
      <div className="grid grid-cols-5 gap-4 mb-6">
        <Button onClick={showToastMessageDefault}>Toast Message Default</Button>
        <Button onClick={showToastMessageSuccess}>Toast Message Success</Button>
        <Button onClick={showToastMessageInfo}>Toast Message Info</Button>
        <Button onClick={showToastMessageError}>Toast Message Error</Button>
        <Button onClick={showToastMessageWarning}>Toast Message Warning</Button>
        <Button onClick={showToastMessageButtonUndo}>
          Toast Message Button Undo
        </Button>
        <Button onClick={showToastMessageProgressBar}>
          Toast Message Process
        </Button>
      </div>
      {/* autoClose={1500} */}
      <ToastContainer
        containerId="A"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="B"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="C"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="D"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="E"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="F"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer
        containerId="G"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export const toastSuccess = (title: string, content: string) => {
  return toast.success(
    showToast({
      title: title,
      text: content,
    }),
    { containerId: 'B' },
  );
};

export const toastError = (title: string, content: string) => {
  return toast.error(
    showToast({
      title: title,
      text: content,
    }),
    { containerId: 'D' },
  );
};

export const ListToastContainer = () => {
  return (
    <>
      <ToastContainer
        containerId="A"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="B"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="C"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="D"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="E"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="F"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
      <ToastContainer
        containerId="G"
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        theme="light"
      />
    </>
  );
};

export const ToastContainerCommon = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

showToast.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ToastMessage;
