import mainAxiosInstance from './api';

class ClassListService {
  static async getAll(
    page: any,
    cs_status: any,
    demo_status: any,
    demo_type: any,
    fb_status: any,
    submit_start: any,
    submit_end: any,
    demo_start: any,
    demo_end: any,
    title: any,
    hide:string
  ): Promise<any> {
    try {
      const response = await mainAxiosInstance.get(`courses/`, {
        params: {
          page: page,
          cs_status: cs_status,
          demo_status: demo_status,
          demo_type: demo_type,
          fb_status: fb_status,
          submit_start: submit_start,
          submit_end: submit_end,
          demo_start: demo_start,
          demo_end: demo_end,
          title: title,
          hide : hide
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }
  static async copyCourse(id: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(`courses/${id}/copy/`);
      if (response.status === 201) {
        return response.status;
      }
    } catch (error) {
    }
  }
  static async hiddenCourse(id: any, payload: boolean): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(`courses/${id}/visible/`, {
        is_visible: payload,
      });
      if (response.status === 200) {
        return response.status;
      }
    } catch (error) {
    }
  }
}

export default ClassListService;
