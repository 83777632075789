import { BasicModal } from '../../../components/molecules/BasicModal';
import Label from '../../../components/atoms/Label';
import Checkbox from '../../../components/atoms/Checkbox';
import SelectField from '../../../components/atoms/Select';
import Button from '../../../components/atoms/Button';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { genderData, ROLE_DATA } from '../../../constants';
import { Controller, useForm } from "react-hook-form";
import TextFieldForm from "../../../components/atoms/TextFieldForm";
import { useEffect, useState } from 'react';
import { IUserForm } from '../../../constants/types';
import UserService from '../../../services/UserService';
import { showToastMessage } from '../../../utils/common';
import { updateUserNotSuccess, createUserSuccess, updateUserSuccess, usernameAlreadyExists, emailAlreadyExists, txtUserIdRequeried, txtEmailRequired, txtValidateCustomEmail, txtValidateUserIdCustom, txtMaxLengthUserId, txtGenderRequired, txtFirstNameRequired, txtRoleRequired } from '../../../constants/message';
import { Loader } from '../../../components/atoms/Loader';

const UserForm = ({ isModalOpen, setIsModalOpen, userData, getData }: any) => {
  const [roleChecked, setRoleChecked] = useState<number[]>([]);
  const { control, handleSubmit, reset, trigger, formState: { errors } } = useForm<IUserForm>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async(_formData: any) => {
    setIsLoading(true);
    const data = {
      ..._formData,
      roles: roleChecked,
    };

    if (userData) {
      await UserService.update(userData?.id, data).then(r => {
        if (r === 200) {
          setIsModalOpen(false);
          showToastMessage('success', '', updateUserSuccess);
          getData();
        } else {
          showToastMessage('error', '', updateUserNotSuccess);
        }
      });
    } else {
      await UserService.create(data).then(r => {
        if (r === 201) {
          setIsModalOpen(false);
          showToastMessage('success', '', createUserSuccess);
          getData();
        } else {
          if (r === 'email') {
            showToastMessage('error', '', emailAlreadyExists);
          } else {
            showToastMessage('error', '', usernameAlreadyExists);
          }
        }
      });
    }
    setIsLoading(false)
  };

  useEffect(() => {
      reset({
        username: userData?.username ?? '',
        email: userData?.email ?? '',
        gender: userData?.gender ?? '',
        first_name: userData?.first_name ?? '',
        age: userData?.age ?? 0,
        position: userData?.position ?? '',
        affiliation: userData?.affiliation ?? '',
        education: userData?.education ?? '',
        remark: userData?.remark ?? '',
        role: userData?.role ?? 0,
      });
      setRoleChecked(userData?.roles ?? [0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, userData, reset]);
  const handleCancel = () => {
    setIsModalOpen(false);
    setRoleChecked([]);
  };

  const handleCheckBox = (value: number) => {
    setRoleChecked((prevState: number[]) => {
      if (prevState.includes(value)) {
        return prevState.filter(item => item !== value);
      } else {
        return [...prevState, value];
      }
    });
  };

  return (
    <BasicModal
      title={userData ? 'ユーザーの編集' : 'ユーザーの新規作成'}
      open={isModalOpen}
      onCancel={handleCancel}
      centered
      width="915px"
      footer={[
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="text-right">
            <Button
              type="submit"
              variant="primary"
              size="medium"
              className="min-w-[294px]"
              disabled={isLoading}
              onClick={() => trigger()}
            >
              {userData ? '保存' : '新規作成'}
            </Button>
          </div>
        </form>
      ]}
    >
      <Loader isLoading={isLoading}>
        <div className="grid grid-cols-2 gap-4">
          <Label label="ユーザーID" className="mb-5" required={!userData}>
            <Controller
              control={control}
              name="username"
              rules={{
                required: txtUserIdRequeried,
                pattern: {
                  value: /^[a-zA-Z0-9@./+/\-/_ ]+$/,
                  message: txtValidateUserIdCustom,
                },
                maxLength: {
                  value: 30,
                  message: txtMaxLengthUserId,
                }
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextFieldForm
                    type="text"
                    placeholder="ユーザーID"
                    classNameChild="w-full"
                    onerror={!!errors.username?.message}
                    errorMessage={errors.username?.message}
                    disabled={userData}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </Label>
          <Label label="メールアドレス" className="mb-5" required={!userData}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: txtEmailRequired,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: txtValidateCustomEmail,
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TextFieldForm
                    disabled={userData}
                    type="text"
                    placeholder="email@example.com"
                    classNameChild="w-full"
                    onChange={onChange}
                    value={value}
                    onerror={!!errors.email?.message}
                    errorMessage={errors.email?.message}
                  />
                )
              }}
            />
          </Label>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <Label label="性別" className="mb-5" required>
            <Controller
              control={control}
              name="gender"
              rules={{
                required: txtGenderRequired,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <SelectField
                      classNameChild="w-full appearance-none"
                      onChange={onChange}
                      value={value}
                      options={genderData}
                      iconRight={faChevronDown}
                      onerror={!!errors.gender?.message}
                    />
                    <span className='text-red-600'>{!!errors.gender?.message && ('このフィールドは必須です')}</span>
                  </>

                );
              }}
            />
          </Label>
          <Label label="氏名" className="mb-5" required>
            <Controller
              control={control}
              name="first_name"
              rules={{
                required: txtFirstNameRequired,
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextFieldForm
                    type="text"
                    placeholder="氏名"
                    classNameChild="w-full"
                    value={value}
                    onChange={onChange}
                    onerror={!!errors.first_name?.message}
                    errorMessage={errors.first_name?.message}
                  />
                )
              }} />
          </Label>

          <Label label="所属" className="mb-5">
            <Controller
              control={control}
              name="affiliation"
              render={({ field: { value, onChange } }) => {
                return (
                  <TextFieldForm
                    type="text"
                    placeholder="所属を入力してください"
                    classNameChild="w-full"
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            />
          </Label>
        </div>

        <Label label="権限" className="mb-5" required>
          <div className="grid grid-cols-4 gap-y-3 gap-x-4 mb-5">
            {ROLE_DATA.map((item) => (
              <Controller
                key={item.value}
                name="role"
                rules={{
                  required: txtRoleRequired,
                }}
                control={control}
                render={({ field: { value } }) => (
                  <Checkbox
                    id={item.value}
                    label={item.label}
                    onChange={() => handleCheckBox(item.value)}
                    checked={roleChecked?.includes(item.value)}
                    userForm={true}
                  />
                )}
              />
            ))}
          </div>
          {roleChecked?.length === 0 && <span className="text-red-600">権限を選択してください</span>}
        </Label>


        <Label label="職位" className="mb-5">
          <Controller
            control={control}
            name="position"
            rules={{
              maxLength: {
                value: 255,
                message: '255文字以内で入力してください',
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TextFieldForm
                  type="text"
                  placeholder="職位を入力してください"
                  classNameChild="w-full"
                  value={value}
                  onChange={onChange}
                  onerror={!!errors.position?.message}
                  errorMessage={errors.position?.message}
                />
              );
            }} />
        </Label>


        <Label label="教育歴" className="mb-5">
          <Controller
            control={control}
            name="education"
            rules={{
              maxLength: {
                value: 1000,
                message: '1000文字以内で入力してください',
              }

            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TextFieldForm
                  type="textarea"
                  placeholder="教育を入力してください"
                  classNameChild="w-full"
                  value={value}
                  onChange={onChange}
                  onerror={!!errors.education?.message}
                  errorMessage={errors.education?.message}
                />
              )
            }}
          />
        </Label>
        <Label label="備考" className="mb-0">
          <Controller
            control={control}
            name="remark"
            rules={{
              maxLength: {
                value: 1000,
                message: '1000文字以内で入力してください',
              }
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TextFieldForm
                  type="textarea"
                  placeholder="コメントを入力してください"
                  classNameChild="w-full"
                  value={value}
                  onChange={onChange}
                  onerror={!!errors.remark?.message}
                  errorMessage={errors.remark?.message}
                />
              )
            }}
          />
        </Label>
      </Loader>
    </BasicModal>
  );
};

export default UserForm;
