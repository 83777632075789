import { Spin } from 'antd';
import PropTypes from 'prop-types';

export function Loader({ isLoading, children, isFullScreen,className }: any) {
  return (
    <>
      {isLoading
        ?
        (
          <Spin tip="Loading..." fullscreen={isFullScreen} className={className}>
            {children}
          </Spin>
        )
        :
        (
          <>
            {children}
          </>
        )
      }
    </>
  );
}


Loader.propTypes = {
  isLoading: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Loader.defaultProps = {
  isLoading: false,
  isFullScreen: false,
  children:null,
  className: '',
};
