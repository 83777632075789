import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import Button from '../../components/atoms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/molecules/Modal';
import { RouteKey, rc } from '../../constants/router';
import { txtConfirmPasswordRequired } from '../../constants/message';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');

  const newPassword = watch('new_password', '');

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate(rc(RouteKey.Home).path);
    }
  }, [navigate]);

  useEffect(() => {
    if (!uidb64 || !token) {
      navigate('/not-found');
      return;
    }
    const reqToken = `${uidb64}/${token}`;
    AuthService.checkExpiration(reqToken).then(status => {
      if (status === 404) {
        navigate('/not-found');
      }
    });
  }, [navigate, token, uidb64]);

  const onSubmit = (data: any) => {
    if (!uidb64 || !token) {
      navigate('/not-found');
      return;
    }
    const reqToken = `${uidb64}/${token}`;
    AuthService.changePassword(
      data.new_password,
      data.confirm_password,
      reqToken,
    )
      .then(status => {
        if (status === 200) {
          setOpen(true);
          setModalContent(
            '新パスワードを再設定しました、再度ログインください。',
          );
        } else if (status === 404) {
          alert('トークンが無効または期限切れです。');
          navigate('/not-found');
        }
      })
      .catch(err => {
      });
  };

  return (
    <>
      <div className="min-w-screen min-h-screen bg-no-repeat bg-cover bg-[url('/public/assets/bg_login.png')] flex items-center justify-center p-4">
        <div className="w-[1170px] mx-auto">
          <div className="flex gap-4 justify-between">
            <div className="pt-24 inline-block text-center">
              <img className="mx-auto block" src="/assets/logo.svg" alt="" />
              <h3 className="mb-0 mt-8 text-white text-heading-1 font-semibold drop-shadow-cs-1">
                <span className="font-black">CS</span> Assessment
              </h3>
            </div>
            <div className="bg-white rounded-lg drop-shadow-cs-2 p-8 h-[420px] w-[450px]">
              <h3 className="text-heading-3 font-semibold mb-6 text-black">
                パスワード再設定
              </h3>
              <div className="text-sm text-black mb-7">
                新しいパスワードを入力し、再設定してください。
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextFieldForm
                  className="mb-3"
                  classNameChild="w-full"
                  placeholder="新しいパスワード"
                  iconRight={visible ? faEyeSlash : faEye}
                  onClickIconRight={() => setVisible(!visible)}
                  canClickIconRight={true}
                  type={visible ? 'text' : 'password'}
                  {...register('new_password', {
                    required: '新パスワードを入力ください',
                    minLength: {
                      value: 6,
                      message: 'この項目は少なくとも6文字以上にしてください。',
                    },
                  })}
                  onerror={errors.new_password?.message ? true : false}
                  errorMessage={errors.new_password?.message}
                />
                <TextFieldForm
                  className="mb-10"
                  classNameChild="w-full"
                  placeholder="新しいパスワード確認"
                  type={'password'}
                  {...register('confirm_password', {
                    required: txtConfirmPasswordRequired,
                    minLength: {
                      value: 6,
                      message: 'この項目は少なくとも6文字以上にしてください。',
                    },
                    validate: value =>
                      value === newPassword ||
                      '新パスワード確認が正しくありません。',
                  })}
                  onerror={errors.confirm_password?.message ? true : false}
                  errorMessage={errors.confirm_password?.message}
                />
                <Button type="submit" variant="primary" className="w-full">
                  パスワードを設定する
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-4">
          <h1 className="text-heading-3 text-black">成功</h1>
          <hr className="border-t-solid border-1 border-grey mb-2" />
          <p className="mb-4">{modalContent}</p>
          <div className="flex flex-row justify-center">
            <button
              className="border border-neutral-300 rounded-lg py-1.5 px-10
               bg-blue-500 hover:bg-blue-600 text-white"
              onClick={() => {
                setOpen(false);
                navigate(rc(RouteKey.Login).path);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ResetPassword;
