import PropTypes from 'prop-types';

const ToastMessageCommon = ({ title, text }: any) => {
    return (
      <div>
        <h3 
          className="mb-0 text-base font-semibold text-color-gray-06" 
          dangerouslySetInnerHTML={{
            __html: title.replace(/\n/g, '<br />')
          }}
        >
        </h3>
        <div 
          className="text-sm leading-[20px] text-color-gray-06"
          dangerouslySetInnerHTML={{
            __html: text.replace(/\n/g, '<br />')
          }}
        >
        </div>
      </div>
    );
};

ToastMessageCommon.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default ToastMessageCommon;