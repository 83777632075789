import { Table } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import type { PaginationProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import {
  faAngleRight,
  faAnglesRight,
  faAngleLeft,
  faAnglesLeft,
} from '@fortawesome/free-solid-svg-icons';
import { linkNavigateTable } from '../../utils/common';

const TableStyle = styled.div.attrs({})`
  .ant-table-wrapper table {
    border: 1px solid #ebebed;
    border-radius: 4px;
  }
  [class^='ant-table'] {
    font-family: 'Noto Sans', sans-serif;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: none;
    padding: 7px 16px 8px;
    color: #2f3642;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th:before,
  .ant-table-wrapper .ant-table-thead > tr > td:before {
    display: none;
  }
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom-color: #ebebed;
    color: #4b515b;
    font-size: 14px;
    padding: 7px 16px 8px;
    line-height: 20px;
  }
  .ant-table-wrapper .ant-table-tbody > tr:last-child > th,
  .ant-table-wrapper .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .ant-table-pagination {
    gap: 8px;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
  .ant-pagination .ant-pagination-item {
    font-family: 'Noto Sans', sans-serif;
    width: auto;
    height: auto;
    min-width: unset;
    line-height: normal;
    border: none;
    margin: 0;
  }
  .ant-pagination .ant-pagination-next a:not(:last-child),
  .ant-pagination .ant-pagination-prev a:not(:last-child) {
    margin-right: 8px;
  }
  .ant-pagination .ant-pagination-next a,
  .ant-pagination .ant-pagination-prev a,
  .ant-pagination .ant-pagination-item a {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #000;
    font-family: 'Noto Sans', sans-serif;
    border-radius: 6px;
    display: inline-block;
  }
  .ant-pagination .ant-pagination-next a:hover,
  .ant-pagination .ant-pagination-prev a:hover,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active a,
  .ant-pagination .ant-pagination-item a:hover {
    background-color: #3f7fe8;
    color: #fff;
  }
  .ant-pagination .ant-pagination-item:hover {
    background-color: transparent;
  }

  .ant-pagination .ant-pagination-disabled a {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .ant-pagination .ant-pagination-disabled a:hover {
    background-color: transparent;
    color: #000;
  }

  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: rgba(0, 0, 0, 0.5);
    font-family: 'Noto Sans', sans-serif;
  }
  .ant-pagination .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
  .ant-pagination
    .ant-pagination-jump-next:hover
    .ant-pagination-item-ellipsis {
    opacity: 1;
    color: #3f7fe8;
  }
  .ant-pagination
    .ant-pagination-jump-prev:hover
    .ant-pagination-item-link-icon,
  .ant-pagination
    .ant-pagination-jump-next:hover
    .ant-pagination-item-link-icon {
    opacity: 0;
  }
  [class^='ant-table'] .ant-table-thead .ant-table-cell.ant-table-column-child {
    background: #ecf2fd;
  }
  [class^='ant-table']
    .ant-table-thead
    .ant-table-cell.ant-table-column-child.first,
  [class^='ant-table']
    .ant-table-thead
    .ant-table-cell.ant-table-column-child.last {
    border-left: 1px solid #c3d7f8;
  }
  .ant-table-wrapper .ant-table-selection-column {
    text-align: left !important;
  }
  .ant-table-wrapper
    .ant-table-tbody
    .ant-table-row.ant-table-row-selected
    > .ant-table-cell {
    background: #f0f6ff !important;
  }
  .ant-table-wrapper .ant-checkbox .ant-checkbox-inner {
    border-radius: 2px;
  }
  .ant-table-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4a86e0;
    border-color: #4a86e0;
  }
  .ant-table-wrapper .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #4a86e0;
  }
  .ant-table-cell {
    max-width: 24rem !important;
  }
`;

export const BasicTable = ({
  columns,
  data,
  pageSize,
  currentPage,
  position,
  totalData,
  totalPage,
  rowClassName,
  rowKey,
  rowSelection,
  setCurrentPage,
  modal,
  scrollX,
}: any) => {
  const handleJumpToFirstPage = (e: any) => {
    if (currentPage === 1 || currentPage === undefined) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setCurrentPage(1);
  };
  const handlePaginationChange = (page: number, pageSize?: number) => {
    if (currentPage === page) return;
    setCurrentPage(page);
  };
  const handleJumpToLastPage = (e: any) => {
    if (currentPage === totalPage) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setCurrentPage(totalPage);
  };
  const location = useLocation();
  const itemRender: PaginationProps['itemRender'] = (
    page,
    type,
    originalElement,
  ) => {
    if (type === 'prev') {
      return (
        <>
          {' '}
          <Link
            to={linkNavigateTable(1, location)}
            className="page-first"
            onClick={handleJumpToFirstPage}
          >
            <FontAwesomeIcon icon={faAnglesLeft} />
          </Link>{' '}
          <Link
            to={linkNavigateTable(currentPage - 1, location)}
            onClick={e => {
              if (currentPage === 1 || currentPage === undefined) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              handlePaginationChange(currentPage - 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Link>
        </>
      );
    }
    if (type === 'page') {
      return (
        <Link
          to={linkNavigateTable(page, location)}
          onClick={() => handlePaginationChange(page)}
        >
          {page}
        </Link>
      );
    }
    if (type === 'next') {
      return (
        <>
          <Link
            to={linkNavigateTable(currentPage + 1, location)}
            onClick={e => {
              if (currentPage === totalPage) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              handlePaginationChange(currentPage + 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Link>{' '}
          <Link
            to={linkNavigateTable(totalPage, location)}
            className="page-last"
            onClick={handleJumpToLastPage}
          >
            <FontAwesomeIcon icon={faAnglesRight} />
          </Link>
        </>
      );
    }
    return originalElement;
  };

  return (
    <>
      <TableStyle>
        <Table
          columns={columns}
          dataSource={data}
          rowClassName={rowClassName}
          rowKey={rowKey}
          rowSelection={rowSelection}
          pagination={
            !modal && {
              current: currentPage,
              pageSize: pageSize,
              position: [position],
              showLessItems: true,
              itemRender: itemRender,
              total: totalData,
              showSizeChanger: false,
            }
          }
          scroll={
            (modal && {
              y: 400,
            }) ||
            (scrollX && { x: 'max-content' })
          }
        />
      </TableStyle>
    </>
  );
};

BasicTable.prototype = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'topLeft',
    'topCenter',
    'topRight',
    'none',
  ]),
  scrollX: PropTypes.bool,
};
BasicTable.defaultProps = {
  className: '',
  children: '',
  position: 'bottomLeft',
  scrollX: false,
};
