import axios, { CancelTokenSource } from 'axios';
import { ICourseSchema, IPrompt, IReview } from '../constants/types';
import mainAxiosInstance from './api';

export const courseDetailService = async (id: string) => {
  try {
    const response = await mainAxiosInstance.get(`courses/${id}/`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const evaluateUpdateService = async (
  id: string,
  payload: ICourseSchema,
) => {
  try {
    const response = await mainAxiosInstance.post(`courses/${id}/evaluate/`, {
      ...payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const criteriaListService = async () => {
  try {
    const response = await mainAxiosInstance.get('criteria/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GPTPromptService = async (
  id: string,
  payload: IPrompt,
  source: CancelTokenSource,
) => {
  try {
    const response = await mainAxiosInstance.post(
      `courses/${id}/eval-prompt/`,
      {
        ...payload,
      },
      { cancelToken: source.token },
    );
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log('Previous request was cancelled');
    } else {
      throw error;
    }
  }
};

export const evaluationReviewAction = async (id: string, payload: IReview) => {
  try {
    const response = await mainAxiosInstance.post(
      `courses/${id}/eval-review/`,
      { ...payload },
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const demoReviewService = async (
  id: string,
  demoId: string,
  payload: any,
) => {
  try {
    const response = await mainAxiosInstance.post(
      `courses/${id}/demos/${demoId}/demo-review/`,
      {
        ...payload,
      },
    );
    return response;
  } catch (error) {
    return error;
  }
};
