import { BasicModal } from '../../../components/molecules/BasicModal';
import { BasicTable } from '../../../components/templates/BasicTable';
import Button from '../../../components/atoms/Button';
import type { TableProps } from 'antd';
import Status from '../../../components/atoms/Status';
import { useEffect, useRef, useState } from 'react';
import UserService from '../../../services/UserService';
import moment from 'moment';
import BasicTooltip from '../../../components/atoms/Tooltips';
import { Loader } from '../../../components/atoms/Loader';
import { IClassListUser } from '../../../constants/types';
import { colorEvaluation, dateFormat, EVALUATION_RATING_STATUS, timeFormat } from '../../../constants';

const getEvaluationRatingLabel = (value : number) => {
  const ratingStatus = EVALUATION_RATING_STATUS.find(status => status.value === value);
  return ratingStatus ? ratingStatus.label : '';
}

const columns: TableProps<IClassListUser>['columns'] = [
  {
    title: 'ID.',
    dataIndex: 'id',
    key: 'id',
    width: 70,
  },
  {
    title: '作成日',
    dataIndex: 'created_date',
    key: 'created_date',
  },
  {
    title: '授業タイトル',
    dataIndex: 'title',
    key: 'title',
    width: 200,
    render: class_title => (
      <BasicTooltip trigger="hover" placement="top" text={class_title}>
      <div className="text-color-blue-700 max-w-28 overflow-hidden whitespace-nowrap text-overflow-ellipsis">
        {class_title}
      </div>
      </BasicTooltip>
    ),
  },
  {
    title: '評価',
    dataIndex: 'overall_rating',
    key: 'overall_rating',
    render: (overall_rating) => {
      return (
        <>
         <Status type="text" variant={`${colorEvaluation[overall_rating]}`} className={`text-sm ${colorEvaluation[overall_rating]}`}>{getEvaluationRatingLabel(overall_rating)}</Status>
        </>
      ) 
    }
  },
  {
    title: 'CSステータス',
    dataIndex: 'status_display',
    key: 'status_display',
  },
  {
    title: '模擬授業実施日',
    dataIndex: 'demo_info',
    key: 'demo_info',
    width: 240,
    render: demo_info => (
      <div className="font-semibold">
        {demo_info?.map((item: any, index: number) => {
          const startMoment = moment(item.start_at);
          const endMoment = moment(item.end_at);
          const formattedDate = startMoment.format(dateFormat);
          const formattedStartTime = startMoment.format(`　${timeFormat}`);
          const formattedEndTime = endMoment.format(timeFormat);
          return (
            <div key={index} className="flex">
              {item.demo_type === 0 ? (
                <div>{item.start_at ? formattedDate : ''}</div>
              ) : (
                <>
                  <div>{formattedDate} </div>
                  <span className="mx-0.5"></span>
                  <div className="text-color-blue-800">{`${formattedStartTime} - ${formattedEndTime}`}</div>
                </>
              )}
            </div>
          );
        })}
      </div>
    ),
  },
  {
    title: '授業タイプ',
    dataIndex: 'demo_info',
    key: 'demo_type',
    render: text => (
      <div>
        {text?.map((item: any) => {
          return item.demo_type === 1 ? (
            <div className="text-color-green-600 font-medium">
              オンライン
            </div>
          ) : item.demo_type === 0 ? (
            <div className="text-color-gray-200 font-medium">動画</div>
          ) : (
            ''
          );
        })}
      </div>
    ),
  },
  {
    title: '完了日',
    dataIndex: 'demo_info',
    key: 'demo_info',
    render: demo_info => (
      <div className="font-semibold">
        {demo_info?.map((item: any, index: number) => {
          const endMoment = moment(item.end_at);
          const formattedEndTime = endMoment.format(dateFormat);
          return (
            <div key={index} className="flex">
              {item.end_at ? formattedEndTime : ''}
            </div>
          );
        })}
      </div>
    ),
  },

];

const ModalClasses = ({ isModalOpen, handleOk, setIsModalOpen, idUser }: any) => {
  const [classListUser, setClassListUser] = useState<any>();
  const isFirstRender = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isModalOpen === true) {
      UserService.getClassListUser(idUser).then((data) => {
        setClassListUser(data);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setClassListUser(null);
    setIsLoading(false);
  }


  return (
    <BasicModal
      title="授業一覧"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width="80%"
      footer={[
        <div className="text-right">
          <Button
            type="button"
            variant="tertiary"
            size="medium"
            className="min-w-[194px]"
            onClick={handleOk}
          >
            キャンセル
          </Button>
        </div>,
      ]}
    >
      <Loader isLoading={isLoading}>
        <BasicTable
          columns={columns}
          data={classListUser}
          pageSize={10}
          totalData={classListUser?.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPage={classListUser?.length / 10}
          modal={true}
        />
      </Loader>
    </BasicModal>
  );
}
export default ModalClasses;