import axios from 'axios';
import {
  getAccessToken,
  removeAccessToken,
  removeCode,
  removeProfile,
} from '../helper/localStorage';
import config from '../config';
import Path from '../config/Paths';
import { RouteKey, rc } from '../constants/router';

const mainRequestConfig = {
  baseURL: config.serviceBaseURL,
  header: {
    'Content-Type': 'application/json',
  },
};

const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = getAccessToken();
    if (token) config.headers.Authorization = `Token ${token}`;
    return config;
  },
  error => Promise.reject(error),
);

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const errorCodeList = [401, 500]; // Define your list of error codes here
    if (error.response && errorCodeList.includes(error.response.status)) {
      removeAccessToken();
      removeProfile();
      removeCode();
      window.location.href = Path.LOGIN;
      return Promise.resolve(error);
    } else if (error.response && error.response.status === 404) {
      window.location.href = '/not-found';
      return Promise.reject(error);
    } else if (error.response && error.response.status === 403) {
      window.location.href = rc(RouteKey.NotPermission).main;
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default mainAxiosInstance;
