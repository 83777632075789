import { Controller } from "react-hook-form";
import SelectOptionSection from "../../components/molecules/SelectOptionSection";
import ViewOnlySubSection from "./ViewOnlySubSection";
import CustomTimePicker from "../../components/molecules/CustomTimePicker";

export default function ViewOnlySection({ control, fields, formData, isViewOnly, errors, reset }: any) {
    return (
        <>
            <div className="flex flex-col gap-6">
                {fields.map((fie: any, index: any) => (
                    <div
                        className="border border-color-blue-200 bg-white rounded"
                        key={fie.id}>
                        <div className="bg-color-blue-5 h-6 text-center flex items-center justify-center text-xs text-color-blue-300 rounded-tl rounded-tr">
                        </div>
                        <div className="p-4">
                            <div className="flex gap-x-4 mb-3">
                                <div className="w-[180px]">
                                    <Controller
                                        control={control}
                                        name={`sections.${index}.type`}
                                        render={({ field }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <SelectOptionSection
                                                    onChange={onChange}
                                                    val={value}
                                                    formData={formData}
                                                    index={index}
                                                    isViewOnly={isViewOnly}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div className="w-[120px]">
                                    <Controller
                                        control={control}
                                        name={`sections[${index}].subs[0].duration`}
                                        rules={{
                                            validate: {
                                                isNotZero: value => value !== '00:00' || 'Value not must be "00:00"'
                                            }
                                        }}
                                        render={({ field }) => {
                                            const { onChange, value } = field;
                                            return (
                                                <CustomTimePicker
                                                    val={value}
                                                    onChange={onChange}
                                                    fields={formData}
                                                    reset={reset}
                                                    indSection={index}
                                                    indSubSection={0}
                                                    id={`sections[${index}].subs[0].duration`}
                                                    onError={errors?.sections && errors?.sections[index]?.subs && errors.sections[index]?.subs[0]?.duration ? true : false}
                                                    textNote={errors?.sections && errors?.sections[index]?.subs && errors?.sections[index]?.subs[0]?.duration?.message}
                                                    readOnly={isViewOnly}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            {/* e: row */}
                            <div
                                className={`flex flex-col gap-4 `}
                            >
                                <ViewOnlySubSection
                                    ind={index}
                                    subs={fie.subs}
                                    errors={errors}
                                    control={control}
                                    isViewOnly={isViewOnly}
                                />
                            </div>
                            {/* e: group */}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}