import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const TextFieldForm = React.forwardRef(({
    type,
    placeholder,
    disabled,
    value,
    iconLeft,
    iconRight,
    canClickIconRight,
    onClickIconRight,
    onChange,
    onerror,
    errorMessage,
    className,
    classNameChild,
    ...textFieldProps
}: any, ref) => {
    let formGroup = '';
    let formInputName = 'relative';
    let inputClassname =
        'text-base py-[11px] text-color-gray-600 bg-white rounded-md border border-solid border-color-gray-03 placeholder:text-color-gray-50 hover:border-color-blue-400 hover:outline-0 focus:border-color-blue-700 focus:shadow-[0 0 2px rgba(202,223,255,0.7)] disabled:bg-color-gray-02 disabled:border-color-gray-03 focus-visible:border-color-blue-700 focus-visible:outline-0';

    if (iconLeft && iconRight) {
        inputClassname += ' px-8';
    } else if (iconLeft) {
        inputClassname += ' ps-8 pe-[11px]';
    } else if (iconRight) {
        inputClassname += ' ps-[11px] pe-8';
    } else {
        inputClassname += ' px-3';
    }

    if (onerror) {
        inputClassname +=
            ' border-color-red-600 hover:border-color-red-600 focus-visible:border-color-red-600';
    }

    return (
        <>
            {type === 'text' || type === 'password' || type === 'number' ? (
                <div className={formGroup}>
                    <div className={className + ` ${formInputName}`}>
                        {iconLeft && (
                            <div className={`text-sm text-color-gray-90 absolute ${errorMessage ? 'top-1/3' : 'top-1/2'} -translate-y-1/2 left-3`}>
                                {<FontAwesomeIcon icon={iconLeft} />}
                            </div>
                        )}
                        <input
                            type={type}
                            className={classNameChild + ` ${inputClassname}`}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            ref={ref}
                            {...textFieldProps}
                        />
                        {iconRight && (
                            <div className={`text-sm text-color-gray-90 absolute right-3 -translate-y-1/2 ${errorMessage ? 'top-1/3' : 'top-1/2'} ${canClickIconRight ? 'cursor-pointer' : ''}`} onClick={onClickIconRight}>
                                {<FontAwesomeIcon icon={iconRight}  />}
                            </div>
                        )}
                        {errorMessage && (
                            <span className="text-red-600">{errorMessage}</span>
                        )}
                    </div>
                </div>
            ) : (
                <div className={className + ` ${formInputName}`}>
                    <textarea
                        className={classNameChild + ` ${inputClassname}`}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        onChange={onChange}
                        ref={ref}
                        {...textFieldProps}
                    />
                    {errorMessage && (
                      <span className="text-red-600">{errorMessage}</span>
                    )}
                </div>
            )}
        </>
    );
});

TextFieldForm.propTypes = {
    type: PropTypes.oneOf(['text', 'textarea', 'password']),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onerror: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    classNameChild: PropTypes.string,
    iconLeft: PropTypes.object,
    iconRight: PropTypes.object,
    onClickIconRight: PropTypes.func,
    canClickIconRight: PropTypes.bool,
};

TextFieldForm.defaultProps = {
    type: 'text',
    className: '',
    classNameChild: '',
    errorMessage: '',
    onerror: false,
    disabled: false, // Default disabled state is false
    iconLeft: null,
    iconRight: null,
    canClickIconRight: false,
    onClickIconRight: () => { },
    onChange: () => { },
};

export default TextFieldForm;
