import mainAxiosInstance from './api';

class CourseService {
  static async createCourse(formData: any): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.post('courses/', {
        ...formData,
      });

      return response;
    } catch (error) {
      return false;
    }
  }

  static async getDataCourse(
    courseId: string | undefined,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.get(`courses/${courseId}/`, {});

      return response;
    } catch (error) {
      return false;
    }
  }

  static async updateCourse(
    formData: any,
    courseId: string | undefined,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.put(`courses/${courseId}/`, {
        ...formData,
      });

      return response;
    } catch (error) {
      return false;
    }
  }

  static async deleteCourse(courseId: any): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.delete(
        `courses/${courseId}/`,
        {},
      );
      return response;
    } catch (error) {
      return false;
    }
  }

  static async supporterAssignment(
    courseId: string | undefined,
    demoId: string | undefined,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.get(
        `courses/${courseId}/demos/${demoId}/assignment/`,
      );

      return response;
    } catch (error) {
      return false;
    }
  }

  static async sendMailAssignmentSupporter(
    courseId: any,
    formData: any,
    demoId: any,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.post(
        `courses/${courseId}/demos/${demoId}/assignment/`,
        {
          ...formData,
        },
      );

      return response;
    } catch (error) {
      return false;
    }
  }

  static async completeAssignment(
    courseId: any,
    formData: any,
    demoId: any,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.post(
        `courses/${courseId}/demos/${demoId}/finish-assignment/`,
        {
          ...formData,
        },
      );

      return response;
    } catch (error) {
      return false;
    }
  }

  static async checkCourseComplete(
    courseId: string | undefined,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.get(
        `courses/${courseId}/status/`,
        {},
      );
      return response;
    } catch (error) {
      return false;
    }
  }

  static async getDataCourseDemoRequest(
    courseId: string | undefined,
    demoId: string | undefined,
  ): Promise<any | boolean> {
    try {
      const response = await mainAxiosInstance.get(
        `courses/${courseId}/demos/${demoId}/demo-review/`,
        {},
      );

      return response;
    } catch (error) {
      return false;
    }
  }
}

export default CourseService;
