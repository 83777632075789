import mainAxiosInstance from './api';


class DashboardService {
  static async getAll(): Promise<any> {
    try {
      const response = await mainAxiosInstance.get(
        '/dashboard/'
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
    }
  }
}

export default DashboardService;