import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
var PropTypes = require('prop-types');

export const Heading = ({className, children, iconPrefix, iconSuffix} : any) => {
  // let classNames = "pb-3.5 " + className;
  return (
    <>
      <div className={className}>
        <h2 className="mb-0 text-xl font-semibold">
          {iconPrefix && <FontAwesomeIcon icon={iconPrefix}/>}
          {children}
          {iconSuffix && <FontAwesomeIcon icon={iconSuffix}/>}
        </h2>
      </div>
    </>
  );
};

Heading.prototype = {
  iconPrefix: PropTypes.object,
  iconSuffix: PropTypes.object,
  children: PropTypes.node.isRequired,
}
Heading.defaultProps = {
  iconPrefix: '',
  iconSuffix: '',
  children: '',
}