// export const TimePicker = () => <></>;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

var PropTypes = require('prop-types');

const IconButton = ({ 
  type, 
  variant, 
  size, 
  className, 
  icon, 
  href, 
  onClick, 
  disabled, 
  children,
  onMouseEnter,
  onMouseLeave,
  ...buttonProps
 }: any) => {
  // Determine the classNames based on the variant prop
  let classNames = 'rounded-md border-0 p-0 font-normal inline-block ' + className;

  let primaryContained = 'bg-transparent text-color-blue-01 text-center hover:bg-color-blue-02 focus:bg-transparent focus:text-color-blue-01';
  let primaryContainedDisabled = 'bg-tranparent text-color-blue-03 cursor-not-allowed';

  let tertiaryContained = 'bg-transparent text-color-gray-default text-center hover:bg-color-gray-02 focus:bg-transparent focus:text-color-blue-01';
  let tertiaryContainedDisabled = ' bg-tranparent text-color-gray-03 cursor-not-allowed';

  let dangerContained = 'bg-transparent text-color-red-500 text-center hover:bg-color-red-50 focus:bg-color-red-50';
  let dangerContainedDisabled = ' bg-tranparent text-color-red-03 cursor-not-allowed';

  let smallSize = " text-sm leading-6 w-6"
  let largeSize = " text-lg leading-9 w-9"

  // color button
  if (disabled) {
    if (variant === 'primary') {
      classNames += primaryContainedDisabled
    }
    else if (variant === 'tertiary') {
      classNames += tertiaryContainedDisabled
    }
    else if (variant === 'danger') {
      classNames += dangerContainedDisabled
    }
  }
  else {
    if (variant === 'primary') {
      classNames += primaryContained
    }
    else if (variant === 'tertiary') {
      classNames += tertiaryContained
    }
    else if (variant === 'danger') {
      classNames += dangerContained
    }
  }

  // size button
  if (size === 'small') {
    classNames += smallSize;
  }
  else {
    classNames += largeSize;
  }


  return (
    <>
      {
        type === 'link' ?

          <Link to={href} className={classNames} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...buttonProps}>
            <FontAwesomeIcon icon={icon} />
          </Link>
          :
          <button type={type} className={classNames} onClick={onClick} disabled={disabled} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...buttonProps}>
            <FontAwesomeIcon icon={icon} />
          </button>
      }
    </>
  );
};

IconButton.propTypes = {
  type: PropTypes.oneOf(['button', 'link']),
  variant: PropTypes.oneOf(['primary', 'tertiary', 'outline-primary', 'outline-tertiary', 'text-primary', 'text-tertiary', 'text-link']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  width: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool, // New prop for disabled state
  children: PropTypes.node,
  icon: PropTypes.object, // Icon component for prefix
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

IconButton.defaultProps = {
  type: 'button',
  variant: 'tertiary',
  size: 'large',
  className: '',
  disabled: false, // Default disabled state is false
  icon: null,
  href: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default IconButton;
