import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../components/atoms/TextField';
import Label from '../../components/atoms/Label';
import { IMailTemplate, IParameterMail } from '../../constants/types';
import MailService from '../../services/MailService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { paramStateNotification, regexRouter, showToastMessage } from '../../utils/common';
import { RouteKey, rc } from '../../constants/router';
import { Loader } from '../../components/atoms/Loader';
import Button from '../../components/atoms/Button';
import { ToastContainer } from 'react-toastify';
import { BasicTable } from '../../components/templates/BasicTable';
import { Input, Space, TableProps } from 'antd';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../components/atoms/IconButton';
import {
  contentRequired,
  txtContentMailTemplatelength,
  txtSubjectMailTemplatelength,
  updateMailTempFalse,
  updateMailTempSuccess,
} from '../../constants/message';
import { MenuItem } from '../../components/atoms/MenuItem';

const MailTemplate = () => {
  const params = new URLSearchParams(window.location.search);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IMailTemplate>({});
  let formData = watch();
  const { mailId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mailData, setMailData] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(params.get('page') ?? 1);
  const updateMailTemplate = async() => {
    setIsLoading(true);
    const response = await MailService.updateMailTemplate(formData, Number(mailId));
    if(response) {
      if (response.status === 200) {
        navigate(regexRouter(rc(RouteKey.MailTemplate).path), paramStateNotification('success', updateMailTempSuccess, ''))
      } else {
        await showToastMessage('error', updateMailTempFalse, '');
      }
    } else {
      await showToastMessage('error', updateMailTempFalse, '');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    MailService.getMailTemplate(Number(mailId), { page: currentPage }).then(
      response => {
        if (response?.data) {
          const { subject, value } = response.data;
          const newData = formData;
          newData.subject = subject;
          newData.value = value;
          reset(newData);
          setMailData(response.data);
        } else {
          navigate(regexRouter(rc(RouteKey.MailTemplate).path));
        }
        setIsLoading(false);
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentPage]);

  const columns: TableProps<IMailTemplate>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'カテゴリー',
      dataIndex: 'category',
      key: 'category',
      render: record => <span>{record.name}</span>,
    },
    {
      title: 'テンプレートラベル',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'メールの内容',
      dataIndex: 'value',
      key: 'value',
      render: (html: any) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
      },
    },
    {
      title: '説明',
      dataIndex: 'parameters',
      key: 'parameters',
      render: parameters => (
        <>
          {parameters.map((res: any) => (
            <div key={res.key}>
              <span>
                {res.key} : {res.description}
              </span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'アクション',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <MenuItem
          href={`${regexRouter(rc(RouteKey.MailTemplate).path) + `/${id}`}`}
        >
          <IconButton icon={faPen} />
        </MenuItem>
      ),
    },
  ];

  return (
    <>
      <Loader isLoading={isLoading}>
        {mailId && Number(mailId) !== 0 ? (
          <>
            <form onSubmit={handleSubmit(updateMailTemplate)}>
              <div className="max-w-full">
                <TabLinks className="mb-4" />
                <div className="px-8 mb-24">
                  <div className="border border-color-gray-40 bg-white p-4 rounded ">
                    <Heading className="text-color-blue-800 mb-6">
                      システム設定の編集
                    </Heading>
                    <div className="mb-3">
                      <Label
                        className="mb-5"
                        label="メールの件名"
                        textTooltip="Mail Subject"
                        textNote={errors?.subject?.message}
                        onerror={errors?.subject ? true : false}
                      >
                        <Controller
                          control={control}
                          name={`subject`}
                          rules={{
                            required: '件名を入力してください',
                            maxLength: {
                              value: 255,
                              message: txtSubjectMailTemplatelength,
                            },
                          }}
                          render={({ field }) => {
                            const { onChange, value } = field;
                            return (
                              <TextField
                                classNameChild="w-full block"
                                value={value}
                                onChange={onChange}
                                placeholder="タイトル"
                                onerror={errors?.subject ? true : false}
                              />
                            );
                          }}
                        />
                      </Label>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="mb-5"
                        label="メールの内容"
                        textTooltip="Mail Content"
                        textNote={errors?.value?.message}
                        onerror={errors?.value ? true : false}
                      >
                        <Controller
                          control={control}
                          name={`value`}
                          rules={{
                            required: contentRequired,
                            maxLength: {
                              value: 2000,
                              message: txtContentMailTemplatelength,
                            },
                          }}
                          render={({ field }) => {
                            const { onChange, value } = field;
                            return (
                              <TextField
                                type="textarea"
                                classNameChild="w-full block min-h-96"
                                value={value}
                                onChange={onChange}
                                placeholder="タイトル"
                                onerror={errors?.value ? true : false}
                              />
                            );
                          }}
                        />
                      </Label>
                      <Label
                        className="mb-5"
                        label="説明"
                        textTooltip="Description"
                      >
                        {mailData?.parameters?.map(
                          (it: IParameterMail, idx: number) => {
                            return (
                              <Space.Compact
                                className="w-full h-[48px] mb-3"
                                key={idx}
                              >
                                <Input
                                  className="w-1/5 !bg-white !text-black"
                                  defaultValue={it.key}
                                  disabled
                                />

                                <Input
                                  className="w-4/5 !bg-white !text-black"
                                  value={it.description}
                                  disabled
                                />
                              </Space.Compact>
                            );
                          },
                        )}
                      </Label>
                    </div>
                    <div className="mb-3 text-center">
                      <Button
                        className="inline-block font-semibold border text-center min-w-[294px] bg-color-blue-500 text-color-gray-01 border-color-blue-500 hover:bg-color-blue-700 hover:border-color-blue-700 hover:text-color-gray-01 focus:bg-color-blue-500 focus:border-color-blue-500 focus:text-color-gray-01 text-base py-[11px] px-4 rounded-md"
                        type="submit"
                        variant="primary"
                        size="medium"
                      >
                        保存
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer
                containerId="success"
                position="top-right"
                autoClose={1000}
                closeButton={false}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <ToastContainer
                containerId="error"
                position="top-right"
                autoClose={1000}
                closeButton={false}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </form>
          </>
        ) : (
          <>
            <div className="max-w-full">
              <TabLinks className="mb-4" />
              <div className="px-8 mb-24">
                <div className="border border-color-gray-40 bg-white p-4 rounded ">
                  <Heading className="text-color-blue-800 mb-6">
                    システム設定一覧
                  </Heading>
                  <div>
                    <BasicTable
                      columns={columns}
                      data={mailData?.data}
                      pageSize={mailData?.page_size}
                      totalData={mailData?.total_records}
                      currentPage={mailData?.current_page}
                      setCurrentPage={setCurrentPage}
                      totalPage={mailData?.total_page}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Loader>
    </>
  );
};

export default MailTemplate;
