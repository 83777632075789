import mainAxiosInstance from './api';

export interface Course {
  id: number;
  title: string;
  status: number;
  status_display: string;
}

export interface SupportData {
  total_records: number;
  total_page: number;
  current_page: number;
  page_size: string;
  data: {
    id: number;
    course: Course;
    is_applied: boolean;
    apply_status: string | null;
    start_at: string;
    end_at: string;
    demo_type: number;
    demo_type_display: string;
    apply_deadline: string;
    fb_deadline: string;
  }[];
}

interface SearchData {
  page: number;
  title?: string;
  demo_type?: number;
  demo_start?: string;
  demo_end?: string;
  is_applied?: boolean;
  status?: string;
}

export interface HistorySupportRecord {
  id: number;
  course: Course;
  is_applied: boolean;
  apply_status: number;
  apply_status_display: string;
  start_at: string;
  end_at: string;
  demo_type: number;
  demo_type_display: string;
  apply_deadline: string;
  fb_deadline: string;
  feedback_url: string;
  is_submitted: boolean;
  status:number;
}

export interface HistorySupport {
  total_records: number;
  total_page: number;
  current_page: number;
  page_size: string;
  data: HistorySupportRecord[];
}

interface ApplySupport {
  id: number;
  demo: number;
  user: {
    id: number;
    username: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    role: number;
    role_display: string;
  };
  status: number;
  status_display: string;
  apply_at: string;
}


class SupportSideService {
  static async getRecruitingSupport(params?: SearchData): Promise<SupportData | undefined> {
    try {
      const response = await mainAxiosInstance.get<SupportData>(
        '/support/recruiting/',
        { params }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }

  static async getHistorySupport(page: number): Promise<HistorySupport | undefined> {
    try {
      const response = await mainAxiosInstance.get<HistorySupport>(
        '/support/history/',
        { params: { page: page } }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }

  static async applySupport(id: number): Promise<ApplySupport | undefined> {
    try {
      const response = await mainAxiosInstance.post<ApplySupport>(
        '/support/' + id +'/apply/',
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }
}

export default SupportSideService;