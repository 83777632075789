import mainAxiosInstance from './api';
interface ICreateCalendar {
  calendar_id: string;
  code: string;
  access_token: string;
  refresh_token: string;
}

export const getCalendarService = async () => {
  try {
    const response = await mainAxiosInstance.get('settings/calendar/');
    return response.data;
  } catch (err) {
    return err;
  }
};

export const createCalendarService = async (params: ICreateCalendar) => {
  try {
    const response = await mainAxiosInstance.post('settings/calendar/', {
      ...params,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateCalendarService = async (id: string, params: any) => {
  try {
    const response = await mainAxiosInstance.put(`settings/calendar/${id}/`, {
      ...params,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

export const deleteCalendarService = async (id: number) => {
  try {
    const response = await mainAxiosInstance.delete(`settings/calendar/${id}/`);
    return response.status;
  } catch (err) {
    return err;
  }
};

export const getTimeBusySystem = async (dates: string[]) => {
  try {
    const response = await mainAxiosInstance.post('demo-blocked-time/', {
      dates,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};
