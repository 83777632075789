
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip'

const tooltipStyles = `
  .rc-tooltip {
    z-index: 1070;
    display: block;
    visibility: visible;
    max-width: 250px;
  }
  .rc-tooltip-hidden {
    display: none;
  }
  .rc-tooltip-inner {
    text-align: left;
    text-decoration: none;
  }
  .rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-tooltip-arrow-inner {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-tooltip-placement-top {
    .rc-tooltip-arrow {
      transform: translate(-50%,5px);
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #000;
      left: 50%;
    }
    .rc-tooltip-arrow-inner {
      bottom: 1px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #fff;
    }
  }
  .rc-tooltip-placement-topLeft {
    .rc-tooltip-arrow {
      transform: translate(-50%,5px);
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #000;
      left: 15%;
    }
    .rc-tooltip-arrow-inner {
      bottom: 1px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #fff;
    }
  }
  .rc-tooltip-placement-topRight {
    .rc-tooltip-arrow {
      transform: translate(-50%,5px);
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #000;
      right: 15%;
    }
    .rc-tooltip-arrow-inner {
      bottom: 1px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: #fff;
    }
  }
  .rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      left: -5px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #000;
      transform: translate(calc(-100% + 1px));
      top: 50%;
    }
    .rc-tooltip-arrow-inner {
      left: 1px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #fff;
    }
  }
  .rc-tooltip-placement-rightTop {
    .rc-tooltip-arrow {
      left: -5px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #000;
      transform: translate(calc(-100% + 1px));
      top: 15%;
      margin-top: 0;
    }
    .rc-tooltip-arrow-inner {
      left: 1px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #fff;
    }
  }
  .rc-tooltip-placement-rightBottom {
    .rc-tooltip-arrow {
      left: -5px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #000;
      transform: translate(calc(-100% + 1px));
      bottom: 15%;
    }
    .rc-tooltip-arrow-inner {
      left: 1px;
      margin-top: -6px;
      border-width: 6px 6px 6px 0;
      border-right-color: #fff;
    }
  }
  .rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      right: -5px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #000;
      transform: translate(calc(100% - 1px));
      top: 50%;
    }
    .rc-tooltip-arrow-inner {
      right: 1px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #fff;
    }
  }
  .rc-tooltip-placement-leftTop {
    .rc-tooltip-arrow {
      right: -5px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #000;
      transform: translate(calc(100% - 1px));
      top: 15%;
      margin-top: 0;
    }
    .rc-tooltip-arrow-inner {
      right: 1px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #fff;
    }
  }
  .rc-tooltip-placement-leftBottom {
    .rc-tooltip-arrow {
      right: -5px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #000;
      transform: translate(calc(100% - 1px));
      bottom: 15%;
    }
    .rc-tooltip-arrow-inner {
      right: 1px;
      margin-top: -6px;
      border-width: 6px 0 6px 6px;
      border-left-color: #fff;
    }
  }
  .rc-tooltip-placement-bottom {
    .rc-tooltip-arrow {
      transform: translate(-50%,-5px);
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #000;
      left: 50%;
    }
    .rc-tooltip-arrow-inner {
      top: 1px;
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #fff;
    }
  }
  .rc-tooltip-placement-bottomLeft {
    .rc-tooltip-arrow {
      transform: translate(-50%,-5px);
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #000;
      left: 15%;
    }
    .rc-tooltip-arrow-inner {
      top: 1px;
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #fff;
    }
  }
  .rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      transform: translate(-50%,-5px);
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #000;
      right: 15%;
    }
    .rc-tooltip-arrow-inner {
      top: 1px;
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: #fff;
    }
  }

`;

const BasicTooltip = ({
  className,
  text,
  children,
  trigger,
  showArrow,
  placement
}: any) => {
  let classNames = 'bg-black text-white absolute z-50 px-4 py-1.5 text-xs leading-[14px] rounded  ' + className;
  
  return (
    <>
      <style>{tooltipStyles}</style>
      <Tooltip overlayClassName={classNames} placement={placement} trigger={trigger} overlay={text} showArrow={showArrow}>
        {children} 
      </Tooltip>
    </>
  );
}
BasicTooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  trigger: PropTypes.oneOf(['hover','click','focus']),
  showArrow: PropTypes.bool,
  placement: PropTypes.oneOf(['top','left','right','bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom'])
};

BasicTooltip.defaultProps = {
  text: '',
  children: '',
  className: '',
  trigger: 'hover',
  showArrow: true,
  placement: 'top'
};
export default BasicTooltip;