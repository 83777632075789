import mainAxiosInstance from './api';

export const getInstructionService = async () => {
  try {
    const response = await mainAxiosInstance.get('settings/instruction/');
    return response;
  } catch (error) {
    return error;
  }
};

export const updateInstructionService = async (content: string) => {
  try {
    const response = await mainAxiosInstance.put(
      'settings/instruction/',
      content,
    );
    return response;
  } catch (error) {
    return error;
  }
};
