import { useEffect, useState } from 'react';
import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Button from '../../components/atoms/Button';
import Label from '../../components/atoms/Label';

import {
  faCheckDouble,
  faChevronDown,
  faEye,
  faEyeSlash,
  faLink,
  faLockOpen,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import UserInfoService from '../../services/MyPageService';
import { Controller, useForm } from 'react-hook-form';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import SelectField from '../../components/atoms/Select';
import TextField from '../../components/atoms/TextField';
import { BasicModal } from '../../components/molecules/BasicModal';
import { genderData } from '../../constants';
import { Loader } from '../../components/atoms/Loader';
import {
  ICalendar,
  IChangepassword,
  UserInfoType,
} from '../../constants/types';
import {
  passChangeSuccess,
  personalInfoUpdated,
  txtChangePasswordNotSuccess,
  txtGenderRequired,
  txtValidateConfirmPasswordRequired,
  txtValidateOldPasswordRequired,
  txtValidatePassworLength,
} from '../../constants/message';
import {
  createCalendarService,
  deleteCalendarService,
  getCalendarService,
} from '../../services/Calendar';
import { getCode, getProfile, removeCode } from '../../helper/localStorage';
import { isAdmin, showToastMessage } from '../../utils/common';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const MyPage = () => {
  const [disabled, setDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invisibleOldPassWord, setInvisibleOldPassword] = useState(true);
  const [invisibleNewPassword, setInvisibleNewPassword] = useState(true);
  const [invisibleConfirmPassword, setInvisibleConfirmPassword] =
    useState(true);
  const [calendars, setCalendars] = useState<ICalendar[]>([]);

  const location = useLocation();

  const [isLoader, setIsLoader] = useState(false);
  const idUser = JSON.parse(getProfile() || '').id;
  const calendar_detail = calendars?.find(
    calendar => calendar.user.id === idUser,
  );

  const {
    reset,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<UserInfoType>();

  const {
    reset: resetChangePassword,
    handleSubmit: handleSubmitChangePassword,
    watch: watchChangePassword,
    control: controlChangePassword,
    formState: { errors: errorsChangePassword },
  } = useForm<IChangepassword>();

  const showModal = () => {
    setIsModalOpen(true);
  };
  let formData = watch();
  let formDataChangePassword = watchChangePassword();

  const handleChangePassword = () => {
    setIsLoader(true);
    UserInfoService.changePassword(formDataChangePassword).then(r => {
      if (r === 200) {
        showToastMessage('success', '', passChangeSuccess);
      } else {
        showToastMessage('error', '', txtChangePasswordNotSuccess);
      }
      setIsLoader(false);
      handleCancel();
    });
  };

  const handleGetProfile = () => {
    setIsLoader(true);
    UserInfoService.getUserInfo().then(r => {
      const {
        gender,
        username,
        remark,
        email,
        first_name,
        age,
        education,
        address,
        specialty,
        affiliation,
        position,
        research_content,
      } = r;
      const newData = {
        ...formData,
        gender,
        username,
        remark,
        email,
        first_name,
        age,
        education,
        address,
        specialty,
        affiliation,
        position,
        research_content,
      };
      reset(newData, {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      });
      setIsLoader(false);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInvisibleOldPassword(true);
    setInvisibleNewPassword(true);
    setInvisibleConfirmPassword(true);
    let newDataChangePassword = formDataChangePassword;
    newDataChangePassword.old_password = '';
    newDataChangePassword.new_password = '';
    newDataChangePassword.confirm_password = '';
    resetChangePassword(newDataChangePassword);
  };
  const handleEdit = () => {
    setDisabled(!disabled);
  };
  const handleCloseEdit = () => {
    setDisabled(true);
    reset();
  };
  const handleSave = async () => {
    setIsLoader(true);
    const response = await UserInfoService.updateUserInfo(formData);
    if (response === 200) {
      await showToastMessage('success', '', personalInfoUpdated);
    } else {
      await showToastMessage('error', '', response?.message);
    }
    setIsLoader(false);
    setDisabled(!disabled);
  };

  useEffect(() => {
    handleGetProfile();
    getCalendarService().then(result => setCalendars(result));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function oauthSignIn() {
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Parameters to pass to OAuth 2.0 endpoint.
    var params: any = {
      client_id: process.env.REACT_APP_GG_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI, // Must match your registered redirect URI
      response_type: 'code', // Use 'code' to request an authorization code
      access_type: 'offline',
      scope:
        'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email',
      include_granted_scopes: 'false',
      state: 'state_parameter_passthrough_value',
      prompt: 'consent',
    };

    // Build the URL for the authorization request
    var urlParams = new URLSearchParams(params).toString();
    var authUrl = `${oauth2Endpoint}?${urlParams}`;

    // Redirect the user to the authorization URL
    window.location.href = authUrl;
  }

  async function getEmailUser(accessToken: string) {
    try {
      const response = await axios.get(
        'https://www.googleapis.com/oauth2/v1/userinfo',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data.email;
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    const checkUserData = async () => {
      if (getCode()) {
        setIsLoader(true);
        axios
          .post(
            `https://oauth2.googleapis.com/token`,
            {
              client_id: process.env.REACT_APP_GG_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SERCET,
              code: getCode(),
              grant_type: 'authorization_code',
              redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            },
            {
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
            },
          )
          .then(result => {
            getEmailUser(result?.data.access_token).then(email => {
              createCalendarService({
                calendar_id: email,
                code: getCode() || '',
                access_token: result?.data?.access_token,
                refresh_token: result?.data?.refresh_token,
              }).finally(() =>
                getCalendarService().then(result => {
                  setCalendars(result);
                  setIsLoader(false);
                }),
              );
            });
          });
      }
    };
    if (location?.search) {
      checkUserData();

      window.addEventListener('storage', checkUserData);

      return () => {
        window.removeEventListener('storage', checkUserData);
      };
    }
  }, [location]);

  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, reset]);

  const handleClickGGCalendar = () => {
    if (calendar_detail) {
      deleteCalendarService(calendar_detail?.id).then(res => {
        setIsLoader(true);
        removeCode();
        if (res === 204) {
          getCalendarService().then(result => {
            setCalendars(result);
            setIsLoader(false);
          });
        }
      });
    }
  };

  return (
    <>
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="mx-8">
          <Loader isLoading={isLoader}>
            <div className="border border-color-gray-40 bg-white p-4 rounded">
              <div className="flex items-center mb-4">
                <Heading className="text-color-blue-700">個人情報</Heading>

                <div className="flex gap-2 ms-auto">
                  {isAdmin() && (
                    <Button
                      type="button"
                      variant="outline-primary"
                      size="small"
                      onClick={() => {
                        if (!calendar_detail) {
                          oauthSignIn();
                        } else handleClickGGCalendar();
                      }}
                      className={
                        calendar_detail
                          ? 'text-red-500 !border-color-red-500 hover:bg-red-500 hover:text-white'
                          : ''
                      }
                      iconPrefix={faLink}
                    >
                      {calendar_detail
                        ? 'Google Calendar連携削除'
                        : 'Google Calendar連携'}
                    </Button>
                  )}
                  {disabled ? (
                    <>
                      <Button
                        type="button"
                        variant="outline-primary"
                        iconSuffix={faLockOpen}
                        size="small"
                        onClick={showModal}
                      >
                        PW変更
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        iconPrefix={faPen}
                        size="small"
                        onClick={handleEdit}
                      >
                        個人情報
                      </Button>
                    </>
                  ) : (
                    <form
                      onSubmit={handleSubmit(handleSave)}
                      id="my_page"
                      className="flex gap-2"
                    >
                      <Button
                        variant="outline-tertiary"
                        size="small"
                        onClick={handleCloseEdit}
                        type="button"
                      >
                        キャンセル
                      </Button>

                      <Button
                        type="submit"
                        variant="primary"
                        iconPrefix={faCheckDouble}
                        size="small"
                      >
                        Save
                      </Button>
                    </form>
                  )}
                </div>
              </div>

              <div className="border border-color-gray-50 bg-white rounded py-5 px-6">
                <div className="grid grid-cols-3 gap-x-4">
                  <Label label="メールアドレス" className="mb-5" required>
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: 'メールアドレスを入力してください',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: '無効なメールアドレス',
                        },
                        maxLength: {
                          value: 255,
                          message: '255文字以内で入力してください',
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            disabled={disabled}
                            type="text"
                            placeholder="email@example.com"
                            classNameChild="w-full"
                            onChange={onChange}
                            value={value}
                            onerror={!!errors.email?.message}
                            errorMessage={errors.email?.message}
                          />
                        );
                      }}
                    />
                  </Label>

                  <Label label="氏名" className="mb-5" required>
                    <Controller
                      control={control}
                      name="first_name"
                      rules={{
                        required: '氏名を入力してください',
                        maxLength: {
                          value: 25,
                          message: '25文字以内で入力してください',
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            classNameChild="w-full block"
                            placeholder=""
                            type="text"
                            disabled={disabled}
                            value={value}
                            onChange={onChange}
                            oneerror={!!errors.first_name?.message}
                            errorMessage={errors.first_name?.message}
                          />
                        );
                      }}
                    />
                  </Label>

                  <Label label="ユーザーID" className="mb-5">
                    <Controller
                      control={control}
                      name="username"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            classNameChild="w-full block"
                            placeholder=""
                            disabled={true}
                            type="text"
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                  </Label>
                </div>
                <div className="grid grid-cols-4 gap-x-4">
                  <Label label="性別" className="mb-5" required>
                    <Controller
                      control={control}
                      name="gender"
                      rules={{
                        required: txtGenderRequired,
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <SelectField
                              classNameChild="w-full appearance-none"
                              onChange={onChange}
                              value={value?.toString()}
                              options={genderData}
                              disabled={disabled}
                              iconRight={faChevronDown}
                              onerror={!!errors.gender?.message}
                            />
                            <span className="text-red-600">
                              {!!errors.gender?.message && txtGenderRequired}
                            </span>
                          </>
                        );
                      }}
                    />
                  </Label>

                  <Label label="職位" className="mb-5">
                    <Controller
                      control={control}
                      name="position"
                      rules={{
                        maxLength: {
                          value: 255,
                          message: '255文字以内で入力してください',
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            classNameChild="w-full block"
                            placeholder=""
                            disabled={disabled}
                            type="text"
                            onChange={onChange}
                            value={value ?? ""}
                            onerror={!!errors.position?.message}
                            errorMessage={errors.position?.message}
                          />
                        );
                      }}
                    />
                  </Label>
                  <Label label="所属" className="mb-5">
                    <Controller
                      control={control}
                      name="affiliation"
                      rules={{
                        maxLength: {
                          value: 255,
                          message: '255文字以内で入力してください',
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            classNameChild="w-full block"
                            placeholder=""
                            disabled={disabled}
                            type="text"
                            onChange={onChange}
                            value={value ?? ""}
                            onerror={!!errors.affiliation?.message}
                            errorMessage={errors.affiliation?.message}
                          />
                        );
                      }}
                    />
                  </Label>
                  <Label label="専門" className="mb-5">
                    <Controller
                      control={control}
                      name="specialty"
                      rules={{
                        maxLength: {
                          value: 255,
                          message: '255文字以内で入力してください',
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextFieldForm
                            classNameChild="w-full block"
                            placeholder=""
                            disabled={disabled}
                            type="text"
                            onChange={onChange}
                            value={value ?? ""}
                            onerror={!!errors.specialty?.message}
                            errorMessage={errors.specialty?.message}
                          />
                        );
                      }}
                    />
                  </Label>
                </div>
                <Label label="教育歴" className="mb-5">
                  <Controller
                    control={control}
                    name="education"
                    rules={{
                      maxLength: {
                        value: 255,
                        message: '255文字以内で入力してください',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextFieldForm
                          type="textarea"
                          classNameChild="w-full block"
                          placeholder=""
                          disabled={disabled}
                          value={value ?? ""}
                          onChange={onChange}
                          onerror={!!errors.education?.message}
                          errorMessage={errors.education?.message}
                        />
                      );
                    }}
                  />
                </Label>
                <Label label="研究内容" className="mb-5">
                  <Controller
                    control={control}
                    name="research_content"
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: '1000文字以内で入力してください',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextFieldForm
                          type="textarea"
                          classNameChild="w-full block"
                          placeholder="研究内容を入力してください"
                          disabled={disabled}
                          value={value ?? ""}
                          onChange={onChange}
                          onerror={!!errors.research_content?.message}
                          errorMessage={errors.research_content?.message}
                        />
                      );
                    }}
                  />
                </Label>
                <Label label="備考">
                  <Controller
                    control={control}
                    name="remark"
                    rules={{
                      maxLength: {
                        value: 1000,
                        message: '1000文字以内で入力してください',
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextFieldForm
                          type="textarea"
                          classNameChild="w-full block"
                          placeholder=""
                          disabled={disabled}
                          onChange={onChange}
                          value={value ?? ""}
                          onerror={!!errors.remark?.message}
                          errorMessage={errors.remark?.message}
                        />
                      );
                    }}
                  />
                </Label>
              </div>
            </div>
          </Loader>
        </div>
      </div>

      <BasicModal
        title="パスワード変更"
        open={isModalOpen}
        onOk={handleChangePassword}
        onCancel={handleCancel}
        centered
        width={445}
        footer={[
          <form onSubmit={handleSubmitChangePassword(handleChangePassword)}>
            <div className="grid grid-cols-2 gap-x-5">
              <Button
                type="button"
                variant="outline-tertiary"
                size="medium"
                className="w-full"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="medium"
                className="w-full"
              >
                変更する
              </Button>
            </div>
          </form>,
        ]}
      >
        <div>
          <Controller
            control={controlChangePassword}
            name="old_password"
            rules={{
              required: txtValidateOldPasswordRequired,
              minLength: {
                value: 6,
                message: txtValidatePassworLength,
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextFieldForm
                  type={invisibleOldPassWord ? 'password' : 'text'}
                  iconRight={invisibleOldPassWord ? faEyeSlash : faEye}
                  onClickIconRight={() => {
                    setInvisibleOldPassword(!invisibleOldPassWord);
                  }}
                  canClickIconRight={true}
                  className="mb-3"
                  classNameChild="w-full"
                  placeholder="現在のパスワード"
                  onChange={onChange}
                  value={value}
                  onerror={!!errorsChangePassword.old_password?.message}
                  errorMessage={errorsChangePassword.old_password?.message}
                />
              );
            }}
          />

          <Controller
            control={controlChangePassword}
            name="new_password"
            rules={{
              required: '新しいパスワードを入力してください',
              minLength: {
                value: 6,
                message: 'パスワードは6文字以上でなければなりません',
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextFieldForm
                  classNameChild="w-full"
                  type={invisibleNewPassword ? 'password' : 'text'}
                  placeholder="新しいパスワード"
                  iconRight={invisibleNewPassword ? faEyeSlash : faEye}
                  onClickIconRight={() => {
                    setInvisibleNewPassword(!invisibleNewPassword);
                  }}
                  onChange={onChange}
                  value={value}
                  onerror={!!errorsChangePassword.new_password?.message}
                  errorMessage={errorsChangePassword.new_password?.message}
                  canClickIconRight={true}
                />
              );
            }}
          />

          <Controller
            control={controlChangePassword}
            name="confirm_password"
            rules={{
              required: txtValidateConfirmPasswordRequired,
              minLength: {
                value: 6,
                message: txtValidatePassworLength,
              },
              validate: value =>
                value === formDataChangePassword.new_password ||
                'パスワードが一致しません',
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextFieldForm
                  type={invisibleConfirmPassword ? 'password' : 'text'}
                  iconRight={invisibleConfirmPassword ? faEyeSlash : faEye}
                  onClickIconRight={() => {
                    setInvisibleConfirmPassword(!invisibleConfirmPassword);
                  }}
                  canClickIconRight={true}
                  className="mt-3"
                  classNameChild="w-full"
                  placeholder="新しいパスワード確認"
                  onerror={!!errorsChangePassword.confirm_password?.message}
                  errorMessage={errorsChangePassword.confirm_password?.message}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </div>
      </BasicModal>
    </>
  );
};
export default MyPage;
