// FeedbackService.ts
import mainAxiosInstance from './api';
import {
  FeedbackInfo,
  CriteriaResponse,
  AspectsResponse,
  FeedbackFormState,
  IFormFeedback,
  FeedbackInfoByAdmin,
} from '../constants/types';

class FeedbackService {
  static async getFeedbackInfo(
    courseId: string,
    demoId: string,
  ): Promise<FeedbackInfo | null> {
    try {
      const response = await mainAxiosInstance.get(
        `/courses/${courseId}/demos/${demoId}/feedback/`,
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
    return null;
  }

  static async getFeedbackInfoByAdmin(
    courseId: string,
    demoId: string,
  ): Promise<FeedbackInfoByAdmin | null> {
    try {
      const response = await mainAxiosInstance.get(
        `/courses/${courseId}/demos/${demoId}/admin-feedback/`,
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
    return null;
  }

  static async getCriteria(): Promise<CriteriaResponse | null> {
    try {
      const response = await mainAxiosInstance.get('/feedback/criteria/');
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
    return null;
  }

  static async getAspects(): Promise<AspectsResponse | null> {
    try {
      const response = await mainAxiosInstance.get('/feedback/aspects/');
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
    return null;
  }

  static async saveFeedback(
    courseId: string,
    demoId: string,
    data: IFormFeedback & { send_feedback: boolean },
  ): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(
        `/courses/${courseId}/demos/${demoId}/feedback/`,
        data,
      );
      return response;
    } catch (error) {
    }
  }
  static async saveFeedbackByAdmin(
    courseId: string,
    demoId: string,
    data: IFormFeedback & { send_feedback: boolean },
  ): Promise<any> {
    try {
      const response = await mainAxiosInstance.post(
        `/courses/${courseId}/demos/${demoId}/admin-feedback/`,
        data,
      );
      return response;
    } catch (error) {
    }
  }
}

export default FeedbackService;
