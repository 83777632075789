import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

var PropTypes = require('prop-types');

const Button = ({
  type,
  variant,
  size,
  width,
  className,
  iconPrefix,
  iconSuffix,
  href,
  onClick,
  disabled,
  children,
  onMouseEnter,
  onMouseLeave,
  name,
  ...buttonProps
}: any) => {
  // Determine the classNames based on the variant prop
  let classNames = 'inline-block font-semibold border text-center ' + className;

  let primaryContained =
    ' bg-color-blue-500 text-color-gray-01 border-color-blue-500 hover:bg-color-blue-700 hover:border-color-blue-700 hover:text-color-gray-01 focus:bg-color-blue-500 focus:border-color-blue-500 focus:text-color-gray-01';
  let primaryContainedDisabled =
    ' bg-color-blue-200 text-color-gray-01 border-color-blue-200 cursor-not-allowed';

  let successContained =
    ' bg-color-green-600 text-white border-color-green-600 hover:bg-color-green-700 hover:border-color-green-700 hover:text-white focus:bg-color-green-700 focus:border-color-green-700 focus:text-white';
  let successContainedDisabled =
    ' bg-color-green-100 text-color-gray-01 border-color-green-100 cursor-not-allowed';

  let tertiaryContained =
    ' bg-color-blue-100 text-color-blue-700 border-color-blue-100 hover:bg-color-blue-200 hover:border-color-blue-200 hover:text-color-blue-700 focus:bg-color-blue-100 focus:border-color-blue-200 focus:text-color-blue-700';
  let tertiaryContainedDisabled =
    ' bg-color-blue-100 border-color-blue-100 text-color-blue-200 cursor-not-allowed';

  let primaryOutlined =
    ' bg-white text-color-blue-500 border-color-blue-500 hover:bg-color-blue-100 hover:border-color-blue-500 hover:text-color-blue-500 focus:bg-white focus:border-color-blue-500 focus:text-color-blue-500';
  let primaryOutlinedDisabled =
    ' bg-white text-color-blue-200 border-color-blue-200 cursor-not-allowed';

  let tertiaryOutlined =
    ' bg-color-gray-01 text-color-gray-07 border-color-gray-03 hover:bg-color-gray-02 hover:border-color-gray-03 hover:text-color-gray-07 focus:bg-color-gray-07 focus:border-color-gray-07 focus:text-white';
  let tertiaryOutlinedDisabled =
    ' bg-color-gray-01 text-color-gray-03 border-color-gray-01 cursor-not-allowed';

  let tertiaryText =
    ' bg-transparent text-color-gray-07 border-transparent hover:bg-color-gray-02 hover:border-color-gray-02 hover:text-color-gray-07 focus:bg-color-gray-07 focus:border-color-gray-07 focus:text-white';
  let tertiaryTextDisabled =
    ' bg-transparent text-color-gray-03 border-transparent cursor-not-allowed';

  let linkText = 'bg-transparent text-color-blue-01 border-transparent hover:bg-color-gray-01 hover:border-color-gray-01 hover:text-color-blue-01 focus:bg-transparent focus:border-transparent focus:text-color-blue-01 disabled:bg-color-gray-02 disabled:border-color-gray-02 disabled:text-color-gray-03';

  let linkTextDisabled =
    ' bg-transparent text-color-blue-03 border-transparent cursor-not-allowed';

  let dangerContained =
    ' bg-color-red-500 text-white border-color-red-500 hover:bg-color-red-600 hover:border-color-red-600 hover:text-white focus:bg-color-red-500 focus:border-color-red-500 focus:text-white';
  let dangerContainedDisabled =
    ' bg-color-red-03 text-white border-color-red-03 cursor-not-allowed';

  let smallSize = ' text-sm py-[7px] px-4 rounded-md';
  let mediumSize = ' text-base py-[11px] px-4 rounded-md';
  let largeSize = ' text-base py-[15px] px-6 rounded-xl';

  // width button
  if (width === 'full') {
    classNames += ' w-full';
  } else if (width !== '') {
    classNames += ' w-[' + width + ']';
  }

  // color button
  if (disabled) {
    if (variant === 'primary') {
      classNames += primaryContainedDisabled;
    } else if (variant === 'tertiary') {
      classNames += tertiaryContainedDisabled;
    } else if (variant === 'success') {
      classNames += successContainedDisabled;
    } else if (variant === 'outline-primary' || variant === 'text-primary') {
      classNames += primaryOutlinedDisabled;
    } else if (variant === 'outline-tertiary') {
      classNames += tertiaryOutlinedDisabled;
    } else if (variant === 'text-tertiary') {
      classNames += tertiaryTextDisabled;
    } else if (variant === 'text-link') {
      classNames += linkTextDisabled;
    } else if (variant === 'tertiary') {
      classNames += tertiaryContainedDisabled;
    } else if (variant === 'success') {
      classNames += successContainedDisabled;
    } else if (variant === 'outline-primary' || variant === 'text-primary') {
      classNames += primaryOutlinedDisabled;
    } else if (variant === 'outline-tertiary') {
      classNames += tertiaryOutlinedDisabled;
    } else if (variant === 'text-tertiary') {
      classNames += tertiaryTextDisabled;
    } else if (variant === 'text-link') {
      classNames += linkTextDisabled;
    } else if (variant === 'danger') {
      classNames += dangerContainedDisabled;
    }
  } else {
    if (variant === 'primary') {
      classNames += primaryContained;
    } else if (variant === 'tertiary') {
      classNames += tertiaryContained;
    } else if (variant === 'success') {
      classNames += successContained;
    } else if (variant === 'outline-primary' || variant === 'text-primary') {
      classNames += primaryOutlined;
    } else if (variant === 'outline-tertiary') {
      classNames += tertiaryOutlined;
    } else if (variant === 'text-tertiary') {
      classNames += tertiaryText;
    } else if (variant === 'text-link') {
      classNames += linkText;
    } else if (variant === 'danger') {
      classNames += dangerContained;
    }
  }

  // size button
  if (size === 'small') {
    classNames += smallSize;
  } else if (size === 'medium') {
    classNames += mediumSize;
  } else {
    classNames += largeSize;
  }

  return (
    <>
      {type === 'link' ? (
        <Link
          to={disabled ? undefined : href}
          className={classNames}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...buttonProps}
          disable={disabled}
          onClick={disabled ? (e) => e.preventDefault() : onClick}
        >
          {iconPrefix && <FontAwesomeIcon icon={iconPrefix} className="mr-2" />}
          {children}
          {iconSuffix && <FontAwesomeIcon icon={iconSuffix} className="ml-2" />}
        </Link>
      ) : (
        <button
          type={type}
          className={classNames}
          onClick={onClick}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          name={name}
          {...buttonProps}
        >
          {iconPrefix && <FontAwesomeIcon icon={iconPrefix} className="mr-2" />}
          {children}
          {iconSuffix && <FontAwesomeIcon icon={iconSuffix} className="ml-2" />}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'link', 'submit']),
  variant: PropTypes.oneOf([
    'primary',
    'tertiary',
    'outline-primary',
    'success',
    'outline-tertiary',
    'text-primary',
    'text-tertiary',
    'text-link',
    'danger',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  width: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  mouseenter: PropTypes.func,
  mouseleave: PropTypes.func,
  focus: PropTypes.func,
  disabled: PropTypes.bool, // New prop for disabled state
  children: PropTypes.node.isRequired,
  iconPrefix: PropTypes.object, // Icon component for prefix
  iconSuffix: PropTypes.object, // Icon component for suffix
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  name: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'medium',
  width: '',
  className: '',
  disabled: false, // Default disabled state is false
  iconPrefix: null,
  iconSuffix: null,
  href: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  name: '',
};

export default Button;
