const TabContent1 = ({ courseInfo }: any) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-simple text-color-blue-900">
          <tbody>
            <tr>
              <td
                width="23.5%"
                className="border-r border-r-color-blue-100 p-4 h-24 align-top font-semibold"
              >
                目的
              </td>
              <td className="p-4 h-24 align-top last:border-r-0">
                <ul className="list-disc list-outside">
                  {courseInfo && courseInfo.objective}
                </ul>
              </td>
            </tr>

            <tr>
              <td className="border-r border-r-color-blue-100 p-4 h-24 align-top font-semibold">
                クラスの達成目標
              </td>
              <td className="p-4 h-24 align-top last:border-r-0">
                {courseInfo && courseInfo.goal}
              </td>
            </tr>

            <tr>
              <td className="border-r border-r-color-blue-100 p-4 h-24 align-top font-semibold">
                目標達成の
                <br />
                測定方法
              </td>
              <td className="p-4 h-24 align-top last:border-r-0">
                {courseInfo && courseInfo.method}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TabContent1;
