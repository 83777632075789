// export const TextField = () => <></>;
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectField = ({
  placeholder,
  disabled,
  value,
  iconLeft,
  iconRight,
  onChange,
  onerror,
  className,
  classNameChild,
  children,
  size,
  options,
  colorOption,
  ...textFieldProps
}: any) => {
  let formGroup = '';
  let formInputName = 'relative';
  let inputClassname =
    'text-color-gray-600 bg-white rounded-md border border-solid border-color-gray-03 placeholder:text-color-gray-50 hover:border-color-blue-400 hover:outline-0 focus:border-color-blue-700 focus:shadow-[0 0 2px rgba(202,223,255,0.7)] disabled:bg-color-gray-02 disabled:border-color-gray-03 focus-visible:border-color-blue-700 focus-visible:outline-0';

  if (iconLeft && iconRight) {
    inputClassname += ' px-8';
  } else if (iconLeft) {
    inputClassname += ' ps-8 pe-[11px]';
  } else if (iconRight) {
    inputClassname += ' ps-[11px] pe-8';
  } else {
    inputClassname += ' px-3';
  }

  if (onerror) {
    inputClassname +=
      ' border-color-red-600 hover:border-color-red-600 focus-visible:border-color-red-600';
  }

  let smallSize = ' text-sm py-[7px]';
  let mediumSize = ' text-base py-[11px]';

  // size input
  if (size === 'small') {
    inputClassname += smallSize;
  } else if (size === 'medium') {
    inputClassname += mediumSize;
  } else {
    //do nothing
  }

  return (
    <>
      <div className={formGroup}>
        <div className={className + ` ${formInputName}`}>
          {iconLeft && (
            <div className="text-sm text-color-gray-90 absolute top-1/2 -translate-y-1/2 left-3">
              {<FontAwesomeIcon icon={iconLeft} />}
            </div>
          )}
          <select
            type="text"
            className={classNameChild + ` ${inputClassname}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...textFieldProps}
          >
            {options.map((item: any, key: number) => {
              return (
                <option
                  value={item.value}
                  className={colorOption ? colorOption[key] : ''}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
          {iconRight && (
            <div className="text-sm text-color-gray-90 absolute top-1/2 -translate-y-1/2 right-3">
              {<FontAwesomeIcon icon={iconRight} />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SelectField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.string,
  onChange: PropTypes.func,
  onerror: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  classNameChild: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  size: PropTypes.oneOf(['medium', 'small']),
  colorOption: PropTypes.array,
  options: PropTypes.any,
};

SelectField.defaultProps = {
  className: '',
  classNameChild: '',
  onerror: false,
  disabled: false, // Default disabled state is false
  iconLeft: null,
  iconRight: null,
  children: '',
  size: 'medium',
  options: [
    {
      label: 'Option 1',
      value: 1,
    },
    {
      label: 'Option 2',
      value: 2,
    },
  ],
  colorOption: [],
  onChange: () => {},
};

export default SelectField;
