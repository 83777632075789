import PropTypes from 'prop-types';

const DateTimePicker = ({ isSecond, val, onChange, indSection, indSubSection }: any) => {
    let minutes = val ? val.slice(0, 2) : '00';
    let seconds = val ? val.slice(3, 5) : '00';
    const clickTime = (time: any) => {
        if (isSecond) {
            onChange(minutes + ":" + time);
        } else {
            onChange(time + ":" + seconds);
        }
    };
    let times = [];
    if(isSecond) {
        // second time only show value '00', '10', '20', '30', '40', '50', '59'
        times = ['00', '10', '20', '30', '40', '50', '59'];
    } else {
        times = Array.from({ length: 100 }, (_, i) => i.toString().padStart(2, '0'));
    }
    return (
        <>
            {times.map(time => (
                <div 
                    key={time}
                    className={`h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white 
                            ${isSecond ? (seconds === time ? `bg-color-blue-400 text-white second-active-${indSection}-${indSubSection}` : '') : (minutes === time ? `bg-color-blue-400 text-white minute-active-${indSection}-${indSubSection}` : '')
                        }`}
                    onClick={() => clickTime(time)}
                >
                    {time}
                </div>
            ))}
        </>
    );
}

DateTimePicker.propTypes = {
    isSecond: PropTypes.bool,
    onChange: PropTypes.func,
    val: PropTypes.string,
    indSection: PropTypes.any,
    indSubSection: PropTypes.any
};

DateTimePicker.defaultProps = {
    isSecond: true,
    onChange: () => { },
    val: '',
    indSection: '',
    indSubSection: ''
};

export default DateTimePicker;