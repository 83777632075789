import { useEffect, useMemo, useState } from 'react';
import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Button from '../../components/atoms/Button';
import { BasicTable } from '../../components/templates/BasicTable';
import { BasicModal } from '../../components/molecules/BasicModal';
import dayjs from 'dayjs';

import type { TableProps } from 'antd';
import SupportSideService, {
  Course,
  SupportData,
} from '../../services/SupportSideService';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/atoms/Loader';
import { applySPFalse, applySPSuccess } from '../../constants/message';
import { getProfile } from '../../helper/localStorage';
import { checkDateDeadline, showToastMessage } from '../../utils/common';
import moment from 'moment';
import { dateFormat, timeFormat } from '../../constants';

interface DataType {
  id: number;
  course: Course;
  is_applied: boolean;
  apply_status: string | null;
  start_at: string;
  end_at: string;
  demo_type: number;
  demo_type_display: string;
  apply_deadline: string;
  fb_deadline: string;
}

const CSDemoRequestingList = () => {
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const initialPage = parseInt(params.get('page') ?? '1', 10);
  // const [isModalOpen, setIsModalOpen] = useState(false);// search modal
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [supportData, setSupportData] = useState<SupportData | undefined>(
    undefined,
  );
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [dataConfirm, setDataConfirm] = useState<DataType | null>(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const page = params.get('page');
    if (page) {
      setCurrentPage(parseInt(page, 10));
    }
  }, [params]);
  // const showModal = () => {
  //   setIsModalOpen(true); // search modal
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false); // search modal
  // };
  // const handleCancel = () => {  // search modal
  //   setIsModalOpen(false);
  // };

  const showModalConfirm = (data: DataType) => {
    setDataConfirm(data);
    setIsModalConfirmOpen(true);
  };
  const handleOkConfirm = async (id: number | undefined) => {
    if (id === undefined) return;
    setIsModalConfirmOpen(false);
    setIsLoading(true);
    const response = await SupportSideService.applySupport(id);
    if (response) {
      await showToastMessage('success', '', applySPSuccess);
      setRefreshTable(prev => !prev);
      setIsLoading(false);
    } else {
      await showToastMessage('error', '', applySPFalse);
      setIsLoading(false);
    }
  };
  const handleCancelConfirm = () => {
    setIsModalConfirmOpen(false);
  };

  useEffect(() => {
    const data = getProfile();
    const parsedData = data ? JSON.parse(data) : null;
    const role = parsedData?.roles;

    if (!Array.isArray(role) || !role.includes(1)) {
      navigate('/not-found');
      return;
    }
    setIsLoading(true);
    SupportSideService.getRecruitingSupport({ page: currentPage }).then(
      response => {
        setIsLoading(false);
        setSupportData(response);
      },
    );
  }, [navigate, currentPage, refreshTable]);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '授業タイトル',
      dataIndex: 'course',
      key: 'course',
      render: course => (
        <div className="text-color-blue-700 font-medium">{course.title}</div>
      ),
    },
    {
      title: '応募済',
      dataIndex: 'is_applied',
      key: 'is_applied',
      className: '!text-center',
      render: is_applied => {
        return is_applied === true ? (
          <div className="text-color-green-600 font-semibold text-center">
            済
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: '応募結果',
      dataIndex: 'apply_status',
      key: 'apply_status',
      className: '!text-center',
      render: apply_status => {
        return apply_status === 0 ? (
          <div className="flex justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="7" stroke="#109F48" strokeWidth="2" />
            </svg>
          </div>
        ) : apply_status === 1 ? (
          <div className="flex justify-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.32741 6.00065L11.0383 2.28971C11.2145 2.1139 11.3135 1.87533 11.3138 1.62648C11.314 1.37763 11.2153 1.13888 11.0395 0.962759C10.8637 0.786639 10.6251 0.687572 10.3763 0.687352C10.1274 0.687132 9.88868 0.785778 9.71256 0.961587L6.00163 4.67252L2.29069 0.961587C2.11457 0.785467 1.8757 0.686523 1.62663 0.686523C1.37755 0.686523 1.13868 0.785467 0.962564 0.961587C0.786443 1.13771 0.6875 1.37658 0.6875 1.62565C0.6875 1.87472 0.786443 2.11359 0.962564 2.28971L4.6735 6.00065L0.962564 9.71159C0.786443 9.88771 0.6875 10.1266 0.6875 10.3756C0.6875 10.6247 0.786443 10.8636 0.962564 11.0397C1.13868 11.2158 1.37755 11.3148 1.62663 11.3148C1.8757 11.3148 2.11457 11.2158 2.29069 11.0397L6.00163 7.32877L9.71256 11.0397C9.88868 11.2158 10.1276 11.3148 10.3766 11.3148C10.6257 11.3148 10.8646 11.2158 11.0407 11.0397C11.2168 10.8636 11.3158 10.6247 11.3158 10.3756C11.3158 10.1266 11.2168 9.88771 11.0407 9.71159L7.32741 6.00065Z"
                fill="#E84F4F"
              />
            </svg>
          </div>
        ) : (
          ''
        );
      },
    },
    {
      title: '授業タイプ',
      dataIndex: 'demo_type_display',
      key: 'demo_type_display',
    },
    {
      title: (
        <>
          模擬授業日
          <br />
          ※動画：視聴可能期間
        </>
      ),
      dataIndex: 'start_at',
      key: 'start_at',
      render: (start_at, data) => {
        let demo_type = data.demo_type;
        const startMoment = moment(start_at);
        const endMoment = moment(data.end_at);
        const formattedDate = startMoment?.format(dateFormat);
        const formattedStartTime = startMoment?.format(`　${timeFormat}`);
        const formattedEndTime = endMoment?.format(timeFormat);
        if (demo_type === 1) {
          return (
            <div className="font-semibold">
              <span>{formattedDate}</span>{' '}
              <span className="text-color-blue-800">
                <span>{formattedStartTime}</span> -{' '}
                <span>{formattedEndTime}</span>
              </span>
            </div>
          );
        }
        return dayjs(start_at).format(dateFormat);
      },
    },
    {
      title: '応募期日',
      dataIndex: 'apply_deadline',
      key: 'apply_deadline',
      render: apply_deadline => (
        <div className="font-semibold">
          {moment(apply_deadline)?.format(dateFormat)}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      className: '!w-[120px]',
      render: (action, data, index) => (
        <div className="grid" key={index}>
          {data.is_applied ? (
            <>
              <p>応募済</p>
            </>
          ) : !checkDateDeadline(data?.apply_deadline, true) ? (
            <>
              <p>応募期限切れ</p>
            </>
          ) : (
            <button
              type="button"
              className="inline-block text-sm font-semibold py-0.5 px-3 rounded text-center bg-color-blue-500 text-white hover:bg-color-blue-700 hover:text-white focus:bg-color-blue-500 focus:text-white disabled:bg-color-blue-200 disabled:text-color-gray-01 disabled:cursor-not-allowed"
              onClick={() => {
                showModalConfirm(data);
              }}
            >
              応募する
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="px-8 mb-24">
          <div className="border border-color-gray-40 bg-white p-4 rounded">
            <div className="flex items-center mb-4">
              <Heading className="text-color-blue-700">
                サポーター募集一覧
              </Heading>
              {/*<Button*/}
              {/*  className="min-w-32 ms-2.5"*/}
              {/*  type="button"*/}
              {/*  variant="outline-primary"*/}
              {/*  size="small"*/}
              {/*  iconSuffix={faSearch}*/}
              {/*  onClick={showModal}*/}
              {/*>*/}
              {/*  詳細検索*/}
              {/*</Button>*/}
            </div>
            <Loader isLoading={isLoading}>
              {supportData && (
                <BasicTable
                  columns={columns}
                  data={supportData?.data}
                  pageSize={supportData?.page_size}
                  totalData={supportData?.total_records}
                  currentPage={supportData?.current_page}
                  setCurrentPage={setCurrentPage}
                  totalPage={supportData?.total_page}
                />
              )}
            </Loader>
          </div>
        </div>
      </div>
      {/*<SearchAdvance*/}
      {/*  isModalOpen={isModalOpen}*/}
      {/*  handleOk={handleOk}*/}
      {/*  handleCancel={handleCancel}*/}
      {/*/>*/}
      <BasicModal
        title="模擬授業のサポーターに応募"
        open={isModalConfirmOpen}
        onOk={() => handleOkConfirm(dataConfirm?.id)}
        onCancel={handleCancelConfirm}
        centered
        width="680px"
        footer={[
          <div className="grid grid-cols-2 gap-4" key={dataConfirm?.id}>
            <div>
              <Button
                type="button"
                variant="outline-tertiary"
                className="w-full"
                onClick={handleCancelConfirm}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                className="w-full"
                onClick={() => handleOkConfirm(dataConfirm?.id)}
              >
                応募する
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="mb-5 border-l-4 border-color-blue-01 pl-2.5">
          <div className="flex flex-wrap">
            <div className="w-[20%] text-color-gray-06 text-base">
              授業タイプ：
            </div>
            <div className="w-[80%] text-color-blue-400 text-base font-semibold ps-1">
              {dataConfirm?.demo_type_display}
            </div>
            <div className="w-[20%] text-color-gray-06 text-base">
              授業タイトル：
            </div>
            <div className="w-[80%] text-color-blue-400 text-base font-semibold ps-1">
              {dataConfirm?.course?.title}
            </div>
            <div className="w-[20%] text-color-gray-06 text-base">実施日：</div>
            <div className="w-[80%] text-color-blue-400 text-base font-semibold ps-1">
              {dataConfirm?.demo_type === 1 ? (
                <>
                  {dayjs(dataConfirm?.start_at)?.format(
                    `${dateFormat}　${timeFormat}`,
                  )}
                  ～{dayjs(dataConfirm?.end_at)?.format(timeFormat)}
                </>
              ) : (
                dayjs(dataConfirm?.start_at)?.format(dateFormat)
              )}
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-[20%] text-color-gray-06 text-base">
              FB提出期日：
            </div>
            <div className="w-[80%] text-color-blue-400 text-base font-semibold ps-1">
              {dayjs(dataConfirm?.fb_deadline).format(dateFormat)}
            </div>
          </div>
        </div>
        <div className="text-base font-semibold color-gray-06 mb-1">
          のサポーターに応募してよろしいですか？
        </div>
        <div className="text-sm color-gray-default">
          依頼の有無について後ほど詳細メールが届きますので、少しお待ちください。
        </div>
      </BasicModal>
    </>
  );
};
export default CSDemoRequestingList;
