import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteKey, rc } from '../../constants/router';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const backToDashboard = () => {
    navigate(rc(RouteKey.Home).path);
  };

  return (
    <div className="flex flex-col justify-center items-center mt-8">
      <h1 className="text-3xl text-red-600">404 - Page Not Found</h1>
      <button className="underline" onClick={backToDashboard}>
        Back to dashboard
      </button>
    </div>
  );
};

export default NotFoundPage;
