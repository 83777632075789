import { useEffect, useState } from 'react';
import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import { Tab, TabPane } from '../../components/atoms/Tab';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import TabContent1 from './included/TabContent1';
import TabContent2 from './included/TabContent2';
import TabContent3 from './included/TabContent3';
import 'react-toastify/dist/ReactToastify.css';
import { Radio } from 'antd/lib';
import styled from 'styled-components';
import { ArrowLeft } from '../../constants';
import { ICourseSchema } from '../../constants/types';
import { ListToastContainer } from '../../components/atoms/ToastMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, rc } from '../../constants/router';
import CourseService from '../../services/CourseService';
import { regexRouter } from '../../utils/common';
import { Loader } from '../../components/atoms/Loader';
import { getProfile } from '../../helper/localStorage';
import RequestDemoDialog from '../../components/organisms/RequestDemoDialog';

const EvaluationView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState<ICourseSchema>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const profile = getProfile();
  const userID = profile && JSON.parse(profile).id;
  const statusShow = [4, 6];

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      CourseService.getDataCourse(id || '').then(response => {
        if (response.status === 200 && response.data.status >= 4) {
          setCourseInfo(response.data);
        } else {
          navigate(regexRouter(rc(RouteKey.Home).path));
        }
        setIsLoading(false);
      });
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Loader isLoading={isLoading} isFullScreen={true}>
        <ListToastContainer />
        <div className="max-w-full">
          <TabLinks className="mb-4" />
          <div className="px-8 py-5 bg-white mb-4">
            <Heading className="text-blue-800">
              <span
                className="mr-2 inline-block align-middle cursor-pointer"
                onClick={() => navigate(rc(RouteKey.Home).path)}
              >
                <ArrowLeft color="#6599ED" />
              </span>
              授業の評価詳細
            </Heading>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div>
                <Label label="ID">
                  <TextField
                    type="text"
                    classNameChild="w-full block"
                    placeholder={id}
                    disabled
                  />
                </Label>
              </div>
              <div className="col-span-2">
                <Label label="タイトル">
                  <TextField
                    type="text"
                    classNameChild="w-full block"
                    placeholder={courseInfo?.title}
                    disabled
                  />
                </Label>
              </div>
            </div>
          </div>
          <div className="mx-8 mb-20">
            <div className="relative p-4 bg-white rounded border border-color-gray-40">
              <div className="overflow-hidden">
                {courseInfo &&
                  userID === courseInfo?.user?.id &&
                  statusShow?.includes(courseInfo?.status) && (
                    <div className="float-right ms-auto pl-4 flex gap-2">
                      {/* {courseInfo?.demo_info && (
                        <Button
                          type="button"
                          variant="primary"
                          iconPrefix={faDisplay}
                          size="small"
                          onClick={showModal}
                        >
                          模擬授業申請
                        </Button>
                      )} */}
                    </div>
                  )}
                <div>
                  <Tab
                    defaultActiveKey="1"
                    className="tab-evaluation-view shorter"
                  >
                    <TabPane tabKey="1" tab="総合評価">
                      <TabContent1 courseInfo={courseInfo} />
                    </TabPane>
                    <TabPane tabKey="2" tab="パート">
                      <TabContent2 courseInfo={courseInfo} />
                    </TabPane>
                    <TabPane tabKey="3" tab="評価内訳">
                      <TabContent3 courseInfo={courseInfo} />
                    </TabPane>
                  </Tab>
                </div>
              </div>
            </div>
          </div>
        </div>

        {id && courseInfo && (
          <RequestDemoDialog
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            id={id}
            courseInfo={courseInfo}
            // FormCSDemo={FormCSDemo}
          />
        )}
      </Loader>
    </>
  );
};
export default EvaluationView;

export const CusRadio = styled(Radio)`
  .ant-radio-input {
    opacity: 1;
  }
  .ant-radio-inner:after {
    background: #4a86e0;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #4a86e0;
  }
`;
