import { CONFIRM_REDIRECT } from "../constants";

export const setConfirmRedirect = (textNotification : string) => {
    return sessionStorage.setItem(CONFIRM_REDIRECT, textNotification);
  }
  
  export const getConfirmRedirect = () => {
    return sessionStorage.getItem(CONFIRM_REDIRECT);
  }
  
  export const removeConfirmRedirect = () => {
    if(getConfirmRedirect()){
      return sessionStorage.removeItem(CONFIRM_REDIRECT);
    }
  };