import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalConfirmStyles = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    padding: 34px 20px 12px 20px;
    margin-bottom: 0;
  }
  .ant-modal-title {
    font-size: 24px;
    font-weight: 600;
  }
  .ant-modal-body {
    padding: 8px 20px;
    border-radius: 0;
  }
  .ant-modal-footer {
    padding: 20px;
    margin-top: 0;
  }
  .ant-modal-close-x {
    color: #757474;
  }
`;
export const BasicModalConfirm = ({
  title,
  width,
  centered,
  open,
  onOk,
  onCancel,
  children,
  footer,
}: any) => {
  return (
    <>
      <ModalConfirmStyles
        title={title}
        width={width}
        centered={centered}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        closable={false}
        footer={[footer]}
        maskClosable={false}
      >
        {children}
      </ModalConfirmStyles>
    </>
  );
};
BasicModalConfirm.prototype = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  footer: PropTypes.node,
};
BasicModalConfirm.defaultProps = {
  width: '448px',
};
