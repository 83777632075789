import PropTypes from 'prop-types';

export const StaticBlock = ({className, imgUrl, text, number} : any) => {
  
  let classNames = 'flex rounded items-center bg-color-gray-20 py-2 px-4 min-w-[216px] ' + className;
  return (
    <>
      <div className={classNames}>
        <div className="w-12 h-12"><img src={imgUrl} alt=""/></div>
        <div className="pl-6">
          <div className="font-bold text-color-blue-700 text-heading-3">{number}</div>
          <div className="text-base text-color-gray-400">{text}</div>
        </div>
      </div>
    </>
  );
};
StaticBlock.prototype = {
  className: PropTypes.string
}
StaticBlock.defaultProps = {
  className: ''
}