import { ICriteriaSchema } from '../constants/types';
import mainAxiosInstance from './api';


class CriteriaService {
  static async getAllCriteria(): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<ICriteriaSchema>(
        '/criteria/'
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
    }
  }
}

export default CriteriaService;