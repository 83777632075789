import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TabLinks } from '../../components/templates/TabLinks';
import { Loader } from '../../components/atoms/Loader';
import { Heading } from '../../components/templates/Heading';
import Button from '../../components/atoms/Button';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { fieldRequired, saveDataSuccess } from '../../constants/message';
import TextArea from 'antd/lib/input/TextArea';
import {
  getInstructionService,
  updateInstructionService,
} from '../../services/Instruction';
import { showToastMessage } from '../../utils/common';

const AIDataTraining = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getInstructionService().then((res: any) => {
      setValue('instruction', res.data.instruction);
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async(d: any) => {
    setIsLoading(true);
    await updateInstructionService(d).then((result: any) => {
      if (result?.status === 200) {
        showToastMessage('success', 'AIデータ教育', saveDataSuccess);
      }
    });
    setIsLoading(false);
  };

  return (
    <>
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="mx-8">
          <form onSubmit={handleSubmit(handleSave)} id="my_page">
            <Loader isLoading={isLoading}>
              <div className="border border-color-gray-40 bg-white p-4 rounded">
                <div className="flex items-center mb-4">
                  <Heading className="text-color-blue-700">
                    AIデータ教育
                  </Heading>
                  <div className="flex gap-2 ms-auto">
                    <Button
                      type="submit"
                      variant="primary"
                      iconPrefix={faCheckDouble}
                      size="small"
                      className="w-full"
                    >
                      保存
                    </Button>
                  </div>
                </div>

                <div className="bg-white py-3">
                  <Controller
                    control={control}
                    name="instruction"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <>
                          <TextArea
                            value={value}
                            onChange={onChange}
                            rows={30}
                          />
                          {errors.instruction && (
                            <p style={{ color: 'red' }}>{fieldRequired}</p>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </Loader>
          </form>
        </div>
      </div>
    </>
  );
};

export default AIDataTraining;
