import { BasicModal } from '../../../components/molecules/BasicModal';
import { BasicTable } from '../../../components/templates/BasicTable';
import Button from '../../../components/atoms/Button';
import type { TableProps } from 'antd';
import { ISupporterUser } from '../../../constants/types';
import { useEffect, useRef, useState } from 'react';
import UserService from '../../../services/UserService';
import moment from 'moment/moment';
import { Loader } from '../../../components/atoms/Loader';
import { dateFormat, timeFormat } from '../../../constants';

const columns: TableProps<ISupporterUser>['columns'] = [
  {
    title: 'ID.',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '作成日',
    dataIndex: 'start_at',
    key: 'start_at',
    render: start_at => {
      const startMoment = moment(start_at).format(dateFormat);
      return <div>{startMoment}</div>
    }
  },
  {
    title: '授業タイトル',
    dataIndex: 'course',
    key: 'course',
    render: class_title => {
      return (
        <div className="text-color-blue-700">{class_title?.title}</div>
      )
    }
  },
  {
    title: '模擬授業実施日',
    dataIndex: '',
    key: '',
    width: 340,
    render: data => {
      const startMoment = moment(data.start_at);
      const endMoment = moment(data.end_at);
      const formattedDate = startMoment.format(dateFormat);
      const formattedStartTime = startMoment.format(`　${timeFormat}`);
      const formattedEndTime = endMoment.format(timeFormat);
      return (
        <div className="font-semibold">
              <div  className="flex">
                {data.demo_type === 0 ? (
                  <div>{data.start_at ? formattedDate : ''}</div>
                ) : (
                  <>
                    <div>{formattedDate} </div>
                    <span className="mx-0.5"></span>
                    <div className="text-color-blue-800">{`${formattedStartTime} - ${formattedEndTime}`}</div>
                  </>
                )}
              </div>
        </div>
      )
    },
  },
  {
    title: '授業タイプ',
    dataIndex: 'demo_type',
    key: 'demo_type',
    render: text => (
      <div>
        {text === 1 ? (
          <div className="text-color-green-600 font-medium">
            オンライン
          </div>
        ) : text === 0 ? (
          <div className="text-color-gray-200 font-medium">動画</div>
        ) : (
          ''
        )
        }
      </div>
    ),
  },
];

const ModalSupporter = ({ isModalOpen, handleOk, setIsModalOpen, idUser }: any) => {
  const [supportData, setSupportData] = useState<any>();
  const isFirstRender = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isModalOpen === true) {
      UserService.getSupporterUser(idUser).then((data) => {
        setSupportData(data);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsLoading(false);
    setSupportData(null);
  }

  return (
    <BasicModal
      title="模擬授業サポート一覧"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width="919px"
      footer={[
        <div className="text-right">
          <Button
            type="button"
            variant="tertiary"
            size="medium"
            className="min-w-[194px]"
            onClick={handleOk}
          >
            キャンセル
          </Button>
        </div>,
      ]}
    >
      <Loader isLoading={isLoading}>
        <BasicTable
          columns={columns}
          data={supportData}
          pageSize={10}
          totalData={supportData?.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPage={supportData?.length / 10}
          modal={true}
        />
      </Loader>
    </BasicModal>
  );
}
export default ModalSupporter;