import { Heading } from '../../components/templates/Heading';
import { TabLinks } from '../../components/templates/TabLinks';
import Label from '../../components/atoms/Label';
import TextField from '../../components/atoms/TextField';
import Button from '../../components/atoms/Button';
import { Tab, TabPane } from '../../components/atoms/Tab';

import TabContent1 from '../EvaluationView/included/TabContent1';
import TabContent2 from '../EvaluationView/included/TabContent2';
import TabContent3 from '../EvaluationView/included/TabContent3';

import { faCheckDouble, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { BasicModal } from '../../components/molecules/BasicModal';
import DatePicker from 'antd/lib/date-picker';
import { Tooltip, type DatePickerProps } from 'antd';
import CourseService from '../../services/CourseService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  copyTextToClipboard,
  paramStateNotification,
  regexRouter,
  showToastMessage,
} from '../../utils/common';
import { rc, RouteKey } from '../../constants/router';
import { Controller, useForm } from 'react-hook-form';
import { demoReviewService } from '../../services/CSSevice';
import { ListToastContainer } from '../../components/atoms/ToastMessage';
import { Loader } from '../../components/atoms/Loader';
import { ArrowLeft, dateFormat } from '../../constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  approveDemoSuccess,
  rejectDemoSuccess,
  systemError,
} from '../../constants/message';
import { IBusyShema, ICalendar, ScheduleSchema } from '../../constants/types';
import axios from 'axios';
import { getCalendarService } from '../../services/Calendar';
import momentTimeZone from 'moment-timezone';

const EvaluationDemoReviewVideo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [courseInfo, setCourseInfo] = useState<any>();
  const [comment, setComment] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('コピー');
  const [calendars, setCalendars] = useState<ICalendar[]>([]);

  const { id, demoId } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setValue(`comment`, '');
    setIsModalOpen(false);
  };

  const handleRejectCS = (d: any) => {
    setIsConfirm(true);
    setComment(d.comment);
  };

  const isMinuteEven = (minute: number) => {
    if (minute === 0 || minute === 30) {
      return true;
    }
    return false;
  };

  const createEvent = async () => {
    try {
      const startCS = courseInfo?.demo_settings?.schedules?.[0].start_at;
      const endCS = courseInfo?.demo_settings?.schedules?.[0].end_at;
      calendars?.forEach(async (calendar: any) => {
        await axios
          .post(
            'https://oauth2.googleapis.com/token',
            {
              client_id: process.env.REACT_APP_GG_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SERCET,
              grant_type: 'refresh_token',
              refresh_token: calendar?.refresh_token,
            },
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
              },
            },
          )
          .then(async res => {
            const listEvent = await axios.get(
              `https://www.googleapis.com/calendar/v3/calendars/${calendar?.calendar_id}/events`,
              {
                headers: {
                  Authorization: `Bearer ${res?.data?.access_token}`,
                },
                params: {
                  singleEvents: true,
                  fields: 'items(start,end,summary,recurrence)',
                  timeMin: momentTimeZone(startCS).tz('Asia/Tokyo')?.startOf('days').format(),
                  timeMax: momentTimeZone(startCS).tz('Asia/Tokyo')?.endOf('days').format(),
                  maxResults: 999,
                  q: '模擬授業'
                },
              },
            );
            const dateSelect = listEvent?.data?.items?.filter(
              (it: IBusyShema) => it.summary === '模擬授業',
            );
            const allDayData = momentTimeZone(startCS)?.format('YYYY-MM-DD');
            const sortedEvents = dateSelect.sort((a: any, b: any) => {
              const firstStartDate = a.start.date ? moment(a.start.date).format('YYYY-MM-DD') : null;
              const secondStartDate = b.start.date ? moment(b.start.date).format('YYYY-MM-DD') : null;

              if (firstStartDate === allDayData && secondStartDate !== allDayData) {
                return -1;
              } else if (firstStartDate !== allDayData && secondStartDate === allDayData) {
                return 1;
              }
              return 0;
            });

            for (let i = 0; i <= sortedEvents.length - 1; i++) {
              const data = sortedEvents[i];
              const startTimeCalendar = momentTimeZone.tz(data?.start?.date ?? data?.start?.dateTime, "Asia/Tokyo");
              const endTimeCalendar = momentTimeZone.tz(data?.end?.date ?? data?.end?.dateTime, "Asia/Tokyo");
              const diffInDays = endTimeCalendar.diff(startTimeCalendar, 'days');
              const startCsDate = momentTimeZone.tz(startCS, "Asia/Tokyo");
              const endCsDate = momentTimeZone.tz(endCS, "Asia/Tokyo");
              const isGreaterThanOrEqual = startCsDate.isSameOrAfter(startTimeCalendar);
              const isLessThanOrEqual = endCsDate.isSameOrBefore(endTimeCalendar);
              const startCsDateEven = isMinuteEven(startCsDate.minute());
              const startTimeCalendarEven = isMinuteEven(startTimeCalendar.minute());
              if (
                (diffInDays || ((isGreaterThanOrEqual && isLessThanOrEqual) && (startCsDateEven === startTimeCalendarEven)))
              ) {
                await axios.post(
                  `https://www.googleapis.com/calendar/v3/calendars/${calendar?.calendar_id}/events`,
                  {
                    summary: `【模擬授業】${courseInfo?.title}`,
                    start: {
                      dateTime:
                        courseInfo?.demo_settings?.schedules[0].start_at,
                      timeZone: 'Asia/Tokyo',
                    },
                    end: {
                      dateTime: courseInfo?.demo_settings?.schedules[0].end_at,
                      timeZone: 'Asia/Tokyo',
                    },
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${res?.data?.access_token}`,
                      'Content-Type': 'application/json',
                    },
                  },
                );

                if (diffInDays) {
                  return;
                }
              }
            }
          });
      });
    } catch (error) {
      throw error;
    }
  };

  const submitReject = async () => {
    setIsLoading(true);
    await demoReviewService(id || '', demoId || '', {
      approve: false,
      reason: comment,
    }).then((result: any) => {
      if (result?.status === 200) {
        navigate(
          regexRouter(rc(RouteKey.Home).path),
          paramStateNotification(
            'success',
            '',
            rejectDemoSuccess(courseInfo?.title),
          ),
        );
      } else {
        showToastMessage('error', '模擬授業の差し戻し', systemError);
      }
    });
    setIsLoading(false);
  };

  const submitCSDemo = async () => {
    setIsLoading(true);
    const result : any = await demoReviewService(id || '', demoId || '', { approve: true });
    if (result?.status === 200) {
      setIsLoading(false);
      if (courseInfo?.demo_type === 1) {
        await createEvent();
      }
      navigate(regexRouter(rc(RouteKey.Home).path), paramStateNotification('success', '模擬授業募集', approveDemoSuccess(courseInfo?.title)))
    } else {
      if (result?.response?.data?.status === 'bad_request') {
        showToastMessage('error', '', result?.response?.data?.detail[0]);
      } else {
        showToastMessage('error', '模擬授業募集', systemError);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCalendarService().then(result => {
      setCalendars(result);
    });
  }, []);

  useEffect(() => {
    const fetch = async () => {
      CourseService.getDataCourseDemoRequest(id || '', demoId).then(
        response => {
          if (response.status === 200) {
            setCourseInfo(response.data);
            if (response.data?.status !== 4) {
              navigate(rc(RouteKey.Home).path);
            }
          } else {
            navigate(regexRouter(rc(RouteKey.Home).path));
          }
          setIsLoading(false);
        },
      );
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {isLoading && (
        <Loader
          isLoading={isLoading}
          isFullScreen={true}
          className="z-[5000]"
        />
      )}

      <ListToastContainer />
      <div className="max-w-full">
        <TabLinks className="mb-4" />
        <div className="px-8 py-5 bg-white mb-4">
          <Heading className="text-blue-800">
            <span
              className="mr-2 inline-block align-middle cursor-pointer"
              onClick={() => navigate(rc(RouteKey.Home).path)}
            >
              <ArrowLeft color="#6599ED" />
            </span>
            授業評価
          </Heading>
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div>
              <Label label="ID">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo?.id}
                  disabled
                />
              </Label>
            </div>
            <div className="col-span-2">
              <Label label="タイトル">
                <TextField
                  type="text"
                  classNameChild="w-full block"
                  placeholder={courseInfo?.title}
                  disabled
                />
              </Label>
            </div>
          </div>
        </div>

        <div className="mx-8 mb-2.5">
          <div className="relative py-5 px-8 bg-white rounded border border-color-gray-40">
            <Heading className="text-blue-800 mb-4">模擬授業の設定</Heading>
            {courseInfo?.demo_type === 1 ? (
              <div className="flex flex-wrap gap-x-4">
                <div className="w-[180px]">
                  <Label label="授業タイプ">
                    <TextField
                      type="text"
                      classNameChild="w-full"
                      value={courseInfo?.demo_type_display}
                      disabled
                    />
                  </Label>
                </div>
                <div className="grow shrink basis-0">
                  <Label label="スケジュール">
                    <div className="flex flex-wrap">
                      {courseInfo?.demo_settings?.schedules?.map(
                        (time: ScheduleSchema, k: number) => (
                          <div className="me-5 last:me-0 text-base font-semibold text-color-blue-500">
                            日時{k + 1}：
                            {moment(time?.start_at).format(
                              'YYYY/MM/DD_HH時mm分',
                            )}
                            ～{moment(time?.end_at).format('HH時mm分')}
                          </div>
                        ),
                      )}
                    </div>
                  </Label>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap gap-x-4">
                <div className="w-[180px]">
                  <Label label="授業タイプ">
                    <TextField
                      type="text"
                      classNameChild="w-full"
                      value={courseInfo?.demo_type_display}
                      disabled
                    />
                  </Label>
                </div>
                <div className="w-[218px]">
                  <Label label="FB期限">
                    <DatePicker
                      popupClassName="cpickerdropdown"
                      className="!py-[11px] w-full"
                      size="large"
                      placeholder=""
                      disabled
                      format={dateFormat}
                    />
                  </Label>
                </div>
                <div className="grow shrink basis-0">
                  <Label label="動画リンク">
                    <div className="p-3 text-base text-color-blue-500 underline border border-color-blue-100 bg-color-blue-5 rounded-md flex items-center gap-x-1 relative">
                      <div className="p-0 border-0 w-4 h-4 inline-flex items-center justify-center">
                        {/* <LinkIcon color="#3F7FE8" /> */}
                      </div>
                      <span className="line-clamp-1 max-w-[550px]">
                        {courseInfo?.demo_settings?.file_url}
                      </span>
                      <Tooltip title={tooltipTitle} placement="bottom">
                        <FontAwesomeIcon
                          icon={faCopy}
                          color="#3F7FE8"
                          className="absolute right-[16px] cursor-pointer"
                          onClick={() => {
                            copyTextToClipboard(
                              courseInfo?.demo_settings?.file_url,
                              setTooltipTitle,
                            );
                            setTimeout(() => setTooltipTitle('コピー'), 1000);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mx-8 mb-20">
          <div className="relative p-4 bg-white rounded border border-color-gray-40">
            <div className="overflow-hidden">
              <div className="float-right ms-auto pl-4 flex gap-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className="inline-block text-sm font-semibold p-2 rounded-md text-center text-color-blue-700 bg-color-red-100 min-w-[142px] hover:bg-color-red-200"
                >
                  差戻
                </button>
                <Button
                  type="button"
                  variant="success"
                  iconPrefix={faCheckDouble}
                  size="small"
                  className="min-w-[142px]"
                  onClick={() => setIsSubmit(true)}
                >
                  サポーター募集
                </Button>
              </div>
              <div>
                <Tab defaultActiveKey="1" className="tab-evaluation-view">
                  <TabPane tabKey="1" tab="総合評価">
                    <TabContent1 courseInfo={courseInfo} />
                  </TabPane>
                  <TabPane tabKey="2" tab="パート">
                    <TabContent2 courseInfo={courseInfo} />
                  </TabPane>
                  <TabPane tabKey="3" tab="評価内訳">
                    <TabContent3 courseInfo={courseInfo} />
                  </TabPane>
                  {/* <TabPane tabKey="4" tab="シナリオ">
                    <TabContent4 courseInfo={courseInfo} />
                  </TabPane> */}
                  {/* <TabPane tabKey="5" tab="波形">
                    <TabContent5 courseInfo={courseInfo} />
                  </TabPane> */}
                </Tab>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BasicModal
        title="模擬授業の差し戻し"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(handleRejectCS)}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Button
                  type="button"
                  variant="outline-tertiary"
                  className="w-full"
                  onClick={handleCancel}
                >
                  キャンセル
                </Button>
              </div>
              <div>
                <Button type="submit" variant="danger" className="w-full">
                  差し戻し
                </Button>
              </div>
            </div>
            ,
          </form>,
        ]}
      >
        <Controller
          control={control}
          name="comment"
          rules={{
            required: 'このフィールドは必須です。',
            maxLength: {
              value: 1000,
              message: '１０００文字以内で入力してください。',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Label label="コメント">
              <TextField
                type="textarea"
                classNameChild="w-full block h-[72px]"
                placeholder="不受理のコメントを入れてください"
                value={value}
                onChange={onChange}
                onerror={errors.comment?.message ? true : false}
              // textNote={errors?.comment?.message}
              />
            </Label>
          )}
        />
      </BasicModal>

      <BasicModal
        title={``}
        open={isConfirm}
        onOk={() => setIsConfirm(true)}
        onCancel={() => setIsConfirm(false)}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(submitReject)}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Button
                  type="button"
                  variant="outline-tertiary"
                  className="w-full"
                  size="small"
                  onClick={() => setIsConfirm(false)}
                >
                  キャンセル
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="danger"
                  className="w-full"
                  size="small"
                >
                  差し戻し
                </Button>
              </div>
            </div>
            ,
          </form>,
        ]}
      >
        <div className="mt-6">
          {`「${courseInfo?.title}」を差し戻しますか？`}
        </div>
      </BasicModal>

      <BasicModal
        title={''}
        open={isSubmit}
        onOk={() => setIsSubmit(true)}
        onCancel={() => setIsSubmit(false)}
        centered
        width="680px"
        footer={[
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Button
                type="button"
                variant="outline-tertiary"
                className="w-full"
                size="small"
                onClick={() => setIsSubmit(false)}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                className="w-full"
                size="small"
                onClick={submitCSDemo}
              >
                サポーター募集
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="mt-6">
          {`「${courseInfo?.title}」のサポーターを募集しますか？`}
        </div>
      </BasicModal>
    </>
  );
};

export default EvaluationDemoReviewVideo;
