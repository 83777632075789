import { StaticBlock } from '../../../components/templates/StaticBlock';

const AdminStatic = ({ data }: any) => {
  const statics = [
    {
      imgUrl: '/assets/Group.svg',
      number: data?.reviewing || 0,
      text: '申請中',
    },
    {
      imgUrl: '/assets/checklist.svg',
      number: data?.not_evaluated || 0,
      text: '未評価',
    },
    {
      imgUrl: '/assets/class.svg',
      number: data?.demo_request || 0,
      text: '模擬授業申請中',
    },
    {
      imgUrl: '/assets/layer1.svg',
      number: data?.recruiting || 0,
      text: 'サポーター募集中',
    },
    {
      imgUrl: '/assets/onboarding 1.svg',
      number: data?.demo_progress || 0,
      text: 'フィードバック未送信',
    },
  ];

  return (
    <div className="flex flex-wrap gap-6 mt-4">
      {statics.map((item: any) => {
        return (
          <StaticBlock
            key={item.index}
            imgUrl={item.imgUrl}
            number={item.number}
            text={item.text}
          />
        );
      })}
    </div>
  );
};
export default AdminStatic;
