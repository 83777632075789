import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateRight,
  faPencil,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ISecion, ISubs } from '../../../constants/types';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IPropsEvaluation, splitAndMapValue } from '..';
import { displayTypeSection, findObjectIndex } from '../../../utils/common';

const TabContent2 = (props: IPropsEvaluation) => {
  const {
    courseInfo,
    EvaluationForm,
    setPromptDT,
    showModal,
    resCheck,
    isSubmit,
    setIsSubmit,
    setContentText,
    setResCheck,
    curTab,
    setIsTemporarySaved,
    isModalOpen,
    valueChange,
    setValueChange,
  } = props;
  const { control, setValue, getValues } = EvaluationForm;
  const [activeCmt, setActiveCmt] = useState(-1);
  const [cmtValues, setCmtValues] = useState<any[]>([]);

  useEffect(() => {
    if (resCheck && isSubmit && curTab === '2') {
      const positon = findObjectIndex(
        courseInfo?.sections,
        resCheck?.section_id,
      );
      setActiveCmt(positon);

      const updatedValueInArray = courseInfo?.sections.map(
        (_: any, index: number) => {
          if (index === positon) {
            return resCheck?.result !== null ? resCheck?.result : '';
          } else {
            return cmtValues[index];
          }
        },
      );
      setCmtValues(updatedValueInArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resCheck, isSubmit]);

  useEffect(() => {
    if (courseInfo && !resCheck) {
      const updateValue = courseInfo?.sections?.map(
        (it: ISecion, idx: number) => {
          const overalCmt =
            isSubmit ||
            activeCmt === -1 ||
            (valueChange !== getValues(`sections[${idx}].overall_comment`) &&
              activeCmt !== -1)
              ? getValues(`sections[${idx}].overall_comment`)
              : it.overall_comment;
          if (
            cmtValues[idx] !== getValues(`sections[${idx}].overall_comment`)
          ) {
            return overalCmt;
          }
          return cmtValues?.[idx];
        },
      );
      setValueChange('');
      setCmtValues(updateValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCmt, resCheck, curTab]);

  useEffect(() => {
    if (isModalOpen || curTab !== '2') {
      setActiveCmt(-1);
    }
  }, [isModalOpen, curTab]);

  return (
    <div className="flex flex-col gap-4">
      <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-group-column">
        <thead>
          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              構成
            </td>
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              内容
            </td>
            <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
              方法
            </td>
            <td className="p-4 h-14 align-middle last:border-r-0">
              学生の活動
            </td>
          </tr>
        </thead>
        <tbody>
          {courseInfo?.sections?.map((section: ISecion, index: number) => {
            return (
              <>
                {section?.subs?.map((sub: ISubs, subIndex: number) => {
                  return (
                    <>
                      <tr className="bg-color-gray-20" key={`sub_${subIndex}`}>
                        {subIndex === 0 && (
                          <td
                            width="100px"
                            className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900 font-semibold"
                            rowSpan={section?.subs?.length}
                          >
                            {displayTypeSection(
                              courseInfo,
                              index,
                              courseInfo?.sections[index].type,
                            )}
                            <br />
                            {section?.subs?.[0].duration}
                          </td>
                        )}
                        <td
                          width="18.7%"
                          className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                        >
                          {splitAndMapValue(sub?.content)}
                        </td>
                        <td
                          width="26%"
                          className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top text-color-blue-900"
                        >
                          {splitAndMapValue(sub?.method)}
                        </td>
                        <td className="px-4 py-2 h-24 align-top text-color-blue-900 last:border-r-0">
                          {splitAndMapValue(sub?.activity)}
                        </td>
                      </tr>
                    </>
                  );
                })}

                <tr
                  className={`text-sm leading-5 ${index === activeCmt ? 'active' : ''}`}
                  key={`part_comment_${index}`}
                >
                  <td className="border-r border-r-color-blue-100 p-4 h-36 align-top text-color-blue-900">
                    <div className="flex flex-col justify-between h-full relative z-[1]">
                      <div>コメント</div>
                      <div className="mt-1">
                        <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                          <button
                            type="button"
                            onClick={() => {
                              showModal();
                              setActiveCmt(index);
                              if (
                                activeCmt !== index ||
                                (activeCmt === index && resCheck)
                              ) {
                                setResCheck();
                              }
                              setIsSubmit(false);
                              setPromptDT({
                                field: 'overall',
                                section_id: section?.id,
                              });
                              setContentText(
                                getValues(
                                  `sections[${index}].overall_comment`,
                                ) || '',
                              );
                            }}
                            className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                          >
                            <FontAwesomeIcon icon={faRotateRight} />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setActiveCmt(index);
                              if (activeCmt !== index) {
                                setResCheck();
                              }
                            }}
                            className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setValue(
                                `sections[${index}].overall_comment`,
                                cmtValues[index],
                              );
                              if (activeCmt !== index && activeCmt !== -1) {
                                setIsTemporarySaved(true);
                              } else setIsTemporarySaved(false);
                              setActiveCmt(-1);
                            }}
                            className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="p-4 h-36 align-top text-color-blue-900 last:border-r-0"
                    colSpan={3}
                  >
                    <Controller
                      control={control}
                      name={`sections[${index}].overall_comment`}
                      render={({ field: { value } }) => {
                        const handleCmtChange = (
                          e: React.ChangeEvent<HTMLTextAreaElement>,
                        ) => {
                          const updatedCmtValues = [...cmtValues];
                          updatedCmtValues[index] = e.target.value;
                          setValueChange(updatedCmtValues[index]);
                          setCmtValues(updatedCmtValues);
                        };
                        return (
                          <textarea
                            className="text-color-blue-900 text-sm resize-none w-full h-full leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white"
                            value={
                              cmtValues[index] !== undefined
                                ? cmtValues[index]
                                : value
                            }
                            disabled={activeCmt !== index}
                            onChange={handleCmtChange}
                          />
                        );
                      }}
                    />
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default TabContent2;
