import mainAxiosInstance from './api';

const RequestDemoService = async (id: string, payload: any) => {
  try {
    const response = await mainAxiosInstance.post(
      `courses/${id}/request-demo/`,
      { ...payload },
    );
    return response.status;
  } catch (err) {
    return false;
  }
};

export default RequestDemoService;
