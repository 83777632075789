// export const TextField = () => <></>;
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextField = ({
  type,
  placeholder,
  disabled,
  readOnly,
  value,
  iconLeft,
  iconRight,
  onChange,
  onerror,
  className,
  classNameChild,
  children,
  size,
  id,
  textNote,
  isViewOnlySelectTime,
  resize,
  ...textFieldProps
}: any) => {
  let formGroup = '';
  let formInputName = 'relative';
  let inputClassname =
    `text-color-gray-600 ${readOnly ? 'bg-color-gray-20' : 'bg-white hover:border-color-blue-400 focus:border-color-blue-700 focus-visible:border-color-blue-700'} rounded-md border border-solid 
    border-color-gray-03 placeholder:text-color-gray-50 hover:outline-0 
    focus:shadow-[0 0 2px rgba(202,223,255,0.7)] 
    ${!isViewOnlySelectTime ? '' : 'disabled:bg-color-gray-02 disabled:border-color-gray-03'} focus-visible:outline-0`;

  if (iconLeft && iconRight) {
    inputClassname += ' px-8';
  } else if (iconLeft) {
    inputClassname += ' ps-8 pe-[11px]';
  } else if (iconRight) {
    inputClassname += ' ps-[11px] pe-8';
  } else {
    inputClassname += ' px-3';
  }

  if (type === 'textarea') {
    inputClassname += `${resize ? ' resize-none' : ''}`;
  }

  if (onerror) {
    inputClassname +=
      ' border-color-red-600 hover:border-color-red-600 focus-visible:border-color-red-600';
  }

  let smallSize = ' text-sm py-[7px]';
  let mediumSize = ' text-base py-[11px]';

  // size input
  if (size === 'small') {
    inputClassname += smallSize;
  } else if (size === 'medium') {
    inputClassname += mediumSize;
  } else {
    //do nothing
  }

  return (
    <>
      {type === 'text' ? (
        <div className={formGroup}>
          <div className={className + ` ${formInputName}`}>
            {iconLeft && (
              <div className="text-sm text-color-gray-90 absolute top-1/2 -translate-y-1/2 left-3">
                {<FontAwesomeIcon icon={iconLeft} />}
              </div>
            )}
            <input
              type="text"
              className={classNameChild + ` ${inputClassname}`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              disabled={disabled}
              readOnly={readOnly}
              id={id}
              {...textFieldProps}
            />
            {iconRight && (
              <div className="text-sm text-color-gray-90 absolute top-1/2 -translate-y-1/2 right-3">
                {<FontAwesomeIcon icon={iconRight} />}
              </div>
            )}
          </div>

          {textNote && (
            <div
              className={`text-sm ${onerror ? 'text-color-red-04' : 'text-color-gray-default'}`}
            >
              {textNote}
            </div>
          )}
        </div>
      ) : (
        <div className={className + ` ${formInputName}`}>
          <textarea
            className={classNameChild + ` ${inputClassname}`}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            id={id}
            readOnly={readOnly}
          >
            {children}
          </textarea>
          {textNote && (
            <div
              className={`text-sm ${onerror ? 'text-color-red-04' : 'text-color-gray-default'}`}
            >
              {textNote}
            </div>
          )}
        </div>
      )}
    </>
  );
};

TextField.propTypes = {
  type: PropTypes.oneOf(['text', 'textarea']),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.string,
  onChange: PropTypes.func,
  onerror: PropTypes.bool,
  className: PropTypes.string,
  classNameChild: PropTypes.string,
  textNote: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  size: PropTypes.oneOf(['medium', 'small']),
  id: PropTypes.any,
  readOnly: PropTypes.bool,
  isViewOnlySelectTime: PropTypes.bool,
  resize: PropTypes.bool
};

TextField.defaultProps = {
  type: 'text',
  className: '',
  classNameChild: '',
  onerror: false,
  disabled: false, // Default disabled state is false
  readOnly: false,
  iconLeft: null,
  iconRight: null,
  children: '',
  size: 'medium',
  onChange: () => { },
  value: '',
  id: null,
  isViewOnlySelectTime: false,
  resize: false
};

export default TextField;
