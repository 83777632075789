import React from 'react';
import { displayTypeSection } from '../../../utils/common';

const TabContent2 = ({ courseInfo }: any) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-group-column text-color-blue-900">
          <thead>
            <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                構成
              </td>
              <td
                className="border-r border-r-color-blue-100 p-4 h-14 align-middle"
              >
                内容
              </td>
              <td className="border-r border-r-color-blue-100 p-4 h-14 align-middle">
                方法
              </td>
              <td className="p-4 h-14 align-middle last:border-r-0">
                学生の活動
              </td>
            </tr>
          </thead>
          <tbody>
            {courseInfo &&
              courseInfo?.sections?.map((section: any, index: number) => (
                <React.Fragment key={index}>
                  {section?.subs?.map((sub: any, subIndex: number) => (
                    <tr key={`${index}-${subIndex}`}>
                      {subIndex === 0 && (
                        <td
                          width="100px"
                          rowSpan={section.subs.length}
                          className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top font-semibold"
                        >
                          {displayTypeSection(
                            courseInfo,
                            index,
                            courseInfo?.sections[index].type,
                          )}
                          <br />
                          {section?.subs?.[0].duration}
                        </td>
                      )}
                      <td
                        width="18.7%"
                        className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top"
                      >
                        {sub.content}
                      </td>
                      <td
                        width="26%"
                        className="border-r border-r-color-blue-100 px-4 py-2 h-24 align-top"
                      >
                        {sub.method}
                      </td>
                      <td className="px-4 py-2 h-24 align-top last:border-r-0">
                        {sub.activity}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default TabContent2;
