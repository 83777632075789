import Button from '../../components/atoms/Button';
import { rc, RouteKey } from '../../constants/router';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import AuthService from '../../services/AuthService';
import TextFieldForm from '../../components/atoms/TextFieldForm';
import { useEffect, useState } from 'react';
import Modal from '../../components/molecules/Modal';
import { inputPromptEmailOrUserID, txtNotificationCheckEmail } from '../../constants/message';
import { BasicModal } from '../../components/molecules/BasicModal';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>('');
  const [isRequestError, setIsRequestError] = useState<boolean>(false);

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate(rc(RouteKey.Home).path);
    }
  }, [navigate]);

  const onSubmit = async (data: any) => {
    AuthService.resetPassword(data.username).then(response => {
      if (response) {
        const res: any = response;
        if (res.status === 200) {
          setIsRequestError(false);
          setOpen(true);
          setModalContent(txtNotificationCheckEmail);
        } else {
          setIsRequestError(true);
        }
      }
    });
  };

  const isValidInput = (input: string): boolean => {
    // Regular expression pattern for email validation
    const emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression pattern for normal characters validation (no special characters, no spaces)
    const normalCharsPattern: RegExp = /^[a-zA-Z0-9]+$/;

    // Check if the input matches either email pattern or normal characters pattern
    return emailPattern.test(input) || normalCharsPattern.test(input);
  };

  return (
    <>
      <div className="min-w-screen min-h-screen bg-no-repeat bg-cover bg-[url('/public/assets/bg_login.png')] flex items-center justify-center p-4">
        <div className="w-[1170px] mx-auto">
          <div className="flex gap-4 justify-between">
            <div className="pt-24 inline-block text-center">
              <img className="mx-auto block" src="/assets/logo.svg" alt="" />
              <h3 className="mb-0 mt-8 text-white text-heading-1 font-semibold drop-shadow-cs-1">
                <span className="font-black">CS</span> Assessment
              </h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white rounded-lg drop-shadow-cs-2 p-8 h-min-[420px] w-[450px]">
                <h3 className="text-heading-3 font-semibold mb-6 text-black">
                  パスワード再設定
                </h3>
                <div className="text-sm text-black mb-7">
                  登録しているメールアドレスまたはユーザーIDを入力してください
                  <br />
                  パスワード再設定ページのURLをメールにてお知らせ致します。
                </div>
                <TextFieldForm
                  className="mb-8"
                  classNameChild="w-full"
                  placeholder="メールアドレスまたはユーザーID"
                  iconLeft={faUser}
                  {...register('username', {
                    required: inputPromptEmailOrUserID,
                    validate: (value: string) =>
                      isValidInput(value) || inputPromptEmailOrUserID,
                  })}
                  onerror={(errors.username?.message || isRequestError) ? true : false}
                  errorMessage={isRequestError ? inputPromptEmailOrUserID : errors.username ? inputPromptEmailOrUserID : ''}
                />
                <Button type="submit" variant="primary" className="w-full">
                  送信
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="flex flex-col gap-4 mt-2">
          <p className="mb-4">{modalContent}</p>
          <div className="flex flex-row justify-center">
            <button
              className="border border-neutral-300 rounded-lg py-1.5 px-10
               bg-blue-500 hover:bg-blue-600 text-white"
              onClick={() => {
                setOpen(false);
                navigate(rc(RouteKey.Login).path);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ForgotPassword;
