import { IChangepassword, UserInfoType } from '../constants/types';
import mainAxiosInstance from './api';
class UserInfoService {
  static async getUserInfo(): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<UserInfoType>(
        'profiles/',
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
    }
  }

  static async updateUserInfo(
    formData: any
  ): Promise<any> {
    try {
      const response = await mainAxiosInstance.put<UserInfoType>(
        'profiles/',
        {
          ...formData,
        },
      );
      if (response.status === 200) {
        return response.status;
      }
    } catch (error : any) {
      if (error.response && error.response.status === 400) {
        return {
          status: 400,
          message: error?.response?.data?.email,
        };
      } else {
        return false;
      }
    }
  }

  static async changePassword(formDataChangePassword: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.put<IChangepassword>(
        'profiles/change-pwd/',
        {
          ...formDataChangePassword,
        },
      );
      if (response.status === 200) {
        return response.status;
      }
    } catch (error) {
    }
  }
}

export default UserInfoService;
