import { IChangepassword, IMailTemplate, UserInfoType } from '../constants/types';
import mainAxiosInstance from './api';
class MailService {
  static async getMailTemplate(mailId : number | undefined, params: any): Promise<any> {
    try {
      const response = await mainAxiosInstance.get<IMailTemplate>(
        mailId ? `settings/mails/${mailId}/` : `settings/mails/`,
        {params}
      );
      return response;

    } catch (error) {
    }
  }

  static async updateMailTemplate(data : any, mailId : number): Promise<any> {
    try {
      const response = await mainAxiosInstance.put<IMailTemplate>(
        `settings/mails/${mailId}/`,
        {
            ...data
        }
      );
      return response;

    } catch (error) {
    }
  }

}

export default MailService;
