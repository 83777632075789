import moment from 'moment';
import {
  TYPE_SECTIONS,
  DEFAULT_SECTION_START_END_TIME,
} from '../constants/index';
import { getProfile } from '../helper/localStorage';
import {
  getConfirmRedirect,
  removeConfirmRedirect,
} from '../helper/sessionStorage';
import { toast } from 'react-toastify';
import ToastMessageCommon from '../components/atoms/ToastMessageCommon';
export const resetTimeSection = (
  fields: any,
  isRemoveSectionFirst: boolean,
) => {
  let data = fields;
  if (isRemoveSectionFirst) {
    // When remove first section -> set time section second default value 00:00 -> 00:00
    data.sections[0].timestamp = DEFAULT_SECTION_START_END_TIME;
  }
  for (let i = 0; i < data.sections.length; i++) {
    const subs = data.sections[i].subs;
    let totalMinutes = 0;
    let firstValue = 0;
    let timestamp = data.sections[i].timestamp[1];
    let timeParts = timestamp.split(':');
    let minutes = parseInt(timeParts[0]);
    let seconds = parseInt(timeParts[1]);
    // add old value time section with new value time (After section first)
    if (i !== 0) {
      firstValue += minutes * 60 + seconds;
    }
    for (let j = 0; j < subs.length; j++) {
      if (subs[j].duration) {
        timestamp = subs[j].duration;
        timeParts = timestamp.split(':');
        minutes = parseInt(timeParts[0]);
        seconds = parseInt(timeParts[1]);
        totalMinutes += minutes * 60 + seconds;
      }
    }
    totalMinutes += firstValue;
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    // calculator time then convert format mm:ss
    const newTimestamp = `${totalHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    // The time of the end of the previous session is equal to the time of the beginning of the next session
    data.sections[i].timestamp[1] = newTimestamp;
    // when change any position , reset first value of all position section
    if (i < data.sections.length - 1) {
      data.sections[i + 1].timestamp[0] = newTimestamp;
      data.sections[i + 1].timestamp[1] = newTimestamp;
    }
  }
  return data;
};

export const displayTypeSection = (formData: any, index: any, value: any) => {
  let countTypeSection = 1;
  let types = TYPE_SECTIONS;
  const typeSections = formData.sections.filter(
    (item: any) => item.type === value,
  );
  // check only text exist
  if (formData.sections.length === 1 || typeSections.length === 1)
    return types[value];
  for (let i = 0; i < formData.sections.length; i++) {
    if (i === index) break;
    if (formData.sections[i].type === value) {
      countTypeSection += 1;
    }
  }
  return `${types[value]} ${countTypeSection}`;
};

export const regexRouter = (route: any) => {
  const regex = /\/:.*$/;
  return route.replace(regex, '');
};

export function convertToWhiteCircledNumber(number: number) {
  const offset = 9311; // Unicode value of the White Circled Number 0 character.
  const whiteCircledNumber = String.fromCharCode(offset + number);
  return whiteCircledNumber;
}

export const findObjectIndex = (data: any, sectionId: number, type = 'id') => {
  for (let i = 0; i < data.length; i++) {
    if (data[i][type] === sectionId) {
      return i;
    }
  }
  return -1;
};

export const linkNavigateTable = (page: any, location: any) => {
  let link = '#';
  if (
    location.search.includes('?page=') ||
    location.search.includes('&page=')
  ) {
    const searchPage = 'page=';
    // Sử dụng biểu thức chính quy để tìm và thay thế giá trị
    const regex = new RegExp(`(${searchPage})(\\d+)`);
    const newSearchParams = location.search.replace(regex, `$1${page}`);
    link = `${location.pathname}${newSearchParams}`;
  } else {
    link = location.search
      ? `${location.pathname}${location.search}&page=${page}`
      : location.pathname + `?page=${page}`;
  }
  return link;
};

export const copyTextToClipboard = (text: string, setTooltipTitle: any) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  //Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      setTooltipTitle('リンクをコピー');
    }
  } catch (err) {
    throw err;
  }
  document.body.removeChild(textArea);
};

export function combineDateTime(date: any, start: string, end: string) {
  return {
    start_at: `${moment(date?.$d).format('YYYY-MM-DD')} ${start}`,
    end_at: `${moment(date?.$d).format('YYYY-MM-DD')} ${end}`,
  };
}

export const isAdmin = () => {
  const roles = JSON.parse(getProfile() || '').roles;
  if (roles.includes(2)) {
    return true;
  }
  return false;
};

export function calculateTotalTime(timeArray: string[]): string {
  const totalSeconds = timeArray.reduce((total, time) => {
    const [minutes, seconds] = time.split(':');
    return total + parseInt(minutes) * 60 + parseInt(seconds);
  }, 0);

  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalSecondsRemaining = totalSeconds % 60;
  return `${totalMinutes.toString().padStart(2, '0')}:${totalSecondsRemaining.toString().padStart(2, '0')}`;
}

export function getAllTimeSection(
  sections: { subs?: { duration: string }[] }[],
): string[] {
  let times: string[] = [];
  sections?.forEach(section => {
    // section have only 1 subs
    if (section.subs && section.subs.length > 0) {
      times.push(section.subs[0].duration);
    }
  });
  return times;
}

export function handleRedirect(
  e: any,
  link: string,
  setIsModalConfirmRedirect: any,
  setRedirectLink: any,
) {
  const confirmRedirect = getConfirmRedirect();
  if (confirmRedirect) {
    e.preventDefault();
    setIsModalConfirmRedirect(true);
    setRedirectLink(link);
  }
}

export function handleConfirmRedirect(redirectLink: string, navigate: any) {
  if (redirectLink.includes('https') || redirectLink.includes('http')) {
    window.open(redirectLink, '_blank');
  } else {
    navigate(redirectLink);
    removeConfirmRedirect();
  }
}

export function checkDateDeadline(date: string, isAssignSupporter: boolean) {
  let currentDate = new Date();
  currentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
  );
  let dateDeadline = new Date(date);
  dateDeadline = new Date(
    dateDeadline.getFullYear(),
    dateDeadline.getMonth(),
    dateDeadline.getDate(),
  );
  if (isAssignSupporter) {
    if (currentDate <= dateDeadline) {
      return true;
    }
  } else {
    if (currentDate >= dateDeadline) {
      return true;
    }
  }
  return false;
}

export async function showToastMessage(
  status: string,
  title: string,
  text: string,
) {
  if (status === 'success') {
    toast.success(
      ToastMessageCommon({
        title: title,
        text: text,
      }),
    );
  } else {
    toast.error(
      ToastMessageCommon({
        title: title,
        text: text,
      }),
    );
  }
}

export function paramStateNotification(
  status: string,
  title: string,
  text: string,
) {
  return {
    state: {
      isNotification: JSON.stringify({
        status: status,
        title: title,
        text: text,
      }),
    },
  };
}
