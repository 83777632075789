import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectField from '../../../components/atoms/Select';
import {
  faChevronDown,
  faRotateRight,
  faPencil,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { colorEvaluation, EVALUATION_RATING_STATUS } from '../../../constants';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { IPropsEvaluation } from '..';

const TabContent1 = (props: IPropsEvaluation) => {
  const {
    courseInfo,
    EvaluationForm,
    showModal,
    setPromptDT,
    resCheck,
    isSubmit,
    setIsSubmit,
    setContentText,
    setResCheck,
    curTab,
    setIsTemporarySaved,
    isModalOpen,
  } = props;
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = EvaluationForm;

  const [activeKey, setActiveKey] = useState<number>(0);
  const [overallValue, setOverallValue] = useState<any>();
  const [objectiveValue, setObjectiveValue] = useState<any>();
  const [methodValue, setMethodValue] = useState<any>();
  const [goalValue, setGoalValue] = useState<any>();

  const colorRating = colorEvaluation;

  useEffect(() => {
    const isCheck = isSubmit || activeKey === 0;
    if (overallValue !== getValues('overall_comment') && !resCheck) {
      setOverallValue(
        isCheck ||
          (overallValue !== getValues('overall_comment') && activeKey !== 0)
          ? getValues('overall_comment')
          : courseInfo?.overall_comment,
      );
    } else if (objectiveValue !== getValues('objective_comment') && !resCheck) {
      setObjectiveValue(
        isCheck ||
          (objectiveValue !== getValues('objective_comment') && activeKey !== 0)
          ? getValues('objective_comment')
          : courseInfo?.objective_comment,
      );
    } else if (methodValue !== getValues('method_comment') && !resCheck) {
      setMethodValue(
        isCheck ||
          (methodValue !== getValues('method_comment') && activeKey !== 0)
          ? getValues('method_comment')
          : courseInfo?.method_comment,
      );
    } else if (goalValue !== getValues('goal_comment') && !resCheck) {
      setGoalValue(
        isCheck || (goalValue !== getValues('goal_comment') && activeKey !== 0)
          ? getValues('goal_comment')
          : courseInfo?.goal_comment,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, resCheck]);

  useEffect(() => {
    if (resCheck && isSubmit && curTab === '1') {
      const result = resCheck?.result !== null ? resCheck?.result : '';
      switch (resCheck.field) {
        case 'overall':
          setActiveKey(1);
          setOverallValue(result);
          break;
        case 'goal':
          setActiveKey(3);
          setGoalValue(result);
          break;
        case 'method':
          setActiveKey(4);
          setMethodValue(result);
          break;
        default:
          setActiveKey(2);
          setObjectiveValue(result);
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resCheck, isSubmit]);

  useEffect(() => {
    if (isModalOpen || curTab !== '1') {
      setActiveKey(0);
    }
  }, [isModalOpen, curTab]);

  return (
    <>
      <div className="flex flex-col gap-4 eval_form">
        <div className="border-color-blue-300 rounded border">
          <div className="border-b border-b-color-blue-100 bg-color-blue-50 py-3 px-4 rounded-t flex items-center gap-8">
            <h3 className="text-color-blue-600 font-semibold text-sm m-0 leading-4">
              総合評価
            </h3>
            <div className="min-w-[200px]">
              <Controller
                control={control}
                name="overall_rating"
                render={({ field: { onChange, value } }) => {
                  return (
                    <SelectField
                      classNameChild={`w-full appearance-none font-bold ${colorRating[value]}`}
                      size="small"
                      options={EVALUATION_RATING_STATUS}
                      iconRight={faChevronDown}
                      value={value}
                      onChange={onChange}
                      colorOption={colorRating}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div
            className={`py-2 px-4 min-h-[166px] relative ${activeKey === 1 ? 'active' : ''}`}
          >
            <Controller
              control={control}
              name="overall_comment"
              render={({ field: { value } }) => {
                return (
                  <textarea
                    className="text-color-blue-900 text-sm resize-none w-full h-[60px] leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white min-h-[100px]"
                    value={
                      overallValue !== undefined
                        ? overallValue
                        : value?.replace(/(\\n)/g, '\n')
                    }
                    onChange={e => setOverallValue(e.target.value)}
                    disabled={activeKey !== 1}
                    key={1}
                  ></textarea>
                );
              }}
            />

            <div className="bottom-[10px] absolute">
              <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                <button
                  type="button"
                  onClick={() => {
                    showModal();
                    setActiveKey(1);
                    if (activeKey !== 1 || (activeKey === 1 && resCheck)) {
                      setResCheck();
                    }
                    setIsSubmit(false);
                    setPromptDT({ field: 'overall' });
                    setContentText(getValues('overall_comment') || '');
                  }}
                  className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                >
                  <FontAwesomeIcon icon={faRotateRight} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setActiveKey(1);
                    if (activeKey !== 1) {
                      setResCheck();
                    }
                  }}
                  className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (overallValue !== undefined) {
                      setValue('overall_comment', overallValue);
                    } else {
                      setValue('overall_comment', courseInfo?.overall_comment);
                    }
                    if (activeKey !== 1 && activeKey !== 0) {
                      setIsTemporarySaved(true);
                    } else setIsTemporarySaved(false);
                    setActiveKey(0);
                  }}
                  className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {errors?.overall_comment && (
          <span style={{ color: 'red' }}>
            {errors?.overall_comment.message}
          </span>
        )}

        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-simple">
          <tr
            className="bg-color-blue-50 text-color-blue-600 font-semibold"
            key={1}
          >
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              目的
            </td>
            <Controller
              control={control}
              name="objective"
              render={({ field: { value } }) => (
                <td className="p-4 h-24 align-top last:border-r-0">
                  <ul className="list-disc list-outside">
                    {value
                      ?.split(/\r?\n/)
                      .map((item: any, index: number) => (
                        <p key={index}>{item}</p>
                      ))}
                  </ul>
                </td>
              )}
            />
          </tr>

          <tr className={`${activeKey === 2 ? 'active' : ''}`} key={2}>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              <div className="flex flex-col justify-between h-full relative z-[1]">
                <div>コメント</div>
                <div className="mb-2 mt-7">
                  <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                    <button
                      type="button"
                      onClick={() => {
                        showModal();
                        setActiveKey(2);
                        if (activeKey !== 2 || (activeKey === 2 && resCheck)) {
                          setResCheck();
                        }
                        setIsSubmit(false);
                        setPromptDT({ field: 'objective' });
                        setContentText(getValues('objective_comment') || '');
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setActiveKey(2);
                        if (activeKey !== 2) {
                          setResCheck();
                        }
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (objectiveValue !== undefined) {
                          setValue('objective_comment', objectiveValue);
                        } else {
                          setValue(
                            'objective_comment',
                            courseInfo?.objective_comment,
                          );
                        }
                        if (activeKey !== 2 && activeKey !== 0) {
                          setIsTemporarySaved(true);
                        } else setIsTemporarySaved(false);
                        setActiveKey(0);
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              <Controller
                control={control}
                name="objective_comment"
                render={({ field: { value } }) => (
                  <textarea
                    className="text-color-blue-900 text-sm resize-none w-full h-full leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white"
                    value={
                      objectiveValue !== undefined
                        ? objectiveValue
                        : value?.replace(/(\\n)/g, '\n')
                    }
                    disabled={activeKey !== 2}
                    onChange={e => setObjectiveValue(e.target.value)}
                  ></textarea>
                )}
              />
            </td>
          </tr>

          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              クラスの達成目標
            </td>
            <td className="p-4 h-24 align-top last:border-r-0">
              <Controller
                control={control}
                name="goal"
                render={({ field: { value } }) => (
                  <>
                    {value
                      ?.split(/\r?\n/)
                      .map((line: string, index: number) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </>
                )}
              />
            </td>
          </tr>

          <tr className={`${activeKey === 3 ? 'active' : ''}`} key={3}>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              <div className="flex flex-col justify-between h-full relative z-[1]">
                <div>コメント</div>
                <div className="mb-2 mt-7">
                  <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                    <button
                      type="button"
                      onClick={() => {
                        showModal();
                        setActiveKey(3);
                        if (activeKey !== 3 || (activeKey === 3 && resCheck)) {
                          setResCheck();
                        }
                        setIsSubmit(false);
                        setPromptDT({ field: 'goal' });
                        setContentText(getValues('goal_comment') || '');
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setActiveKey(3);
                        if (activeKey !== 3) {
                          setResCheck();
                        }
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (goalValue !== undefined) {
                          setValue('goal_comment', goalValue);
                        } else {
                          setValue('goal_comment', courseInfo?.goal_comment);
                        }
                        if (activeKey !== 3 && activeKey !== 0) {
                          setIsTemporarySaved(true);
                        } else setIsTemporarySaved(false);
                        setActiveKey(0);
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              <Controller
                control={control}
                name="goal_comment"
                render={({ field: { value } }) => (
                  <textarea
                    className="text-color-blue-900 text-sm resize-none w-full h-full leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white"
                    value={
                      goalValue !== undefined
                        ? goalValue
                        : value?.replace(/(\\n)/g, '\n')
                    }
                    disabled={activeKey !== 3}
                    onChange={e => setGoalValue(e.target.value)}
                  ></textarea>
                )}
              />
            </td>
          </tr>

          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              目標達成の
              <br />
              測定方法
            </td>
            <td className="p-4 h-24 align-top last:border-r-0">
              <Controller
                control={control}
                name="method"
                render={({ field: { value } }) => (
                  <>
                    {value
                      ?.split(/\r?\n/)
                      .map((line: string, index: number) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </>
                )}
              />
            </td>
          </tr>

          <tr className={`${activeKey === 4 ? 'active' : ''}`} key={4}>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              <div className="flex flex-col justify-between h-full relative z-[1]">
                <div>コメント</div>
                <div className="mb-2 mt-7">
                  <div className="bg-white p-2 border-2 border-white rounded-[5px] inline-flex shadow-cs-2">
                    <button
                      type="button"
                      onClick={() => {
                        showModal();
                        setActiveKey(4);
                        if (activeKey !== 4 || (activeKey === 4 && resCheck)) {
                          setResCheck();
                        }
                        setIsSubmit(false);
                        setPromptDT({ field: 'method' });
                        setContentText(getValues('method_comment') || '');
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-red-50 hover:text-color-red-600"
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setActiveKey(4);
                        if (activeKey !== 4) {
                          setResCheck();
                        }
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl hover:bg-color-blue-50 hover:text-color-blue-500"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (methodValue !== undefined) {
                          setValue('method_comment', methodValue);
                        } else {
                          setValue(
                            'method_comment',
                            courseInfo?.method_comment,
                          );
                        }
                        if (activeKey !== 4 && activeKey !== 0) {
                          setIsTemporarySaved(true);
                        } else setIsTemporarySaved(false);
                        setActiveKey(0);
                      }}
                      className="rounded border-0 p-0 font-normal inline-flex items-center justify-center bg-color-gray-20 w-7 h-7 text-color-blue-300 me-2 text-xl last:me-0 hover:bg-color-green-50 hover:text-color-green-600"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              <Controller
                control={control}
                name="method_comment"
                render={({ field: { value } }) => (
                  <textarea
                    className="text-color-blue-900 text-sm resize-none w-full h-full leading-5 hover:outline-0 focus:outline-0 focus-visible:outline-0 bg-white"
                    value={
                      methodValue !== undefined
                        ? methodValue
                        : value?.replace(/(\\n)/g, '\n')
                    }
                    disabled={activeKey !== 4}
                    onChange={e => setMethodValue(e.target.value)}
                  ></textarea>
                )}
              />
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default TabContent1;
