import { StaticBlock } from '../../../components/templates/StaticBlock';
import { Link } from 'react-router-dom';
import { regexRouter } from '../../../utils/common';
import { rc, RouteKey } from '../../../constants/router';
import Button from '../../../components/atoms/Button';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

const UserStatic = ({ data }: any) => {
  const statics = [
    {
      imgUrl: '/assets/checklist.svg',
      number: data?.evaluated || 0,
      text: '評価完了',
    },
    {
      imgUrl: '/assets/class.svg',
      number: data?.completed || 0,
      text: 'フィードバック未送信',
    },
  ];

  return (
    <div className="flex flex-wrap gap-6 mt-4">
      <div className="flex items-center">
        <Link to={`${regexRouter(rc(RouteKey.Lesson).path)}`}>
          <Button
            className="min-w-35 h-15"
            type="button"
            iconPrefix={faCirclePlus}
            variant="primary"
            size="small"
          >
            {'クラスデザインシート'} <br />
            {'新規作成'}
          </Button>
        </Link>
      </div>
      {statics.map((item: any) => {
        return (
          <StaticBlock
            key={item.index}
            imgUrl={item.imgUrl}
            number={item.number}
            text={item.text}
          />
        );
      })}
    </div>
  );
};
export default UserStatic;
