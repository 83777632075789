import { useState } from 'react';
import Button from '../../components/atoms/Button';
import Checkbox from '../../components/atoms/Checkbox';
import Radio from '../../components/atoms/Radio';
import TextField from '../../components/atoms/TextField';
import ToastMessage from '../../components/atoms/ToastMessage';
import IconButton from '../../components/atoms/IconButton';
import Status from '../../components/atoms/Status';
import { Tab, TabPane } from '../../components/atoms/Tab';
import BasicTooltip from '../../components/atoms/Tooltips';
import { MenuItem } from '../../components/atoms/MenuItem';
import { BasicDropdown } from '../../components/molecules/Dropdown';
import Label from '../../components/atoms/Label';
import SimpleBar from 'simplebar-react';

import DatePicker from 'antd/lib/date-picker';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuProps } from 'antd';

import {
  faCopy,
  faUser,
  faCircleXmark,
  faAngleRight,
  faArrowRight,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const items: MenuProps['items'] = [
  {
    label: (
      <MenuItem
        className="active"
        target="_blank"
        iconPrefix={faAngleRight}
        iconSuffix={faAngleRight}
        href="https://www.antgroup.com"
      >
        1st menu item
      </MenuItem>
    ),
    key: '0',
  },
  {
    label: (
      <MenuItem
        iconPrefix={faAngleRight}
        iconSuffix={faAngleRight}
        href="https://www.aliyun.com"
      >
        2nd menu item
      </MenuItem>
    ),
    key: '1',
  },
];
const dropdownItemCheck = (
  <>
    <div className="p-2 bg-white shadow-cs-1 relative m-0 font-notosans">
      <Checkbox
        id="uncheckedDropdown"
        cls="mb-1"
        label="Unchecked"
        // onChange={handleCheckboxChange}
      />
      <Checkbox
        id="checkedDropdown"
        cls="mb-1"
        label="Checked"
        checked
        // onChange={handleCheckboxChange}
      />
      <Checkbox
        id="checkedDisabledDropdown"
        label="Checked Disabled"
        checked
        disabled
      />
    </div>
  </>
);

const timeOptions = (
  <>
    <div className="inline-block w-auto bg-white shadow-cs-2 pt-2">
      <div className="flex gap-x-2 pb-5">
        <SimpleBar forceVisible="y" autoHide={true} className="h-[352px]">
          <div className="w-[72px] pe-2 box-border">
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              00
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              01
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              02
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white bg-color-blue-400 text-white">
              03
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              04
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              05
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              06
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              07
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              08
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              09
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              10
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              11
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              12
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              13
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              14
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              15
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              16
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              17
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              18
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              19
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              20
            </div>
          </div>
        </SimpleBar>

        <SimpleBar forceVisible="y" autoHide={true} className="h-[352px]">
          <div className="w-16 pe-2 box-border">
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              00
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              01
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              02
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white bg-color-blue-400 text-white">
              03
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              04
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              05
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              06
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              07
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              08
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              09
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              10
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              11
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              12
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              13
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              14
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              15
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              16
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              17
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              18
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              19
            </div>
            <div className="h-8 flex items-center text-center justify-center rounded-sm ease-in duration-100 hover:bg-color-blue-400 hover:text-white">
              20
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="mx-2 py-2 flex justify-end border-t border-t-color-gray-02">
        <Button
          className="min-w-15 me-auto"
          type="button"
          variant="text-link"
          size="small"
        >
          Now
        </Button>
        <Button
          className="min-w-15"
          type="button"
          variant="primary"
          size="small"
        >
          OK
        </Button>
      </div>
    </div>
  </>
);

function Guideline() {
  const [pickerType, setPickerType] = useState<'month' | 'year'>('month');
  const handleCheckboxChange = (checked: any) => {};
  const onChange: DatePickerProps['onChange'] = ({ date, dateString }: any) => {
    console.log(date, dateString);
  };
  const dateFormat = 'YYYY/MM/DD';
  const monthFormat = 'YYYY/MM';
  const dateTimeFormat = 'YYYY/MM/DD HH:mm';
  const timeFormat = 'HH:mm';
  const { RangePicker } = DatePicker;

  const handlePickerMonth = () => {
    setPickerType('month');
  };

  const handlePickerYear = () => {
    setPickerType('year');
  };

  return (
    <>
      <div className="mx-4">
        <h1 className="font-bold mb-2 text-3xl">Datepicker</h1>
        <div className="grid grid-cols-4 gap-4 mb-6">
          <BasicDropdown
            overlay={timeOptions}
            trigger="click"
            placement="bottomLeft"
          >
            <TextField
              placeholder="07:00"
              size="medium"
              iconRight={faClock}
              classNameChild="w-full"
            />
          </BasicDropdown>
          <BasicDropdown
            overlay={timeOptions}
            trigger="click"
            placement="bottomLeft"
          >
            <TextField
              value="07:00"
              size="medium"
              iconRight={faClock}
              classNameChild="w-full"
            />
          </BasicDropdown>
          <BasicDropdown
            overlay={timeOptions}
            trigger="click"
            placement="bottomLeft"
          >
            <TextField
              placeholder="07:00"
              size="medium"
              iconRight={faClock}
              classNameChild="w-full"
            />
          </BasicDropdown>
          <BasicDropdown
            overlay={timeOptions}
            trigger="click"
            placement="bottomLeft"
            disabled
          >
            <TextField
              value="07:00"
              size="medium"
              iconRight={faClock}
              classNameChild="w-full"
              disabled
            />
          </BasicDropdown>
        </div>
        <div className="grid grid-cols-4 gap-4 mb-6">
          <div className="flex bg-white border border-color-grey-03 rounded-md hover:border-color-blue-500 relative pe-8">
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  placeholder="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-r-none !pe-0"
                />
              </BasicDropdown>
            </div>
            <div className="w-8 flex items-center justify-center">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            </div>
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  placeholder="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-none !px-0"
                />
              </BasicDropdown>
            </div>
            <span className="absolute top-1/2 right-3 -translate-y-1/2">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faClock}
              />
            </span>
          </div>
          {/*  */}
          <div className="flex bg-white border border-color-grey-03 rounded-md hover:border-color-blue-500 relative pe-8">
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  value="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-r-none !pe-0"
                />
              </BasicDropdown>
            </div>
            <div className="w-8 flex items-center justify-center">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            </div>
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  value="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-none !px-0"
                />
              </BasicDropdown>
            </div>
            <span className="absolute top-1/2 right-3 -translate-y-1/2">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faClock}
              />
            </span>
          </div>
          {/*  */}
          <div className="flex bg-white border border-color-grey-03 rounded-md hover:border-color-blue-500 relative pe-8">
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  placeholder="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-r-none !pe-0"
                />
              </BasicDropdown>
            </div>
            <div className="w-8 flex items-center justify-center">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            </div>
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  placeholder="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-none !px-0"
                />
              </BasicDropdown>
            </div>
            <span className="absolute top-1/2 right-3 -translate-y-1/2">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faClock}
              />
            </span>
          </div>
          {/*  */}
          <div className="flex bg-white border border-color-grey-03 rounded-md hover:border-color-blue-500 relative pe-8">
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  value="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-r-none !pe-0"
                  disabled
                />
              </BasicDropdown>
            </div>
            <div className="w-8 flex items-center justify-center">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            </div>
            <div className="grow shrink basis-0">
              <BasicDropdown
                overlay={timeOptions}
                trigger="click"
                placement="bottomLeft"
              >
                <TextField
                  value="07:00"
                  size="medium"
                  classNameChild="w-full !border-transparent rounded-none !px-0"
                  disabled
                />
              </BasicDropdown>
            </div>
            <span className="absolute top-1/2 right-3 -translate-y-1/2">
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faClock}
              />
            </span>
          </div>
          {/*  */}
        </div>

        <h1 className="font-bold mb-2 text-3xl">Button</h1>
        <div className="grid grid-cols-6 gap-4 mb-6">
          <div>
            <h1 className="font-bold mb-2">Contained primary</h1>
            <p className="mb-2">
              <Button
                type="button"
                variant="primary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="primary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="primary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="primary"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="button" variant="primary">
                Default - NoIcon
              </Button>
            </p>
          </div>
          <div>
            <h1 className="font-bold mb-2">Contained tertiary</h1>
            <p className="mb-2">
              <Button
                type="button"
                variant="tertiary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium - Default
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="tertiary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="button" variant="tertiary">
                Default - NoIcon
              </Button>
            </p>
          </div>

          <div>
            <h1 className="font-bold mb-2">Outline primary</h1>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-primary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-primary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-primary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-primary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="button" variant="outline-primary">
                Default - NoIcon
              </Button>
            </p>
          </div>
          <div>
            <h1 className="font-bold mb-2">Outline tertiary</h1>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-tertiary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium - Default
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-tertiary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="outline-tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="button" variant="outline-tertiary">
                Default - NoIcon
              </Button>
            </p>
          </div>

          <div>
            <h1 className="font-bold mb-2">Text primary</h1>
            <p className="mb-2">
              <Button
                type="button"
                variant="text-primary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="text-primary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="text-primary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="button"
                variant="text-primary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="button" variant="text-primary">
                Default - NoIcon
              </Button>
            </p>
          </div>
          <div>
            <h1 className="font-bold mb-2">Text tertiary</h1>
            <p className="mb-2">
              <Button
                type="link"
                variant="text-tertiary"
                size="large"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                className="w-full"
              >
                Large
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="link"
                variant="text-tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Medium - Default
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="link"
                variant="text-tertiary"
                size="small"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
              >
                Small
              </Button>
            </p>
            <p className="mb-2">
              <Button
                type="link"
                variant="text-tertiary"
                size="medium"
                iconPrefix={faCopy}
                iconSuffix={faCopy}
                disabled
              >
                Disabled
              </Button>
            </p>
            <p className="mb-2">
              <Button type="link" variant="text-tertiary">
                Default - NoIcon
              </Button>
            </p>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">IconButton</h1>
        <div className="grid grid-cols-3 gap-4 mb-6">
          <div>
            <h1 className="font-bold mb-2">IconButton tertiary</h1>
            <div className="flex gap-3 items-center">
              <p>
                <IconButton icon={faCopy} />
              </p>
              <p>
                <IconButton icon={faCopy} size="small" variant="tertiary" />
              </p>
              <p>
                <IconButton icon={faCopy} disabled />
              </p>
            </div>
          </div>
          <div>
            <h1 className="font-bold mb-2">IconButton primary</h1>
            <div className="flex gap-3 items-center">
              <p>
                <IconButton icon={faCopy} variant="primary" />
              </p>
              <p>
                <IconButton icon={faCopy} size="small" variant="primary" />
              </p>
              <p>
                <IconButton icon={faCopy} variant="primary" disabled />
              </p>
            </div>
          </div>
          <div>
            <h1 className="font-bold mb-2">IconButton danger</h1>
            <div className="flex gap-3 items-center">
              <p>
                <IconButton icon={faCopy} variant="danger" />
              </p>
              <p>
                <IconButton icon={faCopy} size="small" variant="danger" />
              </p>
              <p>
                <IconButton icon={faCopy} variant="danger" disabled />
              </p>
            </div>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl">Checkbox</h1>
        <div className="grid grid-cols-5 gap-4 mb-6">
          <div className="mb-2">
            <Checkbox
              id="unchecked"
              label="Unchecked"
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-2">
            <Checkbox
              id="checked"
              label="Checked"
              checked
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-2">
            <Checkbox
              id="checkedDisabled"
              label="Checked Disabled"
              checked
              disabled
            />
          </div>
          <div className="mb-2">
            <Checkbox
              id="indeterminate"
              label="Indeterminate"
              onChange={handleCheckboxChange}
              indeterminate
            />
          </div>
          <div className="mb-2">
            <Checkbox id="disabled" label="Disabled" disabled />
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl">Radio</h1>

        <div className="grid grid-cols-5 gap-4 mb-6">
          <div className="mb-2">
            <Radio
              id="unchecked_radio"
              label="Unchecked"
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-2">
            <Radio
              id="checked_radio"
              label="Checked"
              checked
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mb-2">
            <Radio
              id="checkedDisabled_radio"
              label="Checked Disabled"
              checked
              disabled
            />
          </div>
          <div className="mb-2">
            <Radio id="disabled_radio" label="Disabled" disabled />
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">TextField</h1>
        <div className="mb-2">
          <div className="grid grid-cols-4 gap-4 mb-6">
            <TextField
              className="test"
              classNameChild="w-full"
              placeholder="Placeholder - Width full"
            />
            <TextField
              classNameChild="w-full"
              placeholder="Placeholder - Width full"
              iconLeft={faUser}
            />
            <TextField placeholder="Placeholder" />
            <TextField value="Value" />
            <div>
              <TextField
                className="inline-block"
                placeholder="Placeholder"
                iconLeft={faUser}
                iconRight={faCircleXmark}
              />
            </div>
            <TextField
              classNameChild="w-full"
              placeholder="Placeholder - Width full"
              iconRight={faCircleXmark}
            />
            <TextField placeholder="Placeholder" disabled />
            <TextField 
              value="value" 
              onerror
              textNote="Please enter the data"
            />
          </div>
          <div className="grid grid-cols-4 gap-4 mb-6">
            <TextField
              type="textarea"
              classNameChild="w-full"
              placeholder="Placeholder - Width full"
            />
            <TextField type="textarea" classNameChild="w-full">
              Value
            </TextField>
            <TextField
              type="textarea"
              classNameChild="w-full"
              placeholder="Value disabled"
              disabled
            />
            <TextField
              type="textarea"
              classNameChild="w-full"
              placeholder="Placeholder - Width full"
              onerror
              textNote="Please enter the data"
            />
          </div>
        </div>
        <h1 className="font-bold mb-2 text-3xl mt-6">Label</h1>
        <div className="mb-2">
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="mb-2">
              <Label
                className="mb-5"
                label="Lable"
                required
                textTooltip="My tooltip"
                iconTooltip={faCircleInfo}
                optional
                textNote="Please input passenger's name or delete this field."
              >
                <TextField
                  className="inline-block"
                  placeholder="Placeholder"
                  iconLeft={faUser}
                  iconRight={faCircleXmark}
                />
              </Label>
            </div>
            <div className="mb-2">
              <Label
                className="mb-5"
                label="Lable"
                required
                textTooltip="My tooltip"
                iconTooltip={faCircleInfo}
                optional
                textNote="Please input passenger's name or delete this field."
                onerror
              >
                <TextField
                  className="inline-block"
                  placeholder="Placeholder"
                  iconLeft={faUser}
                  iconRight={faCircleXmark}
                  onerror
                />
              </Label>
            </div>
            <div className="mb-2">
              <Label
                className="mb-5"
                label="Lable"
                required
                textTooltip="My tooltip"
                iconTooltip={faCircleInfo}
                optional
                textNote="Please input passenger's name or delete this field."
              >
                <TextField
                  type="textarea"
                  classNameChild="w-full block"
                  placeholder="太郎"
                />
              </Label>
            </div>
            <div className="mb-2">
              <Label
                className="mb-5"
                label="Lable"
                required
                textTooltip="My tooltip"
                iconTooltip={faCircleInfo}
                optional
                textNote="Please input passenger's name or delete this field."
                onerror
              >
                <TextField
                  type="textarea"
                  classNameChild="w-full block"
                  placeholder="太郎"
                  onerror
                />
              </Label>
            </div>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Status</h1>
        <div className="grid grid-cols-4 gap-4 mb-6">
          <div className="mb-2">
            <p className="mb-2">
              <Status type="text" variant="success">
                共同購入募集達成しました！
              </Status>
            </p>
            <p className="mb-2">
              <Status type="text" variant="primary">
                共同購入募集中
              </Status>
            </p>
            <p className="mb-2">
              <Status type="text" variant="danger">
                不成立
              </Status>
            </p>
            <p className="mb-2">
              <Status type="text" variant="light-primary">
                残りの口数を一括購入
              </Status>
            </p>
            <p className="mb-2">
              <Status type="text" variant="gray">
                オークション終了
              </Status>
            </p>
          </div>
          <div className="mb-2">
            <p className="mb-2">
              <Status type="badge" variant="success" size="large">
                共同購入募集達成しました！
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="primary" size="large">
                共同購入募集中
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="danger" size="large">
                不成立
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="light-primary" size="large">
                残りの口数を一括購入
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="gray" size="large">
                オークション終了
              </Status>
            </p>
          </div>
          <div className="mb-2">
            <p className="mb-2">
              <Status type="badge" variant="success" size="small">
                共同購入募集達成しました！
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="primary" size="small">
                共同購入募集中
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="danger" size="small">
                不成立
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="light-primary" size="small">
                残りの口数を一括購入
              </Status>
            </p>
            <p className="mb-2">
              <Status type="badge" variant="gray" size="small">
                オークション終了
              </Status>
            </p>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">ToastMessage</h1>
        <div className="mb-2">
          <ToastMessage />
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Tab</h1>
        <div className="grid grid-cols-4 gap-4 mb-6">
          <div>
            <Tab defaultActiveKey="1">
              <TabPane tabKey="1" tab="Tab 1">
                Content of Tab Pane 1
              </TabPane>
              <TabPane tabKey="2" tab="Tab 2">
                Content of Tab Pane 2
              </TabPane>
              <TabPane tabKey="3" tab="Tab 3">
                Content of Tab Pane 3
              </TabPane>
            </Tab>
          </div>
          <div>
            <Tab defaultActiveKey="1" direction="vertical">
              <TabPane tabKey="1" tab="Tab 1">
                Content of Tab Pane 1
              </TabPane>
              <TabPane tabKey="2" tab="Tab 2">
                Content of Tab Pane 2
              </TabPane>
              <TabPane tabKey="3" tab="Tab 3">
                Content of Tab Pane 3
              </TabPane>
            </Tab>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Date Picker</h1>
        <div className="grid grid-cols-4 gap-4">
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            placeholder="yyyy/mm/dd"
            format={dateFormat}
            onChange={onChange}
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            defaultValue={dayjs('2020/11/08', dateFormat)}
            format={dateFormat}
            onChange={onChange}
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            placeholder="yyyy/mm/dd"
            format={dateFormat}
            onChange={onChange}
            disabled
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            defaultValue={dayjs('2020/11/08', dateFormat)}
            format={dateFormat}
            onChange={onChange}
            disabled
          />
          <RangePicker
            popupClassName="cpickerdropdown"
            size="large"
            format={dateFormat}
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
          <RangePicker
            popupClassName="cpickerdropdown"
            defaultValue={[
              dayjs('2020/11/08', dateFormat),
              dayjs('2020/12/23', dateFormat),
            ]}
            format={dateFormat}
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
          <RangePicker
            popupClassName="cpickerdropdown"
            size="large"
            format={dateFormat}
            disabled
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
          <RangePicker
            popupClassName="cpickerdropdown"
            defaultValue={[
              dayjs('2020/11/08', dateFormat),
              dayjs('2020/12/23', dateFormat),
            ]}
            format={dateFormat}
            disabled
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            renderExtraFooter={() => (
              <div className="picker-tabs">
                <button
                  type="button"
                  onClick={handlePickerMonth}
                  className={pickerType === 'month' ? 'active' : ''}
                >
                  Month
                </button>
                <button
                  type="button"
                  onClick={handlePickerYear}
                  className={pickerType === 'year' ? 'active' : ''}
                >
                  Year
                </button>
              </div>
            )}
            onChange={onChange}
            picker={pickerType}
            format={monthFormat}
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            onChange={onChange}
            picker="year"
          />
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">DateTime Picker</h1>
        <div className="grid grid-cols-4 gap-4">
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            format={dateTimeFormat}
            onChange={onChange}
            showTime
            placeholder="Select date time"
          />
          <DatePicker
            popupClassName="cpickerdropdown"
            size="large"
            format={dateTimeFormat}
            onChange={onChange}
            showTime
            placeholder="Select date time"
            disabled
          />
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Time Picker</h1>
        <div className="grid grid-cols-4 gap-4">
          <TimePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            format={timeFormat}
            placeholder="HH:mm"
          />
          <TimePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            defaultValue={dayjs('07:00', timeFormat)}
            format={timeFormat}
          />
          <TimePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            format={timeFormat}
            placeholder="HH:mm"
            disabled
          />
          <TimePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            defaultValue={dayjs('07:00', timeFormat)}
            format={timeFormat}
            disabled
          />
          <TimePicker.RangePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            format={timeFormat}
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
            showNow={true}
          />
          <TimePicker.RangePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            defaultValue={[
              dayjs('07:00', timeFormat),
              dayjs('09:00', timeFormat),
            ]}
            format={timeFormat}
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
            showNow={true}
          />
          <TimePicker.RangePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            format={timeFormat}
            disabled
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
          <TimePicker.RangePicker
            popupClassName="cpickerdtimeropdown"
            size="large"
            defaultValue={[
              dayjs('07:00', timeFormat),
              dayjs('09:00', timeFormat),
            ]}
            format={timeFormat}
            disabled
            separator={
              <FontAwesomeIcon
                className="text-color-grey-01 opacity-[0.87]"
                icon={faArrowRight}
              />
            }
          />
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Tooltip</h1>
        <div className="mb-10 max-w-[960px] mx-auto">
          <div className="grid grid-cols-3 gap-4 mb-6 px-4">
            <div className="mb-2">
              <BasicTooltip trigger="hover" placement="top" text="My tooltip">
                <Button type="button" variant="primary" size="large">
                  Top
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="topLeft"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  topLeft
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="topRight"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  topRight
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="bottom"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  Bottom
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="bottomLeft"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  bottomLeft
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="bottomRight"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  bottomRight
                </Button>
              </BasicTooltip>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-6 px-4">
            <div className="mb-2">
              <BasicTooltip trigger="hover" placement="left" text="My tooltip">
                <Button type="button" variant="primary" size="large">
                  Left
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="leftTop"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  leftTop
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="leftBottom"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  leftBottom
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip trigger="hover" placement="right" text="My tooltip">
                <Button type="button" variant="primary" size="large">
                  right
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="rightTop"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  rightTop
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="rightBottom"
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  rightBottom
                </Button>
              </BasicTooltip>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-6 px-4">
            <div className="mb-2">
              <BasicTooltip trigger="click" placement="left" text="My tooltip">
                <Button type="button" variant="primary" size="large">
                  Click me
                </Button>
              </BasicTooltip>
            </div>
            <div className="mb-2">
              <BasicTooltip
                trigger="hover"
                placement="top"
                showArrow={false}
                text="My tooltip"
              >
                <Button type="button" variant="primary" size="large">
                  No arrow
                </Button>
              </BasicTooltip>
            </div>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">MenuItem</h1>
        <div className="mb-2">
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="mb-2">
              <MenuItem iconPrefix={faAngleRight} iconSuffix={faAngleRight}>
                MenuItem
              </MenuItem>
            </div>
            <div className="mb-2">
              <MenuItem
                iconPrefix={faAngleRight}
                iconSuffix={faAngleRight}
                className="active"
              >
                MenuItem
              </MenuItem>
            </div>
            <div className="mb-2">
              <MenuItem
                iconPrefix={faAngleRight}
                iconSuffix={faAngleRight}
                className="disabled"
              >
                MenuItem
              </MenuItem>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="mb-2">
              <MenuItem
                iconPrefix={faAngleRight}
                iconSuffix={faAngleRight}
                type="label"
              >
                <Checkbox
                  id="label"
                  label="Label"
                  onChange={handleCheckboxChange}
                />
              </MenuItem>
            </div>
            <div className="mb-2">
              <MenuItem
                iconPrefix={faAngleRight}
                iconSuffix={faAngleRight}
                className="active"
                type="label"
              >
                <Checkbox
                  id="labelDemo"
                  label="Label"
                  onChange={handleCheckboxChange}
                  checked
                />
              </MenuItem>
            </div>
            <div className="mb-2">
              <MenuItem
                iconPrefix={faAngleRight}
                iconSuffix={faAngleRight}
                className="disabled"
                type="label"
              >
                <Checkbox
                  id="disableDemo"
                  label="Label"
                  onChange={handleCheckboxChange}
                  disabled
                />
              </MenuItem>
            </div>
          </div>
        </div>

        <h1 className="font-bold mb-2 text-3xl mt-6">Dropdown</h1>
        <div className="mb-2">
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="mb-2">
              <BasicDropdown menu={{ items }} trigger="click">
                <Button size="small">Click me</Button>
              </BasicDropdown>
            </div>
            <div className="mb-2">
              <BasicDropdown menu={{ items }} placement="bottomRight">
                <Button size="small">Hover me</Button>
              </BasicDropdown>
            </div>
            <div className="mb-2">
              <BasicDropdown
                trigger="click"
                overlay={dropdownItemCheck}
                placement="bottomRight"
              >
                <Button size="small">Dropdown checkbox</Button>
              </BasicDropdown>
            </div>
          </div>
        </div>
        <div style={{ height: '200px' }}></div>
      </div>
    </>
  );
}
export default Guideline;
