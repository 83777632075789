import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { menuAdmin, menuSupporter, menuUser } from '../../constants/menu';
import { useEffect, useRef, useState } from 'react';
import AuthService from '../../services/AuthService';
import { CS_ROLES } from '../../constants';
import { getProfile } from '../../helper/localStorage';
import { getConfirmRedirect } from '../../helper/sessionStorage';
import { BasicModalConfirm } from '../molecules/BasicModalConfirm';
import Button from '../atoms/Button';
import { handleConfirmRedirect, handleRedirect } from '../../utils/common';

export const TabLinks = ({ className }: any) => {
  const [menusProp, setMenusProp] = useState<any[]>();
  const roleRef = useRef<number | null>(null);
  const location = useLocation();
  const dataUser = getProfile();
  const parseData = dataUser ? JSON.parse(dataUser) : null;
  const role = parseData.roles;
  const [isModalConfirmRedirect, setIsModalConfirmRedirect] = useState<boolean>(false);
  const [redirectLink, setRedirectLink] = useState<string>('');
  const navigate = useNavigate();

  const handleOk = () => {
    setIsModalConfirmRedirect(false);
    handleConfirmRedirect(redirectLink, navigate);
  }

  const handleCancel = () => {
    setIsModalConfirmRedirect(false);
    setRedirectLink('')
  }

  useEffect(() => {
    roleRef.current = AuthService.getUserRole();

    switch (roleRef.current) {
      case CS_ROLES.admin:
        setMenusProp(menuAdmin);
        break;
      case CS_ROLES.supporter:
        setMenusProp(menuSupporter);
        break;
      default:
        setMenusProp(menuUser);
        break;
    }
  }, []);

  let classNames =
    'flex px-8 border-b border-b-color-gray-f2 bg-white ' + className;
  return (
    <>
      <ul className={classNames}>
        {menusProp?.filter((item: any) => {
          return !(item.key === 1 && !role.includes(1));
        })?.map((item: any) => {
          return (
            <li key={item.link + Math.random()} className="relative group">
              <Link
                to={item.link}
                onClick={(e) => handleRedirect(e, item.link, setIsModalConfirmRedirect, setRedirectLink)}
                className={`text-base inline-block relative border-b hover:text-color-blue-700 hover:bg-color-blue-50 px-7 py-2 after:content-[''] after:absolute after:w-px after:h-5 after:right-0 after:top-1/2 after:-translate-y-1/2 after:bg-color-blue-100 
                  ${location.pathname === item.link || (item.children && item.children.some((child: any) => location.pathname.includes(child.link))) || (item.children && item.children.some((child: any) => location.pathname.includes(child.main))) ? 'text-color-blue-700 bg-color-blue-50 font-semibold border-b-color-blue-500 after:opacity-0' : 'text-color-gray-600 border-b-transparent after:opacity-1'}`}
              >
                {item.label}
              </Link>
              {item.children && (
                <ul className="absolute top-full left-0 bg-white rounded drop-shadow-cs-1 z-20 min-w-44 hidden group-hover:block">
                  {item.children.map((childItem: any) => (
                    <li
                      key={childItem.link + Math.random()}
                      className={`${childItem?.disabled ? 'hidden' : ''}`}
                    >
                      <Link
                        to={childItem.link}
                        className="text-sm block whitespace-nowrap hover:text-color-blue-700 hover:bg-color-blue-50 px-7 py-2"
                        onClick={(e) => handleRedirect(e, item.link, setIsModalConfirmRedirect, setRedirectLink)}
                      >
                        {childItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
      <BasicModalConfirm
        title="確認"
        open={isModalConfirmRedirect}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Button
                type="button"
                variant="outline-tertiary"
                className="w-full"
                onClick={handleCancel}
              >
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                type="button"
                variant="primary"
                className="w-full"
                onClick={handleOk}
              >
                OK
              </Button>
            </div>
          </div>,
        ]}
      >
        {getConfirmRedirect()}
      </BasicModalConfirm>
    </>
  );
};

TabLinks.propTypes = {
  className: PropTypes.string,
};

TabLinks.defaultProps = {
  className: '',
};
