import { regexRouter } from '../utils/common';
import { rc, RouteKey } from './router';

export const menuUser = [
  {
    label: 'ダッシュボード',
    link: `${rc(RouteKey.Home).path}`,
    key: 0,
  },
  // {
  //   label: '授業管理',
  //   link: `${rc(RouteKey.ListClasses).path}`,
  //   key: 1,
  //   children: [
  //     {
  //       label: '授業一覧',
  //       link: `${rc(RouteKey.ListClasses).path}`,
  //       key: 0,
  //     },
  //     {
  //       label: '新規作成',
  //       link: `${regexRouter(rc(RouteKey.Lesson).path)}`,
  //       key: 1,
  //     },
  //   ],
  // },
  // {
  //   label: 'フィードバック',
  //   link: `${rc(RouteKey.FeedbackForm).path}`,
  //   key: 2,
  // },
  {
    label: 'マイページ',
    link: `${rc(RouteKey.MyPage).path}`,
    key: 4,
  },
  {
    label: 'お問い合わせ',
    link: `${rc(RouteKey.Contact).path}`,
    key: 5,
    children: [
      {
        label: 'システムへのリクエスト',
        link: `${rc(RouteKey.RequestSystem).path}`,
        key: 0,
      },
      {
        label: 'お問い合わせ',
        link: `${rc(RouteKey.Contact).path}`,
        key: 1,
      },
      {
        label: '緊急用お問い合わせ',
        link: `${rc(RouteKey.EmergencyContact).path}`,
        key: 2,
      },
    ],
  },
];

export const menuSupporter = [
  {
    label: 'ダッシュボード',
    link: `${rc(RouteKey.Home).path}`,
    key: 0,
  },
  // {
  //   label: '授業管理',
  //   link: `${rc(RouteKey.ListClasses).path}`,
  //   key: 1,
  //   children: [
  //     {
  //       label: '授業一覧',
  //       link: `${rc(RouteKey.ListClasses).path}`,
  //       key: 0,
  //     },
  //     {
  //       label: '新規作成',
  //       link: `${regexRouter(rc(RouteKey.Lesson).path)}`,
  //       key: 1,
  //     },
  //   ],
  // },
  {
    label: 'サポーター',
    link: `${rc(RouteKey.CSDemoRequestingList).path}`,
    key: 2,
    children: [
      {
        label: '授業の募集中一覧',
        link: `${rc(RouteKey.CSDemoRequestingList).path}`,
        key: 0,
      },
      {
        label: '模擬授業履歴',
        link: `${rc(RouteKey.ApplyHistory).path}`,
        key: 1,
      },
    ],
  },
  // {
  //   label: 'フィードバック',
  //   link: `${rc(RouteKey.FeedbackForm).path}`,
  //   key: 3,
  // },
  {
    label: 'マイページ',
    link: `${rc(RouteKey.MyPage).path}`,
    key: 5,
  },
  {
    label: 'お問い合わせ',
    link: `${rc(RouteKey.Contact).path}`,
    key: 6,
    children: [
      {
        label: 'システムへのリクエスト',
        link: `${rc(RouteKey.RequestSystem).path}`,
        key: 0,
      },
      {
        label: 'お問い合わせ',
        link: `${rc(RouteKey.Contact).path}`,
        key: 1,
      },
      {
        label: '緊急用お問い合わせ',
        link: `${rc(RouteKey.EmergencyContact).path}`,
        key: 2,
      },
    ],
  },
];

export const menuAdmin = [
  {
    label: 'ダッシュボード',
    link: `${rc(RouteKey.Home).path}`,
    key: 0,
    children: [
      {
        link: `${rc(RouteKey.EvaluationForm).path}`,
        main: `${rc(RouteKey.EvaluationForm).main}`,
        key: 0,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.EvaluationView).path}`,
        main: `${rc(RouteKey.EvaluationView).main}`,
        key: 1,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.EvaluationReview).path}`,
        main: `${rc(RouteKey.EvaluationReview).main}`,
        key: 2,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.Lesson).path}`,
        main: `${rc(RouteKey.Lesson).main}`,
        key: 3,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.MockClassAssignment).path}`,
        main: `${rc(RouteKey.MockClassAssignment).main}`,
        key: 4,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.EvaluationDemoReviewVideo).path}`,
        main: `${rc(RouteKey.EvaluationDemoReviewVideo).main}`,
        key: 5,
        disabled: true,
      },
      {
        link: `${rc(RouteKey.LessonViewOnly).path}`,
        main: `${rc(RouteKey.LessonViewOnly).main}`,
        key: 6,
        disabled: true,
      },
    ],
  },
  // {
  //   label: '授業管理',
  //   link: `${rc(RouteKey.ListClasses).path}`,
  //   key: 1,
  //   children: [
  //     {
  //       label: '授業一覧',
  //       link: `${rc(RouteKey.ListClasses).path}`,
  //       key: 0,
  //     },
  //     {
  //       label: '新規作成',
  //       link: `${regexRouter(rc(RouteKey.Lesson).path)}`,
  //       key: 1,
  //     },
  //   ],
  // },
  // {
  //   label: 'フィードバック',
  //   link: '#',
  //   key: 3,
  // },
  {
    label: 'サポーター',
    link: `${rc(RouteKey.CSDemoRequestingList).path}`,
    key: 1,
    children: [
      {
        label: '授業の募集中一覧',
        link: `${rc(RouteKey.CSDemoRequestingList).path}`,
        key: 0,
      },
      {
        label: '模擬授業履歴',
        link: `${rc(RouteKey.ApplyHistory).path}`,
        key: 1,
      },
    ],
  },
  {
    label: 'ユーザー管理',
    link: `${rc(RouteKey.UserList).path}`,
    key: 2,
  },
  {
    label: 'マイページ',
    link: `${rc(RouteKey.MyPage).path}`,
    key: 3,
  },
  {
    label: 'メールテンプレート',
    link: `${regexRouter(rc(RouteKey.MailTemplate).path)}`,
    key: 4,
  },
  {
    label: 'AIデータ教育',
    link: `${regexRouter(rc(RouteKey.AIDataTraining).path)}`,
    key: 5,
  },
  {
    label: 'お問い合わせ',
    link: `${rc(RouteKey.Contact).path}`,
    key: 6,
    children: [
      {
        label: 'システムへのリクエスト',
        link: `${rc(RouteKey.RequestSystem).path}`,
        key: 0,
      },
      {
        label: 'お問い合わせ',
        link: `${rc(RouteKey.Contact).path}`,
        key: 1,
      },
      {
        label: '緊急用お問い合わせ',
        link: `${rc(RouteKey.EmergencyContact).path}`,
        key: 2,
      },
    ],
  },
];
