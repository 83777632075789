import { Radio, Tooltip } from 'antd';
import { BasicModal } from '../molecules/BasicModal';
import Label from '../atoms/Label';
import TextField from '../atoms/TextField';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  faCopy,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '../../constants';
import Button from '../atoms/Button';
import {
  IBusyShema,
  ICalendar,
  ICourseSchema,
  IDemoInfoSchema,
  RequestDemoSchema,
} from '../../constants/types';
import { TimeSchedule } from './TimeSchedule';
import { useEffect, useState } from 'react';
import {
  combineDateTime,
  copyTextToClipboard,
  regexRouter,
  showToastMessage,
} from '../../utils/common';
import useDrivePicker from 'react-google-drive-picker';
import RequestDemoService from '../../services/RequestDemoService';
import { rc, RouteKey } from '../../constants/router';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  csMessageFalse,
  requestMockLessonSuccess,
  systemError,
} from '../../constants/message';
import { getCalendarService } from '../../services/Calendar';
import { Loader } from '../atoms/Loader';
import moment from 'moment';
import axios from 'axios';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  id: string;
  courseInfo: ICourseSchema;
  isHome?: boolean;
  demoList?: IDemoInfoSchema[];
  setIsRequestDemo?: (value: boolean) => void;
}
const RequestDemoDialog = (props: IProps) => {
  const {
    isModalOpen,
    id,
    courseInfo,
    setIsModalOpen,
    isHome,
    demoList,
    setIsRequestDemo,
  } = props;
  const FormCSDemo = useForm();
  const { control, handleSubmit, watch, reset, setValue, getValues } =
    FormCSDemo;
  const navigate = useNavigate();

  const [demoSchedule, setDemoSchedule] = useState([{}]);
  const [tooltipTitle, setTooltipTitle] = useState('コピー');
  const [fileInfo, setFileInfo] = useState<any>();
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState({});
  const [busyTime, setBusyTime] = useState<any[]>([]);
  // const [busyTime, setBusyTime] = useState<IBusyShema[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [freeTime, setFreeTime] = useState<any>([]);
  const [haveBusyTime, setHaveBusyTime] = useState<any>([]);
  const [deleteIndex, setDeleteIndex] = useState<number>();

  const statusDisable = [5];
  const [openPicker, authResponse] = useDrivePicker();

  const handleDeleteSchedule = (index: number) => {
    setDeleteIndex(index);
    setDemoSchedule(prev => prev.filter((_, i) => i !== index));

    const num = index === 0 ? 1 : 0;

    if (index === 0) {
      setValue(
        `date${index}`,
        getValues(`date${num}`) ? getValues(`date${num}`) : null,
      );
      setValue(
        `start_${index}`,
        getValues(`start_${num}`) ? getValues(`start_${num}`) : null,
      );
      setValue(
        `end_${index}`,
        getValues(`end_${num}`) ? getValues(`end_${num}`) : null,
      );

      setValue(`date${num}`, '');
      setValue(`start_${num}`, null);
      setValue(`end_${num}`, null);
    } else {
      setValue(`date${index}`, '');
      setValue(`start_${index}`, null);
      setValue(`end_${index}`, null);
    }
  };

  const handleAddSchedule = () => {
    setDemoSchedule(prev => [...prev, {}]);
  };

  const handleOpenPicker = () => {
    openPicker({
      clientId: process.env.REACT_APP_GG_CLIENT_ID || '',
      developerKey: process.env.REACT_APP_GG_API_KEY || '',
      viewId: 'DOCS_VIDEOS',
      token: authResponse?.access_token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      disableDefaultView: true,
      customScopes: ['https://www.googleapis.com/auth/drive'],
      setParentFolder: process.env.REACT_APP_DRIVE_FOLDER_ID,
      viewMimeTypes: 'video/mp4',
      // customViews: customViewsArray, // custom view
      callbackFunction: data => {
        setFileInfo(data.docs?.[0]);
      },
    });
  };

  const onSubmitCSDemo = async (d: any) => {
    let formData: RequestDemoSchema = { demo_type: d.demo_type };

    if (d.demo_type === 0) {
      formData.file_url = fileInfo?.url || fileInfo?.downloadUrl;
    } else if (d.demo_type === 1 && d.date1 && d.date0) {
      formData.schedules = [
        combineDateTime(d.date0, d.start_0, d.end_0),
        combineDateTime(d.date1, d.start_1, d.end_1),
      ];
    } else {
      if (d.start_0) {
        formData.schedules = [combineDateTime(d.date0, d.start_0, d.end_0)];
      } else
        formData.schedules = [combineDateTime(d.date1, d.start_1, d.end_1)];
    }

    setIsConfirm(true);
    setFormData(formData);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileInfo(null);
    reset();
    setValue(`date0`, '');
    setValue(`start_0`, null);
    setValue(`end_0`, null);
    setValue(`date1`, '');
    setValue(`start_1`, null);
    setValue(`end_1`, null);
    setDemoSchedule([{}]);
    setBusyTime([]);
    setFreeTime([]);
    setHaveBusyTime([]);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await showToastMessage('success', '', requestMockLessonSuccess);
    if (isHome && setIsRequestDemo) {
      setIsRequestDemo(true);
    } else {
      navigate(regexRouter(rc(RouteKey.Home).path));
    }
    reset();
    setValue(`date0`, '');
    setValue(`start_0`, null);
    setValue(`end_0`, null);
    setValue(`date1`, '');
    setValue(`start_1`, null);
    setValue(`end_1`, null);
    setDemoSchedule([{}]);
  };

  const handleSend = async () => {
    await RequestDemoService(id || '', formData)
      .then(status => {
        if (status === 200) {
          handleOk();
          setFileInfo('');
        } else if (status === 400) {
          handleCancel();
          showToastMessage('error', '模擬授業申請', csMessageFalse);
        } else {
          handleCancel();
          showToastMessage('error', systemError, '');
        }
      })
      .catch(error => {
        throw error;
      });
    setIsConfirm(false);
  };

  const getEvents = async (
    calendarID: string,
    access_token: string,
    refresh_token: string,
  ) => {
    try {
      await axios
        .post(
          'https://oauth2.googleapis.com/token',
          {
            client_id: process.env.REACT_APP_GG_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SERCET,
            grant_type: 'refresh_token',
            refresh_token: refresh_token,
          },
          {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(async (result: any) => {
          const response = await axios.get(
            `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events`,
            {
              headers: {
                Authorization: `Bearer ${result?.data?.access_token}`,
              },
              params: {
                singleEvents: true,
                fields: 'items(start,end,summary,recurrence)',
                timeMin: moment().startOf('days').toISOString(),
                timeMax: moment().add(6, 'month').toISOString(),
                maxResults: 999,
                q: '模擬授業',
              },
            },
          );

          const events = response?.data?.items?.filter((it: IBusyShema) =>
            it.summary?.includes('模擬授業'),
          );
          const freeEvents = response?.data?.items?.filter(
            (it: IBusyShema) => it.summary === '模擬授業',
          );
          const hasEvents = response?.data?.items?.filter((it: IBusyShema) =>
            it.summary.includes('【模擬授業】'),
          );

          setHaveBusyTime((prev: any) => [...prev, ...hasEvents]);

          setFreeTime((prev: any) => [...prev, ...freeEvents]);
          setBusyTime((prev: any) => [...prev, ...events]);
        });

      // const response = await axios.post(
      //   `https://www.googleapis.com/calendar/v3/freeBusy?key=${process.env.REACT_APP_GG_API_KEY}`,
      //   {
      //     timeMin: moment().startOf('month').toISOString(),
      //     timeMax: moment().add(2, 'months').endOf('month').toISOString(),
      //     items: [{ id: calendarID }],
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );

      // const events = response?.data?.calendars?.[calendarID]?.busy;

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getCalendarService().then(async result => {
        await Promise.all(
          result.map((it: ICalendar) =>
            getEvents(it.calendar_id, it.access_token, it.refresh_token),
          ),
        );
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (demoList?.length === 2) {
      setDemoSchedule(prev => [...prev, {}]);
    }

    if (demoList?.length && demoList.some(item => item !== undefined)) {
      demoList?.forEach((item, index) => {
        setValue('demo_type', item?.demo_type);
        const startTime = dayjs(item?.start_at);
        const endTime = dayjs(item?.end_at);

        setValue(`date${index}`, startTime);
        setValue(`time${index}`, [startTime, endTime]);
      });
    } else {
      setValue('demo_type', 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoList]);

  return (
    <>
      <Loader isLoading={isLoading} isFullScreen={true} className="z-[5000]" />
      <BasicModal
        title="模擬授業申請"
        open={isModalOpen}
        onOk={onSubmitCSDemo}
        onCancel={handleCancel}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(onSubmitCSDemo)}>
            <div className="grid">
              <Button
                type="submit"
                variant="primary"
                size="medium"
                className={`w-full ${statusDisable?.includes(courseInfo?.status) ? 'hidden' : ''}`}
              >
                申請する
              </Button>
            </div>
          </form>,
        ]}
      >
        <div className="grid grid-cols-2 gap-4 mb-5">
          <Label label="ID">
            <TextField
              type="text"
              classNameChild="w-full block"
              placeholder={id}
              disabled
            />
          </Label>
          <Label label="タイトル">
            <TextField
              type="text"
              classNameChild="w-full block"
              placeholder={courseInfo?.title}
              disabled
            />
          </Label>
        </div>
        <div className="mb-5">
          <Label label="授業タイプ"></Label>
          <div className="grid grid-cols-3 gap-4">
            <Controller
              control={control}
              name="demo_type"
              defaultValue={courseInfo?.demo_type || 1}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={e => {
                    onChange(e);
                  }}
                  value={value}
                >
                  <CusRadio value={0}>
                    <div className="text-base">動画</div>
                  </CusRadio>
                  <CusRadio value={1}>
                    <div className="text-base">オンライン</div>
                  </CusRadio>
                </Radio.Group>
              )}
            />
          </div>
        </div>

        {/* case 1 */}
        {(watch('demo_type') === 1 || !watch('demo_type')?.toString()) && (
          <>
            <div className="pb-2">
              <div className="text-color-blue-500 pb-2 text-sm">
                本日より2週間先(営業日)の日程からご選択頂けます
                毎月15日翌月の日程が更新されます
              </div>
              {demoSchedule?.map((_, index) => {
                return (
                  <TimeSchedule
                    index={index}
                    onDelete={handleDeleteSchedule}
                    num={demoSchedule.length}
                    FormCSDemo={FormCSDemo}
                    status={courseInfo?.status}
                    statusDisable={statusDisable}
                    busyTime={busyTime}
                    freeTime={freeTime}
                    haveBusyTime={haveBusyTime}
                    deleteIndex={deleteIndex}
                  />
                );
              })}
            </div>
            <button
              onClick={handleAddSchedule}
              disabled={demoSchedule?.length === 2}
              className={`w-full inline-flex items-center text-start font-semibold border rounded-md text-base py-[15px] px-5 focus:bg-white 
                ${demoSchedule?.length === 1 ? 'border-color-blue-100 bg-color-blue-5 text-color-blue-500 hover:bg-color-blue-100 hover:border-color-blue-500 hover:text-color-blue-500 focus:border-color-blue-500 focus:text-color-blue-500' : 'text-[#C0C2C5]'}
                ${statusDisable?.includes(courseInfo?.status) ? 'hidden' : ''}`}
            >
              <PlusIcon
                color={`${demoSchedule?.length === 2 ? '#C0C2C5' : '#3F7FE8'}`}
              />
              実施日程
            </button>
          </>
        )}
        {/* e: case 1 */}

        {/* case 2 */}
        {watch('demo_type') === 0 && (
          <>
            <div className="mb-5">
              <Label label="アドレス"></Label>
              <div className="flex gap-2">
                <div className="grow shrink basic-0">
                  <div className="rounded-md bg-color-gray-02 border border-color-gray-03 px-4 py-[11px] flex items-center relative">
                    <div className="ps-2 text-base text-color-gray-600 max-w-[400px]">
                      <span className="line-clamp-1 h-[24px]">
                        {fileInfo?.url ||
                          courseInfo?.demo_info?.[0]?.file_url ||
                          ''}
                      </span>
                    </div>
                    {(fileInfo?.url ||
                      courseInfo?.demo_info?.[0]?.file_url) && (
                      <Tooltip title={tooltipTitle} placement="bottom">
                        <FontAwesomeIcon
                          icon={faCopy}
                          color="#3F7FE8"
                          className="absolute right-[16px] cursor-pointer"
                          onClick={() => {
                            copyTextToClipboard(fileInfo?.url, setTooltipTitle);
                            setTimeout(() => setTooltipTitle('コピー'), 1000);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <Button
                  type="button"
                  variant="primary"
                  size="medium"
                  onClick={() => handleOpenPicker()}
                >
                  アップロード
                </Button>
              </div>
            </div>
          </>
        )}

        {courseInfo?.demo_info?.[0]?.status === 1 && (
          <div className="border border-color-red-02 flex bg-[#FFE4E4] px-4 py-3 rounded-md mt-[20px]">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-color-red-02 text-xl pt-1"
            />
            <div className="pl-2 flex-auto">
              <div className="text-base font-semibold">差し戻しコメント</div>
              <div className="text-sm">{courseInfo?.demo_denied_reason}</div>
            </div>
          </div>
        )}
        {/* e: case 2 */}
      </BasicModal>
      <BasicModal
        title=""
        open={isConfirm}
        onOk={() => setIsConfirm(false)}
        onCancel={() => setIsConfirm(false)}
        centered
        width="680px"
        footer={[
          <form onSubmit={handleSubmit(handleSend)}>
            <div className="flex flex-row-reverse">
              <Button
                type="submit"
                variant="primary"
                size="small"
                className="w-[20%]"
              >
                申請
              </Button>
              <Button
                type="button"
                variant="outline-tertiary"
                size="small"
                className="w-[20%] mr-4"
                onClick={() => setIsConfirm(false)}
              >
                キャンセル
              </Button>
            </div>
          </form>,
        ]}
      >
        <div className="mt-4">{'模擬授業を申請してよろしいですか？'}</div>
      </BasicModal>
    </>
  );
};

export default RequestDemoDialog;

export const CusRadio = styled(Radio)`
  .ant-radio-input {
    opacity: 1;
  }
  .ant-radio-inner:after {
    background: #4a86e0;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #4a86e0;
  }
`;
