import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TabPane = ({ tabKey, activeKey, children } : any) => {
  return (
    <div key={tabKey} className={`tab-pane ${activeKey === tabKey ? 'block' : 'hidden'}`}>
      {children}
    </div>
  );
};

const Tab = ({ defaultActiveKey, direction, className, onChange, children } : any) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  
  let classNames = className;

  let classNamesTab = 'nav-tab ';
  let classNamesContentTab = 'tab-content ';

  if (direction === 'vertical') {
    classNamesTab += 'w-1/4';
    classNames += ' flex';
    classNamesContentTab += 'w-3/4 pl-4'
  }
  else {
    classNamesTab += 'flex gap-x-8 mb-3'
  }

  const handleChange = (key : any) => {
    setActiveKey(key);
    onChange && onChange(key);
  };

  return (
    <div className={classNames}>
      <ul className={classNamesTab}>
        {React.Children.map(children, (child) => {
          const { tabKey, tab } = child.props;
          return (
            <li className={`${direction === 'vertical' ? 'mb-2 last:mb-0' : ''}`}>
                <button
                key={tabKey}
                className={`text-base pt-1.5 pb-[5px] border-b block w-full text-start ${activeKey === tabKey ? 'text-color-blue-400 border-color-blue-400 font-semibold' : 'text-color-gray-200 border-transparent hover:text-color-gray-03'} ${direction !== 'vertical' ? 'min-w-20' : ''}`}
                onClick={() => handleChange(tabKey)}
                >
                {tab}
                </button>
            </li>
          );
        })}
      </ul>
      <div className={classNamesContentTab}>
        {React.Children.map(children, (child) => {
          const { tabKey } = child.props;
          return <TabPane key={tabKey} tabKey={tabKey} activeKey={activeKey}>{child.props.children}</TabPane>;
        })}
      </div>
    </div>
  );
};
Tab.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.string,
    children: PropTypes.node.isRequired,
    defaultActiveKey: PropTypes.string,
    onChange: PropTypes.func
};
  
Tab.defaultProps = {
    direction: 'horizontal',
    className: '',
    defaultActiveKey: '1'
};
export { Tab, TabPane };
