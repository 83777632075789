// export const Status = () => <></>;
import React from 'react';
import PropTypes from 'prop-types';

const Status = ({ type, variant, size, className, children }: any) => {
  // Determine the classNames based on the variant prop
  let classNames = 'font-semibold inline-block ' + className;

  let badge = ' py-1.5 px-3 rounded-md'
  let bgPrimary = ' bg-color-blue-03 text-color-blue-01';
  let bgSuccess = ' bg-color-green-02 text-color-green-01';
  let bgDanger = ' bg-color-red-03 text-color-red-04';
  let bgLightPrimary = ' bg-color-blue-02 text-color-blue-04';
  let bgGray = ' bg-color-gray-02 text-color-gray-08';
  let smallSize = " text-sm"
  let largeSize = " text-base"

  let textPrimary = ' text-color-blue-01 before:bg-color-blue-01';
  let textSuccess = ' text-color-green-01 before:bg-color-green-01';
  let textDanger = ' text-color-red-04 before:bg-color-red-04';
  let textLightPrimary = ' text-color-blue-04 before:bg-color-blue-04';
  let textGray = ' text-color-gray-08 before:bg-color-gray-08';
  let textRatingGreen = ' text-green-600 before:bg-green-600';
  let textRatingBlue = ' text-blue-600 before:bg-blue-600';
  let textRatingYellow = ' text-yellow-600 before:bg-yellow-600';
  let textRatingNeutral = ' text-neutral-600 before:bg-neutral-800';
  let textRatingRose = ' text-rose-600 before:bg-rose-600';

  // color button
  if (type === 'text') {
    classNames += ' text-base before:content-[""] before:inline-block before:rounded-full before:w-2 before:h-2 before:mr-2 before:align-[2px]'
    if (variant === "gray") {
      classNames +=  textGray
    }
    else if (variant === "success") {
      classNames += textSuccess
    }
    else if  (variant === "danger") {
      classNames += textDanger
    }
    else if  (variant === "light-primary") {
      classNames += textLightPrimary
    }
    else if  (variant === "text-color-green-600") {
      classNames += textRatingGreen
    }
    else if  (variant === "text-color-blue-600") {
      classNames += textRatingBlue
    }
    else if  (variant === "text-yellow-600") {
      classNames += textRatingYellow
    }
    else if  (variant === "text-neutral-600") {
      classNames += textRatingNeutral
    }
    else if  (variant === "text-rose-600") {
      classNames += textRatingRose
    }
    else  {
      classNames +=  textPrimary
    }
  }
  else {
    // size button
    if (size === 'small') {
      classNames += smallSize;
    }
    else {
      classNames += largeSize;
    }
    classNames += badge;
    if (variant === "gray") {
      classNames +=  bgGray
    }
    else if (variant === "success") {
      classNames += bgSuccess
    }
    else if  (variant === "danger") {
      classNames += bgDanger
    }
    else if  (variant === "light-primary") {
      classNames += bgLightPrimary
    }
    else  {
      classNames +=  bgPrimary
    }
  }

  return (
    <>
      <span className={classNames}>{children}</span>
    </>
  );
};

Status.propTypes = {
  type: PropTypes.oneOf(['badge', 'text']),
  variant: PropTypes.oneOf(['primary', 'light-primary', 'danger', 'success', 'gray']),
  size: PropTypes.oneOf(['large', 'small']),
  width: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool, // New prop for disabled state
  children: PropTypes.node.isRequired,
  icon: PropTypes.object, // Icon component for prefix
};

Status.defaultProps = {
  type: 'badge',
  variant: 'primary',
  size: 'large',
  className: '',
};

export default Status;

