import { EVALUATION_RATING_STATUS, RATING_EXEMPLARY, RATING_STANDARD } from "../../../constants";

const TabContent1 = ({ courseInfo }: any) => {
  const filteredColor = () => {
    if (courseInfo) {
      if (courseInfo?.overall_rating == EVALUATION_RATING_STATUS[0]?.value) {        
        return 'border-green-600 text-green-600 bg-green-50';
      } else if (courseInfo?.overall_rating == EVALUATION_RATING_STATUS[1]?.value) {
        return 'border-blue-600 text-blue-600 bg-blue-50';
      } else if (courseInfo?.overall_rating == EVALUATION_RATING_STATUS[2]?.value) {
        return 'border-neutral-600 text-neutral-600 bg-neutral-50';
      } else if (courseInfo?.overall_rating == EVALUATION_RATING_STATUS[3]?.value) {
        return 'border-yellow-600 text-yellow-600 bg-yellow-50';
      }
      return 'border-rose-600 text-rose-600 bg-rose-50';
    }
  };
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="border border-color-blue-100 rounded">
          <div className="border-b border-b-color-blue-100 bg-color-blue-50 py-3 px-4 rounded-t flex items-center gap-8">
            <h3 className="text-color-blue-600 font-semibold text-sm m-0 leading-4">
              総合評価
            </h3>
            <div className={`rounded border font-semibold text-sm inline-block ${filteredColor()} text-center min-w-[120px] py-[5px] px-4 leading-4`}>
              {courseInfo?.overall_rating_display}
            </div>
          </div>
          <div className="py-2 px-4 text-color-blue-900 text-sm leading-5 min-h-40">
            {courseInfo?.overall_comment}
          </div>
        </div>

        <table className="border-separate rounded border-spacing-0 w-full border border-color-blue-100 text-sm table-simple">
          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              目的
            </td>
            <td className="p-4 h-24 align-top last:border-r-0">
              {courseInfo?.objective && (
                <ul className="list-disc list-outside pl-4">
                  <li>
                    {courseInfo?.objective}
                  </li>
                </ul>
              )}
            </td>
          </tr>

          <tr>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              コメント
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              {courseInfo?.objective_comment}
            </td>
          </tr>

          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              クラスの達成目標
            </td>
            <td className="p-4 h-24 align-top last:border-r-0">
              {courseInfo?.goal}
            </td>
          </tr>

          <tr>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              コメント
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              {courseInfo?.goal_comment}
            </td>
          </tr>

          <tr className="bg-color-blue-50 text-color-blue-600 font-semibold">
            <td
              width="23.5%"
              className="border-r border-r-color-blue-100 p-4 h-24 align-top"
            >
              目標達成の
              <br />
              測定方法
            </td>
            <td className="p-4 h-24 align-top last:border-r-0">{courseInfo?.method}</td>
          </tr>

          <tr>
            <td className="border-r border-r-color-blue-100 px-4 py-2 h-40 align-top text-color-blue-900">
              コメント
            </td>
            <td className="px-4 py-2 h-40 align-top text-color-gray-400 last:border-r-0">
              {courseInfo?.method_comment}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default TabContent1;
