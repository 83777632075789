import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

const dropdownStyles = `
    .basic-dropdown .ant-dropdown-menu {
        padding: 8px;
        width: 230px
    }
    .basic-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        padding: 0 !important;
        background-color: transparent !important;
        font-size: 16px !important;
    }
    .basic-dropdown .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a {
        color: unset !important;;
    }
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a, 
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a:hover,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content >a,
    .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content >a:hover {
        color: #4B515B;
    }
`;

export const BasicDropdown = ({
  trigger,
  menu,
  overlay,
  children,
  placement,
  onMouseEnter,
  onMouseLeave,
  disabled,
  id,
  status,
  pathname,
}: any) => {
  const navigate = useNavigate();
  return (
    <>
      <style>{dropdownStyles}</style>
      <Dropdown
        menu={menu}
        overlay={overlay}
        trigger={trigger}
        placement={placement}
        overlayClassName="basic-dropdown"
        disabled={disabled}
      >
        {children}
      </Dropdown>
    </>
  );
};

BasicDropdown.prototype = {
  trigger: PropTypes.oneOf(['click', 'hover', 'contextMenu']),
  children: PropTypes.node,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  placement: PropTypes.oneOf([
    'bottomLeft',
    'bottom',
    'bottomRight',
    'topLeft',
    'top',
    'topRight',
  ]),
};
BasicDropdown.defaultProps = {
  trigger: 'hover',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  placement: 'topLeft',
};
