export const temporarilySaved = 'CS評価を一時保存しました';
export const deleteSuccess = '削除完了';
export const passwordChangeNotification = 'パスワード変更通知';
export const changePwFalse = 'パスワードの変更エラー';

export const updateMailTempSuccess = 'メールテンプレートが更新されました';
export const deleteUserSuccess = 'ユーザーを正常に削除しました';
export const createUserSuccess = 'ユーザーの作成が完了しました';
export const updateUserSuccess = 'ユーザーの更新が完了しました';
export const applySPSuccess =
  'サポーターとしての応募が受け入れられました。詳細なメールを管理者から受け取るまでしばらくお待ちください。';
export const saveDataSuccess = 'フィードバックを提出しました';
export const saveDataErrors = 'データ保存エラー';
export const sendDataSuccess = 'データを正常に送信しました';
export const personalInfoUpdated = '個人情報が更新されました';
export const requestMockLessonSuccess = '模擬授業を申請しました';
export const passChangeSuccess = 'パスワードが更新されました';

export const usernameAlreadyExists = 'ユーザーIDは既に存在しています。';
export const emailAlreadyExists = 'メールアドレスは既に存在しています。';
export const updateUserNotSuccess = 'ユーザーの新規作成に失敗しました';
export const updateMailTempFalse = 'メールテンプレートの更新が失敗しました';
export const systemError = 'システムエラーが発生しました';
export const csMessageFalse = 'CSは存在しないまたは評価できません';
export const applySPFalse = 'サポーターとしての応募に失敗しました。';
export const csDeleteFalse = 'CSの削除に失敗しました。';
export const sendDataFalse = 'データの送信に失敗しました';
export const invalidUsernameOrPassword =
  'パスワードが間違っています。再度ご確認ください。';
export const userDeletionFailed = 'ユーザーの削除に失敗しました';
export const cannotDeleteLoggedInAccount =
  '現在ログインしているアカウントは削除できません';
export const updateLessonFalse = 'コースの更新が失敗しました';
export const createLessonFalse = 'コースの作成が失敗しました';
export const txtCanNotSubmitLesson =
  '既にクラスデザインシートを提出し、評価中です。\n評価が完了するまで次のクラスデザインシートを提出することはできません';
export const permission_denied = 'アクセス権をリクエスト';
export const errorLogin =
  'メールアドレス、ユーザーIDまたはパスワードが正しくありません。';

export const csDelete = (title: string) => `「${title}」\nを削除しました`;
export const courseNotFoundOrAlreadyReviewed = (title: string) =>
  `「${title}」は存在しないまたはすでに評価されていました。`;
export const ratingStatus = (type: boolean) =>
  `評価を${type ? '受理' : '不受理に'}しました。`;
export const rejectDemoSuccess = (title: string) =>
  `「${title}」を模擬授業の差し戻しに成功します。`;
export const approveDemoSuccess = (title: string) =>
  `「${title}」を模擬授業のサポーター募集の成功を返します。`;
export const csEvalConfirm = (title: string) => `「${title}」を評価確定`;
export const duplicateSuccess = (title: string) => `「${title}」を複製しました`;
export const hiddenCSSuccess = (title: string) => `隠し「${title}」`;
export const confirmDelete = (title: string) =>
  `「${title}」\nを削除してよろしいですか？`;
export const confirmRatingLesson = (title: string) =>
  `「${title}」の評価を確定してよろしいですか？`;

export const fieldRequired = '必須';
export const inputPromptEmailOrUserID =
  'メールアドレスまたはユーザーIDが正しくありません';
export const inputValidFormat = '有効な形式で入力してください。';
export const maxLength255Prompt = '文字数は255文字以内で入力ください。';

export const txtFeedbackExist = 'フィードバックフォーム';
export const txtLessThan1000 = '文字数は1000以下に入力ください';
export const txtRequiredFeedbackPositiveAspects =
  '良かった点を入力してください';
export const txtRequiredAreaForImprvmt =
  '改善すると良いと思った点を入力してください';
export const txtAlreadyGotFeedback = 'すでにフィードバックされました！';
export const txtRequiredFeedbackCriteria = '評価項目を入力してください';
export const txtRequiredFeedbackAspects = '授業者の伝え方を入力してください';
export const txtRequiredFeedbackOverallScores =
  '授業全体として10段階で評価を入力してください';
export const txtFeedbackEmail = '結果を電子メールで通知しました';
export const usernameRequired =
  'メールアドレスまたはユーザーIDを入力してください';
export const pwRequired = 'パスワードを入力してください';
export const titleRequired = 'タイトルを入力してください';
export const titleLength = 'タイトルは255文字以内で入力してください';
export const objectiveRequired = '目的を入力してください';
export const objectiveLength = '目的は1000文字以下で入力してください';
export const goalsRequired = '達成目標を入力してください';
export const goalsLength = '目標は1000文字以下で入力してください';
export const methodRequired = '測定方法を入力してください';
export const methodLength1000 = '測定方法は1000文字以下で入力してください';
export const timeRequired = '時間を選択してください';
export const contentRequired = '内容を入力してください';
export const methodSectionRequired = '方法を入力してください';
export const contentLength = '内容は2000文字以下で入力してください';
export const methodLength2000 = '方法は2000文字以下で入力してください';
export const studentActivitiesRequired = '学生の活動を入力してください';
export const studentActivitiesLength =
  '学生の活動は2000文字以下で入力してください';
export const fieldRequestDemoRequired = '日程は必須です';
export const textConfirmRedirect = '作成中ですが、保存せず移動しますか？';
export const txtNotificationCheckEmail =
  'パスワードリセットの申請を受け付けました。メールをご確認ください。';
export const txtReceivedCS = 'CSを受理しました';
export const txtValidateOldPasswordRequired =
  '現在のパスワードを入力してください';
export const txtValidatePassworLength =
  'パスワードは6文字以上でなければなりません';
export const txtValidateConfirmPasswordRequired =
  '新しいパスワードを再度入力してください';
export const txtNotReceivedCS = 'CSを不受理にしました';
export const txtContentMailTemplatelength =
  '内容は2000文字以下で入力してください';
export const txtSubjectMailTemplatelength = '255文字以内で入力してください';
export const txtChangePasswordNotSuccess =
  '現在のパスワードが間違っています。\n再度ご確認ください。';
export const txtEvaluationConfirmSuccess = (title: string) =>
  `「${title}」\nの評価を確定しました`;
export const txtConfirmPasswordRequired = '新しいパスワードの確認';
export const txtNotHasSupporterOfDemo =
  '模擬授業のサポーターをアサインしてください';
export const txtSubmitFeedbackSuccessBySupporter =
  'フィードバックを提出しました';
export const txtTemporaryFeedbackSuccessByAdmin = '模擬授業のFB結果を一時保存しました';
export const txtSendMaiAssignSupporterSuccess = 'サポーターへ 依頼メールを送信しました';
export const txtChangeStatusAssignSupporterSuccess = 'サポーターのアサインが完了しました';
export const txtUserIdRequeried = 'ユーザーIDを入力してください';
export const txtEmailRequired = 'メールアドレスを入力してください';
export const txtValidateCustomEmail = '有効なメールアドレスを入力してください';
export const txtValidateUserIdCustom = '無効なユーザーID, 半角英数小文字と@/./+/-/_ の文字のみを含めることができます';
export const txtMaxLengthUserId = 'ユーザーIDは30文字以下で入力してください';
export const txtGenderRequired = '性別を選択してください';
export const txtFirstNameRequired = '氏名を入力してください';
export const txtRoleRequired = '権限を選択してください';
export const notiBusyMessage ='お都合の良い時間がありません'