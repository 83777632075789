import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types';

export const MenuItem = ({type, text, id, href, target, iconPrefix, iconSuffix, className, children, onClick, disable,...rest} : any) =>{
    let classNames = 'block py-1 ps-3 rounded-md text-color-gray-400 hover:bg-color-gray-01 [&.active]:bg-color-blue-02 [&.active]:!text-color-gray-03 [&.disabled]:bg-transparent [&.disabled]:!text-color-gray-03 ' + className;
    let classLabel = ' min-w-[130px]'
    let classLink = ' [&.disabled]:pointer-events-none'
    let classDisable = 'block py-1 ps-3 rounded-md text-color-gray-400 [&.disabled]:hover:bg-color-gray-01 [&.active]:bg-color-blue-02 [&.active]:!text-color-gray-03 [&.disabled]:bg-transparent [&.disabled]:!text-color-gray-03 ' + className;

    if (iconSuffix) {
        classNames += ' pe-9'
    } else {
        classNames += ' pe-3'
    }

    return (
        <>
            {
                type === "label" ?
                  <div className={classNames + classLabel} {...rest} onClick={onClick}>
                      {children}
                  </div>
                  :
                  <Link
                    to={disable ? undefined : href}
                    className={`${disable ? classDisable : classNames} ${disable ? 'disabled-link' : ''} ${classLink}`}
                    {...rest}
                    target={target}
                    onClick={disable ? (e) => e.preventDefault() : onClick}
                    style={disable ? { color: 'silver' } : {}}
                    disabled={disable}
                  >
                      {iconPrefix && <span className="w-4 h-4 inline-flex items-center justify-center relative top-0.5 me-2"><FontAwesomeIcon className='color-gray-default' icon={iconPrefix} /></span>}
                      {children}
                      {iconSuffix && <span className="w-4 h-4 inline-flex items-center justify-center absolute top-1/2 -translate-y-1/2 right-3"><FontAwesomeIcon className='color-gray-default' icon={iconSuffix} /></span>}
                  </Link>
            }

        </>
    );
};
MenuItem.propTypes = {
    type: PropTypes.oneOf(['link','label']),
    text: PropTypes.string,
    id: PropTypes.string,
    href: PropTypes.string,
    iconPrefix: PropTypes.object,
    iconSuffix: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    target: PropTypes.oneOf(['_blank','_self','_parent', '_top','framename']),
    onClick: PropTypes.func,
    disable: PropTypes.bool,
}

MenuItem.defaultProps = {
    type: 'link',
    onClick: () => {},
    disable: false,
}