import React, { useEffect } from 'react';
// import { Routes, Route } from "react-router-dom"
import { ConfigProvider } from 'antd/lib';
import { rc, RouteKey } from './constants/router';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Header } from './components/templates/Header';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import Guideline from './pages/Guideline';
import LessonPage from './pages/LessonPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import MyPage from './pages/MyPage';
import EvaluationView from './pages/EvaluationView';
import UserList from './pages/UserList';
// import ListClasses from './pages/ListClasses';
import NotFoundPage from './pages/NotFoundPage';
import MockClassAssignment from './pages/MockClassAssignment';
import EvaluationForm from './pages/EvaluationForm';
import EvaluationReview from './pages/EvaluationReview';
import EvaluationDemoReviewVideo from './pages/EvaluationDemoReviewVideo';
import FeedbackForm from './pages/FeedbackForm';
import CSDemoRequestingList from './pages/CSDemoRequestingList';
import ApplyHistory from './pages/ApplyHistory';
import { Helmet } from 'react-helmet-async';
import { regexRouter, showToastMessage } from './utils/common';
import { ListToastContainer, showToast, ToastContainerCommon } from './components/atoms/ToastMessage';
import MailTemplate from './pages/Emails';
import AIDataTraining from './pages/AIDataTraining';
import NotPermission from './pages/NotPermission';
import { toast, ToastContainer } from 'react-toastify';
import ProcessYoutube from './pages/ProcessYoutube';
import ProcessYoutubeFail from './pages/ProcessYoutubeFail';
import { getConfirmRedirect, removeConfirmRedirect } from './helper/sessionStorage';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const headerVisibleRoutes = [
    rc(RouteKey.Home).path,
    rc(RouteKey.Guideline).path,
    rc(RouteKey.MyPage).path,
    rc(RouteKey.EvaluationView).path,
    rc(RouteKey.EvaluationForm).path,
    rc(RouteKey.EvaluationReview).path,
    rc(RouteKey.EvaluationDemoReviewVideo).path,
    rc(RouteKey.MockClassAssignment).path,
    rc(RouteKey.FeedbackForm).path,
    rc(RouteKey.CSDemoRequestingList).path,
    rc(RouteKey.UserList).path,
    rc(RouteKey.ApplyHistory).path,
    rc(RouteKey.Lesson).path,
    rc(RouteKey.LessonViewOnly).path,
    rc(RouteKey.MailTemplate).path,
    rc(RouteKey.AIDataTraining).path,
    rc(RouteKey.ProcessYoutube).path,
    rc(RouteKey.ProcessYoutubeFail).path,
    rc(RouteKey.AdminPreviewFormFeedback).path,
  ];

  const isHeaderVisiblePage = headerVisibleRoutes.some(route => {
    const subPath = regexRouter(route);
    if (subPath) {
      return location.pathname.includes(subPath);
    }
    return false;
  });

  if (getConfirmRedirect()) {
    const url =  location.pathname;
    const isLessonPath = url.includes(regexRouter(rc(RouteKey.Lesson).path));
    const isLessonViewOnlyPath = url.includes(regexRouter(rc(RouteKey.LessonViewOnly).path));
    if (!isLessonPath || isLessonViewOnlyPath) {
        removeConfirmRedirect();
    }
}

  if (state?.isNotification) {
    const { status, title, text } = JSON.parse(state.isNotification);
    showToastMessage(status, title, text);
    navigate(location.pathname, { state: {} })
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorPrimary: '#6599ED',
          },
        },
      }}
    >
      <Helmet titleTemplate="%s - CS Accessment" defaultTitle="CS Accessment">
        <meta name="description" content="CS Accessment" />
      </Helmet>
      <div className="App font-notosans">
        <ListToastContainer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          closeButton={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={true}
          theme="light"
        />
        <div className="min-w-300">
          {isHeaderVisiblePage && <Header />}
          <Routes>
            <Route path={rc(RouteKey.Home).path} element={<Dashboard />} />
            <Route path={rc(RouteKey.Login).path} element={<LoginPage />} />
            <Route path={rc(RouteKey.Guideline).path} element={<Guideline />} />
            <Route
              path={rc(RouteKey.ForgotPassword).path}
              element={<ForgotPassword />}
            />
            <Route
              path={rc(RouteKey.ResetPassword).path}
              element={<ResetPassword />}
            />
            <Route path={rc(RouteKey.MyPage).path} element={<MyPage />} />
            <Route
              path={rc(RouteKey.EvaluationView).path}
              element={<EvaluationView />}
            />
            <Route path={rc(RouteKey.UserList).path} element={<UserList />} />
            <Route
              path={rc(RouteKey.EvaluationView).path}
              element={<EvaluationView />}
            />
            <Route path={rc(RouteKey.Lesson).path} element={<LessonPage />} />
            <Route
              path={rc(RouteKey.LessonViewOnly).path}
              element={<LessonPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
            <Route
              path={rc(RouteKey.NotPermission).path}
              element={<NotPermission />}
            />
            <Route
              path={rc(RouteKey.MockClassAssignment).path}
              element={<MockClassAssignment />}
            />
            <Route
              path={rc(RouteKey.EvaluationForm).path}
              element={<EvaluationForm />}
            />
            <Route
              path={rc(RouteKey.EvaluationReview).path}
              element={<EvaluationReview />}
            />
            <Route
              path={rc(RouteKey.EvaluationDemoReviewVideo).path}
              element={<EvaluationDemoReviewVideo />}
            />
            <Route
              path={rc(RouteKey.FeedbackForm).path}
              element={<FeedbackForm />}
            />
            <Route
              path={rc(RouteKey.CSDemoRequestingList).path}
              element={<CSDemoRequestingList />}
            />
            <Route
              path={rc(RouteKey.ApplyHistory).path}
              element={<ApplyHistory />}
            />
            <Route
              path={rc(RouteKey.MailTemplate).path}
              element={<MailTemplate />}
            />
            <Route
              path={rc(RouteKey.AIDataTraining).path}
              element={<AIDataTraining />}
            />
            <Route
              path={rc(RouteKey.ProcessYoutube).path}
              element={<ProcessYoutube />}
            />
            <Route
              path={rc(RouteKey.ProcessYoutubeFail).path}
              element={<ProcessYoutubeFail />}
            />
            <Route
              path={rc(RouteKey.AdminPreviewFormFeedback).path}
              element={<FeedbackForm />}
            />
          </Routes>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default App;
